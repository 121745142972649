
import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { BlocContent } from 'components/wrappers';
import MySwitch from './components/MySwitch';
import Magasin from './components/Magasin';
import Product from './components/Product';
import PerformanceCriteria from './components/PerformanceCriteria';
import Constraints from './components/Constraints';
import MaximizationCriteria from './components/MaximizationCriteria';
import defaultTheme from 'theme';
import { ReactComponent as CollapseIconSvg } from 'assets/images/fleche-onglet.svg';

import {
  PageWrapper,
  StyledCollapse,
  StyledCollapsePanel,
} from './styles';
import {SwitchSettingFormInput, useGetSwitchSettingLazyQuery} from "../../../services/graphql/main";
import {useError} from "../../../services/utils";

const SwitchCreate: React.FC = () => {

  const { id } = useParams();

  const [activeKeys, setActiveKeys] = useState(['my_switch']);

  const { t } = useTranslation('translation');

  const { addError } = useError();

  // const location = useLocation();

  const [switchSetting, setSwitchSetting] = useState<SwitchSettingFormInput>({});

  const [
    getSwitchSettingLazyQuery
  ] = useGetSwitchSettingLazyQuery({ onError: (err) => addError(err, 'error') });

  const loadSwitchSettings = useCallback( async () => {
    let result = await getSwitchSettingLazyQuery();
    if (result.data?.switchSetting) {
      setSwitchSetting({
        productFamilyEnabled: result.data.switchSetting.productFamilyEnabled,
        productSubFamilyEnabled: result.data.switchSetting.productSubFamilyEnabled,
        storeRegionEnabled: result.data.switchSetting.storeRegionEnabled,
        storeTypeEnabled: result.data.switchSetting.storeTypeEnabled,
        centralSizeThreshold: result.data.switchSetting.centralSizeThreshold,
        transportRemovalCost: result.data.switchSetting.transportRemovalCost,
        transportCostPerItem: result.data.switchSetting.transportCostPerItem,
        co2PerKm: result.data.switchSetting.co2PerKm,
      })
      // switchSettingFormToForm(result.data);
    }
  }, [getSwitchSettingLazyQuery]);

  useEffect(() => {
    loadSwitchSettings();
  }, []);

  // indispensable pour gérer activeKey
  const onChangeCollapse = (keys: string | string[]) => {
    setActiveKeys(keys as string[]);
  };

  const onMySwitch = () => {
    setActiveKeys(['my_switch', 'magasin']);
  };

  const onMagasin = (isOpenProduct:boolean) => {
    isOpenProduct && setActiveKeys(['my_switch', 'magasin', 'product']);
  };

  const onProduct = () => {
    setActiveKeys(['my_switch', 'magasin', 'product', 'performance_criteria', 'constraints', 'maximization_criteria']);
  };

  return (
    <PageWrapper>
      <BlocContent style={{width: "1800px", margin: "0 auto"}}>
        <StyledCollapse 
          bordered={false}
          expandIconPosition='end'
          activeKey={activeKeys}
          expandIcon={({ isActive }) =>
            <CollapseIconSvg style={{fontSize: "1.3em", color: defaultTheme.black, transform: (isActive ? "rotate(0deg)" : "rotate(180deg)")}} />}
          onChange={onChangeCollapse}
        >

          <StyledCollapsePanel header={t("create_switch.your_switch")} key="my_switch">
            <MySwitch switchId={id ? Number(id) : undefined} onMySwitch={onMySwitch}/>
          </StyledCollapsePanel>

          <StyledCollapsePanel header={t("create_switch.store")} key="magasin">
            <Magasin onMagasin={onMagasin} isCriteriaRegion={true} isCriteriaType={true} />
          </StyledCollapsePanel>

          <StyledCollapsePanel header={t("create_switch.product")} key="product">
            <Product onProduct={onProduct} />
          </StyledCollapsePanel>

          <StyledCollapsePanel header={t("create_switch.performance_criteria")} key="performance_criteria">
            <PerformanceCriteria/>
          </StyledCollapsePanel>

          <StyledCollapsePanel header={t("create_switch.constraints")} key="constraints">
            <Constraints/>
          </StyledCollapsePanel>

          <StyledCollapsePanel header={t("create_switch.maximization_criteria")} key="maximization_criteria">
            <MaximizationCriteria/>
          </StyledCollapsePanel>

        </StyledCollapse>
      </BlocContent>
    </PageWrapper>
  );
};

export default SwitchCreate;