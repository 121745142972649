import { ColDef, SideBarDef } from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import { isString } from 'lodash';

import { defaultNumberValueGetter } from 'helpers/gridCols';

export const GRID_MAX_HEIGHT = '326px';

export const CENTERED_HEADER_CLASS = 'centered-columns';
export const CENTERED_CELL_STYLE = { textAlign: 'center' };
export const CENTERED_COL_DEF = {
  headerClass: CENTERED_HEADER_CLASS,
  cellStyle: CENTERED_CELL_STYLE,
};

export const HIGHLIGHTED_HEADER_CLASS = 'highlightedHeader';
export const UNIVERSE_HIGHLIGHTED_HEADER_CLASS = 'universeHighlightedHeader';
export const HIGHLIGHTED_CELL_CLASS = 'highlightedCell';
export const FOOTER_ROW_CLASS = 'footerRow';

export const HIGHLIGHTED_COLUMN_COL_DEFS = {
  headerClass: `${CENTERED_HEADER_CLASS} ${HIGHLIGHTED_HEADER_CLASS}`,
  cellClass: HIGHLIGHTED_CELL_CLASS,
};

export const UNIVERSE_HIGHLIGHTED_COLUMN_COL_DEFS = {
  headerClass: UNIVERSE_HIGHLIGHTED_HEADER_CLASS,
};

export const ALT_HIGHLIGHTED_HEADER_CLASS = 'altHighlightedHeader';

export const ALT_HIGHLIGHTED_COLUMN_COL_DEFS = {
  headerClass: `${CENTERED_HEADER_CLASS} ${ALT_HIGHLIGHTED_HEADER_CLASS}`,
  cellClass: HIGHLIGHTED_CELL_CLASS,
};

export const DISABLED_ROW_CLASS = 'disabledRow';
export const DISABLED_CELL_CONTENT_CLASS = 'disabledCellContent';
export const EDITABLE_CELL_CONTENT_CLASS = 'editableCellContent';
export const ALWAYS_ENABLED_CELL_CLASS = 'alwaysEnabledCell';

export const COLUMN_TYPES = {
  NUMBER_COLUMN: {
    filter: 'agNumberColumnFilter',
    valueGetter: defaultNumberValueGetter,
    cellStyle: { textAlign: 'right' },
  },
  TEXT_COLUMN: {
    filter: 'agTextColumnFilter',
    cellStyle: { textAlign: 'left' },
  },
  BOOLEAN_COLUMN: {
    cellStyle: { textAlign: 'center' },
  },
};

export const GRID_DEFAULT_COL_DEFS: ColDef = {
  flex: 1,
  minWidth: 45,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  sortable: true,
  resizable: true,
  suppressMovable: false,
  ...CENTERED_COL_DEF,
  type: 'NUMBER_COLUMN',
  tooltipValueGetter(params) {
    const value = params.valueFormatted ? params.valueFormatted : params.value;

    return isString(value) ? value : null;
  },
};
export const SIDE_BAR: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
};
export const GRID_DEFAULT_PROPS: AgGridReactProps = {
  domLayout: 'normal',
  rowSelection: 'multiple',
  stopEditingWhenCellsLoseFocus: true,
  suppressColumnVirtualisation: true,
  columnTypes: COLUMN_TYPES,
  enableRangeSelection: true,
  sideBar: SIDE_BAR,
};

export const LS_KEY_SORTED_AND_FILTERED_PRODUCT_PERFORMANCE_IDS = 'sortedAndFilteredPPIds';
