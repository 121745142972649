import {ICellRendererParams, ValueFormatterParams} from 'ag-grid-community';

import { ProductCustomFieldsDto, StoreCustomFieldsDto } from 'services/graphql/main';

export const generateCustomFieldsColDefs = (
  customFields: StoreCustomFieldsDto | ProductCustomFieldsDto | undefined,
  fieldPrefix = 'customField',
  cellRenderer?: (params: ICellRendererParams) => any,
) => {
  const colDefs = [];

  if (customFields?.customFieldName1) {
    colDefs.push({
      field: `${fieldPrefix}1`,
      headerName: customFields?.customFieldName1 + ' Code',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
    colDefs.push({
      field: `${fieldPrefix}1`,
      headerName: customFields?.customFieldName1 + ' Label',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      valueFormatter: (params: ValueFormatterParams) => {
        if (customFields.customFieldValues1) {
          let json = JSON.parse(customFields.customFieldValues1).filter((o: any) => o['Code'] == params.value);
          return json.length > 0 ? json[0]['Label'] : '';
        } else {
          return params.value;
        }
      },
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
  }
  if (customFields?.customFieldName2) {
    colDefs.push({
      field: `${fieldPrefix}2`,
      headerName: customFields?.customFieldName2 + ' Code',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
    colDefs.push({
      field: `${fieldPrefix}2`,
      headerName: customFields?.customFieldName2 + ' Label',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      valueFormatter: (params: ValueFormatterParams) => {
        if (customFields.customFieldValues2) {
          let json = JSON.parse(customFields.customFieldValues2).filter((o: any) => o['Code'] == params.value);
          return json.length > 0 ? json[0]['Label'] : '';
        } else {
          return params.value;
        }
      },
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
  }
  if (customFields?.customFieldName3) {
    colDefs.push({
      field: `${fieldPrefix}3`,
      headerName: customFields?.customFieldName3 + ' Code',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
    colDefs.push({
      field: `${fieldPrefix}3`,
      headerName: customFields?.customFieldName3 + ' Label',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      valueFormatter: (params: ValueFormatterParams) => {
        if (customFields.customFieldValues3) {
          let json = JSON.parse(customFields.customFieldValues3).filter((o: any) => o['Code'] == params.value);
          return json.length > 0 ? json[0]['Label'] : '';
        } else {
          return params.value;
        }
      },
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
  }
  if (customFields?.customFieldName4) {
    colDefs.push({
      field: `${fieldPrefix}4`,
      headerName: customFields?.customFieldName4 + ' Code',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
    colDefs.push({
      field: `${fieldPrefix}4`,
      headerName: customFields?.customFieldName4 + ' Label',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      valueFormatter: (params: ValueFormatterParams) => {
        if (customFields.customFieldValues4) {
          let json = JSON.parse(customFields.customFieldValues4).filter((o: any) => o['Code'] == params.value);
          return json.length > 0 ? json[0]['Label'] : '';
        } else {
          return params.value;
        }
      },
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
  }
  if (customFields?.customFieldName5) {
    colDefs.push({
      field: `${fieldPrefix}5`,
      headerName: customFields?.customFieldName5 + ' Code',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
    colDefs.push({
      field: `${fieldPrefix}5`,
      headerName: customFields?.customFieldName5 + ' Label',
      // type: 'TEXT_COLUMN',
      cellRenderer: cellRenderer || undefined,
      valueFormatter: (params: ValueFormatterParams) => {
        if (customFields.customFieldValues5) {
          let json = JSON.parse(customFields.customFieldValues5).filter((o: any) => o['Code'] == params.value);
          return json.length > 0 ? json[0]['Label'] : '';
        } else {
          return params.value;
        }
      },
      sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'
    });
  }

  return colDefs;
};
