import React, { useState } from 'react';

import { FlexContainer, ItemContainer, StyledItemContainer, StyledLabel } from './styles';
import Indicator from './components/Indicator';

import pictoPiecesTransfert from 'assets/images/Picto-pieces-transfert.svg';
import pictoBoutique from 'assets/images/picto-boutique.svg';
import pictoSwitch from 'assets/images/picto-switch.svg';
import pictoBenefice from 'assets/images/picto-benefice.svg';
import IndicatorExtra from './components/IndicatorExtra';
import { SwitchResulPackageStats, SwitchResult } from 'services/graphql/main';

interface Props {
    textKey8?: string,
    extra?: boolean,
    transfer?: boolean,
    switchName?: string | null,
    switchResult?: SwitchResult
}

const Indicators: React.FC<Props> = ({textKey8, extra, transfer, switchName, switchResult, ...rest}) => {

    const [countTransferredItems, setCountTransferredItems] = useState<number | 0>(1000);
    const [countProductsTransferred, setCountProductsTransferred] = useState<number | 0>(100);

    const [countShippers, setCountShippers] = useState<number | 0>(100);
    const [countRecipients, setCountRecipients] = useState<number | 0>(100);

    const [countTransfers, setCountTransfers] = useState<number | 0>(100);
    const [countItemsTransfer, setCountItemsTransfer] = useState<number | 0>(100);

    const [countSalesGain, setCountSalesGain] = useState<number | 0>(100000);
    const [countSalesProfit, setCountSalesProfit] = useState<number | 0>(100000);
    const [countMarginGain, setCountMarginGain] = useState<number | 0>(100000);
    const [countMarginProfit, setCountMarginProfit] = useState<number | 0>(100000);
    const [countCostTransport, setCountCostTransport] = useState<number | 0>(100000);
    const [countCostTransportStore, setCountCostTransportStore] = useState<number | 0>(50000);
    const [countCostTransportWarehouse, setCountCostTransportWarehouse] = useState<number | 0>(50000);
    const [carbonFootprint, setCarbonFootprint] = useState<number | 0>(1);

    return (
                <StyledItemContainer >
                    <FlexContainer style={{flexDirection: "column"}}>
                        <FlexContainer style={{justifyContent: "space-between", alignContent: 'center', alignItems: 'center', width: "100%"}}>
                            <StyledLabel style={{marginLeft: 20}}>{switchName}</StyledLabel>
                            { extra && <IndicatorExtra />}
                        </FlexContainer>
                        <FlexContainer style={{gap: 10, flexWrap: "wrap"}}>

                            <ItemContainer style={{display: "block", position:"relative", marginTop: 10}}>
                                <Indicator
                                    number1={switchResult?.metadata?.stats?.totalProductsSent}
                                    textKey1='simulate_switch.transferred_items'
                                    number2={switchResult?.metadata?.stats?.numberOfProduct}
                                    textKey2='simulate_switch.products_transferred'
                                    iconPath={pictoPiecesTransfert}
                                    theme='blue'
                                />
                            </ItemContainer>

                            <ItemContainer style={{display: "block", position:"relative", marginTop: 10}}>
                                <Indicator
                                    number1={switchResult?.switchResultStoreSenders?.length}
                                    textKey1='simulate_switch.shippers'
                                    number2={switchResult?.switchResultStoreReceivers?.length}
                                    textKey2='simulate_switch.recipients'
                                    iconPath={pictoBoutique}
                                    theme='purple'
                                />
                            </ItemContainer>

                            { transfer && <ItemContainer style={{display: "block", position:"relative", marginTop: 10}}>
                                <Indicator
                                    number1={switchResult?.packages?.length}
                                    textKey1='simulate_switch.transfers'
                                    number2={switchResult?.metadata?.stats?.avgRefsPerPackage}
                                    textKey2='simulate_switch.items_transfer'
                                    iconPath={pictoSwitch}
                                    theme='yellow'
                                />
                            </ItemContainer>}

                            <ItemContainer style={{display: "block", position:"relative", marginTop: 10}}>
                                <Indicator
                                    unit1={'€'}
                                    unit2={'kg CO2'}
                                    number1={switchResult?.metadata?.stats?.globalEstimatedRevenue ? Math.round(switchResult?.metadata?.stats?.globalEstimatedRevenue as number) : 0}
                                    textKey1='simulate_switch.sales_gain'
                                    number2={switchResult?.metadata?.stats?.globalEstimatedRevenueBenef ? Math.round(switchResult?.metadata?.stats?.globalEstimatedRevenueBenef as number) : 0}
                                    textKey2='simulate_switch.sales_profit'
                                    number3={switchResult?.metadata?.stats?.globalEstimatedProfit ? Math.round(switchResult?.metadata?.stats?.globalEstimatedProfit as number) : 0}
                                    textKey3='simulate_switch.margin_gain'
                                    number4={switchResult?.metadata?.stats?.globalEstimatedProfitBenef ? Math.round(switchResult?.metadata?.stats?.globalEstimatedProfitBenef as number) : 0}
                                    textKey4='simulate_switch.margin_profit'
                                    number5={switchResult?.metadata?.stats?.totalTransport ? Math.round(switchResult?.metadata?.stats?.totalTransport as number) : 0}
                                    textKey5='simulate_switch.cost_transport'
                                    number6={switchResult?.metadata?.stats?.storeTransport ? Math.round(switchResult?.metadata?.stats?.storeTransport as number) : 0}
                                    textKey6='simulate_switch.store_transport'
                                    number7={switchResult?.metadata?.stats?.warehouseTransport ? Math.round(switchResult?.metadata?.stats?.warehouseTransport as number) : 0}
                                    textKey7='simulate_switch.warehouse_transport'
                                    number8={switchResult?.metadata?.stats?.carbonFootprint}
                                    textKey8={textKey8}
                                    iconPath={pictoBenefice}
                                    theme='green'
                                />
                            </ItemContainer>

                        </FlexContainer>
                    </FlexContainer>
                </StyledItemContainer>
    );
};

export default Indicators;