import { Switch } from 'antd';
import styled from 'styled-components';

export const Title = styled.span`
  text-align: center;
  color: ${(p) => p.theme.black};
  font-family: "NotoBold";
  font-size: 2em;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itmes: center;
  align-content: center;
  padding-top: 50px;
`;

export const TextContainer = styled.p`
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
  color: ${(p) => p.theme.more_light_blue_background};
  font-family: "NotoMedium";
  font-size: 1.5em;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-itmes: center;
  align-content: center;
  gap: 15px;
  padding-bottom: 50px;
`;

export const ButtonContainer =  styled.div`
  width: 600px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  border: ${(props) => props.theme.light_soft_gray} solid 1px;
  padding: 15px;
  border-radius: 10px;

  .ant-switch-checked {
    background-color: ${(props) => props.theme.green} !important;
  }
`;

export const StyledSwitch = styled(Switch)`
`;


