import { Switch } from 'antd';
import styled from 'styled-components';

export const StyledSwitch = styled(Switch)`
`;

export const FlexContainer =  styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.light_soft_gray};
    padding: 20px;
`;

export const ItemContainer = styled.div`
    flex-grow: 0;

    .ant-switch-checked {
      background-color: ${(props) => props.theme.green} !important;
    }
`;

export const StyledLabel = styled.h5`
    color: ${(props) => props.theme.black};
    font-family: "NotoRegular";
    font-size: 1.em;
`;