import React from 'react';

import LogoSwitch from 'assets/images/Logo_Switch.webp'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AllRoutes from 'screens/AllRoutes';

import { Container, MessageText, PageWrapper, StyledImg, StyledPrimaryButton, UnauthorizedText } from './styles';

const Unauthorized: React.FC = () => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <Container>
        <StyledImg src={LogoSwitch} alt="logo switch" />
        <MessageText>{t('login.login_message')}</MessageText>
        <UnauthorizedText>{t('common.unauthorized_text')}</UnauthorizedText>
        <StyledPrimaryButton textKey="common.login" onClick={() => navigate(AllRoutes.Login)} />
      </Container>
    </PageWrapper>
  );
};

export default Unauthorized;
