import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import SwitchSelect from './components/SwitchSelect';
import SwitchSimulation from './components/SwitchSimulation';

import {
  FlexContainer,
  ItemContainer,
  PageWrapper
} from './styles';

const SwitchSimulate: React.FC = () => {
  const [selectedSwitch, setSelectedSwitch] = useState<number | null | undefined>(null);
  const [selectedSwitchName, setSelectedSwitchName] = useState<string | null>(null);
  const [simulate, setSimulate] = useState<boolean | null>(false);

  const { t } = useTranslation('translation');

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.switchId && location.state.simulate) {
      setSelectedSwitch(location.state.switchId);
      setSelectedSwitchName(location.state.switchName);
      setSimulate(location.state.simulate);
    }
  }, [location]);

  const onSwitchSelect = (switchId: number | null | undefined) => {
      setSelectedSwitch(switchId);
  };

  const getSwitchName = (switchName: string | null) => {
    setSelectedSwitchName(switchName)
  }

  const onSimulate = () => {
    setSimulate(true);
  };

  const stopSimulate = () => {
    setSimulate(false);
  };

  return (
      <PageWrapper>
          <FlexContainer>
              <ItemContainer>
                  <SwitchSelect switchId={location.state?.switchId} switchName={location.state?.switchName} onSwitchSelect={(switchId) => {onSwitchSelect(switchId)}} getSwitchName={(switchName) => {getSwitchName(switchName)}} onSimulate={() => onSimulate()} />
              </ItemContainer>
              {selectedSwitch && simulate && <SwitchSimulation selectedSwitchName={selectedSwitchName} selectedSwitch={selectedSwitch} stopSimulate={stopSimulate}/>}
          </FlexContainer>
      </PageWrapper>
  );
};

export default SwitchSimulate;