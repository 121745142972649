import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import { FlexContainer, ItemContainer, StyledInputNumber, StyledLabel, StyledLightLabel, StyledSwitch } from "./styles";
import { ReactComponent as RequireSvg } from 'assets/images/Picto-champ-oligatoire.svg';
import { ComparisonSign } from "services/graphql/main";
import { SwitchContext } from "context";

const Constraints: React.FC = () => {

    const { t } = useTranslation('translation');
    const comparisonSign = Object.keys(ComparisonSign);

    const { currentSwitch, setCurrentSwitch } = useContext(SwitchContext);

    return (
    <FlexContainer style={{flexDirection: "row", flexWrap: "wrap", marginTop: 30, marginBottom: 30, paddingLeft: 40}}>

        <ItemContainer style={{width: 150}}>
            <FlexContainer style={{flexDirection: "column"}}>

                <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.inter_country")}</StyledLabel>
                <FlexContainer style={{flexDirection: "row", borderBottom: "solid 1px lightgray", paddingBottom: "10px"}}>
                    <ItemContainer style={{flexGrow: 1}}>
                        <StyledLightLabel isTrue={!!currentSwitch.interCountry}>{currentSwitch.interCountry ? t("create_switch.yes") : t("create_switch.no")}</StyledLightLabel>
                    </ItemContainer>
                    <ItemContainer style={{flexGrow: 1}}>
                        <StyledSwitch
                            checked={currentSwitch.interCountry as boolean}
                            onChange={(value) => setCurrentSwitch({...currentSwitch, interCountry: value as boolean})}
                        />
                    </ItemContainer>
                </FlexContainer>

            </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{width: 150}}>
            <FlexContainer style={{flexDirection: "column"}}>

                <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.inter_region")}</StyledLabel>
                <FlexContainer style={{flexDirection: "row", borderBottom: "solid 1px lightgray", paddingBottom: "10px"}}>
                    <ItemContainer style={{flexGrow: 1}}>
                        <StyledLightLabel isTrue={!!currentSwitch.interRegion}>{currentSwitch.interRegion ? t("create_switch.yes") : t("create_switch.no")}</StyledLightLabel>
                    </ItemContainer>
                    <ItemContainer style={{flexGrow: 1}}>
                    <StyledSwitch
                            checked={currentSwitch.interRegion as boolean}
                            onChange={(value) => setCurrentSwitch({...currentSwitch, interRegion: value as boolean})}
                        />
                    </ItemContainer>
                </FlexContainer>

            </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{width: 350}}>
            <FlexContainer style={{flexDirection: "column"}}>

                <FlexContainer style={{flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", paddingBottom: 7}}>
                    <ItemContainer>
                        <StyledLabel>{t("create_switch.max_number_recipients")}</StyledLabel>
                    </ItemContainer>
                    <ItemContainer>
                        <RequireSvg/>
                    </ItemContainer>
                </FlexContainer>

                <FlexContainer style={{flexDirection: "row", borderBottom: "solid 1px lightgray", paddingBottom: "6px"}}>
                    <ItemContainer style={{flexGrow: 1}}>
                        <StyledInputNumber
                            style={{width: 100, border: 0}}
                            controls={false}
                            value={currentSwitch.nbDestMax}
                            onChange={(value) => setCurrentSwitch({...currentSwitch, nbDestMax: value as number})}
                        />
                    </ItemContainer>
                    <ItemContainer style={{flexGrow: 4}}>
                        <StyledLightLabel>{t("create_switch.per_sender")}</StyledLightLabel>
                    </ItemContainer>
                </FlexContainer>

            </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{width: 350}}>
            <FlexContainer style={{flexDirection: "column"}}>

                <FlexContainer style={{flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", paddingBottom: 7}}>
                    <ItemContainer>
                        <StyledLabel>{t("create_switch.min_number_pieces")}</StyledLabel>
                    </ItemContainer>
                    <ItemContainer>
                        <RequireSvg/>
                    </ItemContainer>
                </FlexContainer>

                <FlexContainer style={{flexDirection: "row", borderBottom: "solid 1px lightgray", paddingBottom: "6px"}}>
                    <ItemContainer style={{flexGrow: 1}}>
                        <StyledInputNumber
                            style={{width: 100, border: 0}}
                            controls={false}
                            value={currentSwitch.nbItemMin}
                            onChange={(value) => setCurrentSwitch({...currentSwitch, nbItemMin: value as number})}
                        />
                    </ItemContainer>
                    <ItemContainer style={{flexGrow: 4}}>
                        <StyledLightLabel>{t("create_switch.per_transfer")}</StyledLightLabel>
                    </ItemContainer>
                </FlexContainer>

            </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{width: 350}}>
            <FlexContainer style={{flexDirection: "column"}}>

                <FlexContainer style={{flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", paddingBottom: 7}}>
                    <ItemContainer>
                        <StyledLabel>{t("create_switch.max_pieces_transferred")}</StyledLabel>
                    </ItemContainer>
                    <ItemContainer>
                        <RequireSvg/>
                    </ItemContainer>
                </FlexContainer>
                <FlexContainer style={{flexDirection: "row", borderBottom: "solid 1px lightgray", paddingBottom: "6px"}}>
                <ItemContainer style={{flexGrow: 1}}>
                    <StyledInputNumber
                        style={{width: 100, border: 0}}
                        controls={false}
                        value={currentSwitch.nbItemMaxTransfer}
                        onChange={(value) => setCurrentSwitch({...currentSwitch, nbItemMaxTransfer: value as number})}
                    />
                </ItemContainer>
                <ItemContainer style={{flexGrow: 4}}>
                    <StyledLightLabel>{t("create_switch.per_sender")}</StyledLightLabel>
                </ItemContainer>
            </FlexContainer>

            </FlexContainer>
        </ItemContainer>

    </FlexContainer>
    )
};

export default Constraints;