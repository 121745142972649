import styled from 'styled-components';

export const Title = styled.span`
  text-align: center;
  color: ${(p) => p.theme.black};
  font-family: "NotoMedium";
  font-size: 1.2em;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itmes: center;
  align-content: center;
  padding-bottom: 10px;
`;

export const TextContainer = styled.p`
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
  color: ${(p) => p.theme.more_light_blue_background};
  font-family: "NotoMedium";
  font-size: 1.5em;
`;

interface GridContainerProps {
  gridWidth?: string | number,
  gridHeight?: number
}

export const GridContainer = styled.div.attrs({
  className: 'ag-theme-material'})<GridContainerProps>`

    height: ${(props) => props.gridHeight ? props.gridHeight + 'px' : '600px'};
    width: ${(props) => props.gridWidth ? 'calc('+ props.gridWidth + 'px -50 px)' : '850px'};
    border: lightgray solid 1px;
    border-radius: 18px;
    padding: 10px;

    --ag-range-selection-border-style: none;
    --ag-range-selection-border-color: ${(props) => props.theme.green};
    --ag-selected-row-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-highlight-color: ${(props) => props.theme.light_green};
    --ag-material-accent-color: ${(props) => props.theme.green};
    --ag-data-color: ${(props) => props.theme.black};
    --ag-font-family: "NotoRegular";
    --ag-font-size: 1em;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-itmes: center;
  align-content: center;
  gap: 15px;
  padding-bottom: 30px;
`;



