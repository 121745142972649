import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import {
  TitleContainer,
  Title,
  GridContainer,
  StyledSelect,
  StyledLabel,
  ItemContainer,
  FlexContainer
} from './styles';
import { ColDef } from 'ag-grid-community';
import { StyledModal } from 'components/Modals';
import { StyledCancelPrimaryButton, StyledPrimaryButton } from 'components/Modals/StyledModal/styles';
import { useError } from 'services/utils';
import {
  ProductSettingsDto,
  Season,
  StoreSettingDto,
  Universe,
  useGetSeasonsQuery,
  useGetStoresSettingLazyQuery,
  useGetStoresSettingQuery,
  useGetUniversesQuery,
  useUpdateStoreUniverseActiveMutation
} from 'services/graphql/main';
import {CaretDownOutlined } from "@ant-design/icons";
import defaultTheme from 'theme';
import { currencyFormatter } from'components/AgGridComponents/valueFormatters';
import { Switch } from "antd";
import {toast} from "react-toastify";
import {copyNodeList} from "ag-grid-community/dist/lib/utils/dom";

interface PropsStoreModal {
  customElement?: React.ReactNode;
  customTitle?: string;
  customTitleKey?: string;
  onCancel?: () => void;
  open: boolean;
  text?: string;
  textKey?: string;
  width?: number;
  height?: number;
}

const StoreModal: React.FC<PropsStoreModal> = ({
  customElement,
  customTitle,
  customTitleKey,
  open,
  onCancel,
  width,
  height,
}) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const [selectedUniverse, setSelectedUniverse] = useState<number | undefined>();
  const [selectedSeason, setSelectedSeason] = useState<number | undefined>();

  const [universes, setUniverses] = useState<(Universe | null)[] | null | undefined>();
  const [seasons, setSeasons] = useState<(Season | null)[] | null | undefined>();

  const [isGridReady, setIsGridReady] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const [rows, setRows] = useState<StoreSettingDto[]>([]);

  const colDefs: ColDef[] = useMemo( () => [
    {field: 'code', headerName: t('parameters.store_code'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
    {field: 'name', headerName: t('parameters.store_name'), sortable: true, resizable: true, width: 250,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
    {field: 'address.addressRegion.name', headerName: t('parameters.store_region'), sortable: true, resizable: true, width: 200,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
    {field: 'type', headerName: t('parameters.store_type'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
    {field: 'address.country.name', headerName: t('parameters.store_country'), sortable: true, resizable: true, width: 150,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
    {field: 'targetTurnOver', headerName: t('parameters.store_turnover'), sortable: true, resizable: true, width: 150,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter},
    {field: 'isActive', headerName: t('parameters.store_active'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true,
      cellRenderer: (row: any) =>
      <Switch disabled={true} checked={row.data.isActive as boolean} onChange={(valueChange) => {
        if (!selectedUniverse) return;
        row.data.isActive = valueChange
        updateStoreUniverseActive(row.data.id, selectedUniverse, valueChange).then(() => {});
        gridRef.current?.api.redrawRows({rowNodes: row});
      }}></Switch>
    },
  ], [t, selectedUniverse]);

  const {
    loading: loadingUniversesDataQuery,
    data: universesDataQuery,
    refetch: refetchUniversesQuery,
  } = useGetUniversesQuery({ onError: (err) => addError(err, 'error') });

  const {
    loading: loadingSeasonsDataQuery,
    data: seasonsDataQuery,
    refetch: refetchSeasonsQuery,
  } = useGetSeasonsQuery({ onError: (err) => addError(err, 'error') });

  const [
    getStoresSettingLazyQuery, {
      loading: loadingStoresSettingDataQuery,
      error: erroStoresSettingDataQuery,
      refetch: refetchStoresSettingDataQuery
    }
  ] = useGetStoresSettingLazyQuery({ onError: (err) => addError(err, 'error') });

  const [updateStoreUniverseActiveMutation] = useUpdateStoreUniverseActiveMutation({
    onError: (err) => addError(err, 'error')
  });

  const loadStoresSettingData = useCallback( async () =>{
    console.log(selectedUniverse);
    if (selectedUniverse) {
      gridRef.current?.api.showLoadingOverlay();
      let result = await getStoresSettingLazyQuery({
        variables: {
          universeId: selectedUniverse,
          seasonId: selectedSeason
        }
      })
      if (result.data?.storesSetting) {
        setRows(result.data.storesSetting);
        gridRef.current?.api.redrawRows();
      }
    } else {
      addError(t('parameters.select_universe'), 'warning');
    }
  }, [getStoresSettingLazyQuery, selectedSeason, selectedUniverse]);

  const updateStoreUniverseActive = useCallback(async (storeId: number, universeId: number, isActive: boolean) => {
    const result = await updateStoreUniverseActiveMutation({
      variables: {
        storeActiveState: {storeId: storeId, universeId: universeId, isActive: isActive}
      },
      onError: (err) => addError(err, 'error'),
    });

    if (result.data?.updateStoreUniverseActive) {
      toast.success('Enregistré', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }, [updateStoreUniverseActiveMutation])

  useEffect(() => {
    if (universesDataQuery) {
      setUniverses(universesDataQuery.universes);
      if (universesDataQuery.universes) {
        setSelectedUniverse(universesDataQuery.universes[0].id);
      }
    }
  }, [universesDataQuery]);

  useEffect(() => {
    if (seasonsDataQuery) {
      setSeasons(seasonsDataQuery.seasons);
    }
  }, [seasonsDataQuery]);

  useEffect(() => {
    loadStoresSettingData();
  }, [selectedUniverse, selectedSeason]);
  
  return (
    <>
      <StyledModal
        width={!!width ? width : "900px"}
        centered
        closable={true}
        open={open}
        zIndex={2000}
        onCancel={onCancel}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <TitleContainer>
          <Title>{customTitleKey ? t(customTitleKey) : customTitle}</Title>
        </TitleContainer>

        <FlexContainer style={{flexDirection: "row", alignItems: "flex-end", marginTop: 20, marginBottom: 30}}>
          <ItemContainer>
            <StyledLabel>{t("parameters.universe")}</StyledLabel>
            <StyledSelect
              getPopupContainer={(trigger)=> trigger.parentNode}
              style={{width: 200}}
              suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
              bordered={false}
              value={selectedUniverse}
              onChange={(value) => setSelectedUniverse(value as number)}
              options={universes?.map((universe) => ({value: universe?.id, label: universe?.name}))}
              placeholder={'-'}
              showArrow={true}
              // filterOption={filterOption}
            />
          </ItemContainer>

          <ItemContainer>
            <StyledLabel>{t("parameters.season")}</StyledLabel>
            <StyledSelect
              getPopupContainer={(trigger)=> trigger.parentNode}
              style={{width: 200}}
              suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
              bordered={false}
              value={selectedSeason}
              onChange={(value) => setSelectedSeason(value as number)}
              options={seasons?.map((season) => ({value: season?.id, label: season?.name}))}
              placeholder={'-'}
              showArrow={true}
              // filterOption={filterOption}
            />
          </ItemContainer>
        </FlexContainer>

        <GridContainer gridWidth={width} gridHeight={height}>
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={rows}
            rowHeight={40}
            rowSelection='multiple'
            onGridReady={() => { setIsGridReady(true); }}
          />
        </GridContainer>

        {customElement && customElement}
      </StyledModal>
    </>
  );
};

export default StoreModal;
