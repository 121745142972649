import React from 'react';

import MassImport from "../../MassImport";

import { StyledModal } from 'components/Modals'

type ModalProps = {
  handleCancel: () => void;
  isOpen: boolean;
  refetchData?: () => void;
};

const ImportModal: React.FC<ModalProps> = ({ isOpen, handleCancel, refetchData }) => {
  return (
    <StyledModal
      title=""
      open={isOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
      width="100%"
      centered={true}
    >
      <MassImport refetchData={refetchData} />
    </StyledModal>
  );
};

export default ImportModal;
