import { useContext } from 'react';

import { ErrorContext } from '../../context';

const useError = () => {
  const { errors, addCustomError, addError, clearErrors } = useContext(ErrorContext);

  return { errors, addCustomError, addError, clearErrors };
};

export default useError;
