import { Input, InputNumber, Radio, Select, Switch } from 'antd';
import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const FlexContainer =  styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px 30px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px 30px;
`;

interface ItemContainerProps {
  visibility?: boolean
}

export const ItemContainer = styled.div<ItemContainerProps>`
  visibility:  ${(p) => (p.visibility ? 'hidden' : 'visible')};
  flex-grow: 0;

  .ant-switch-checked {
    background-color: ${(props) => props.theme.green} !important;
  }

  .ant-radio-wrapper {
    color: ${(props) => props.theme.black};
    font-family: "NotoRegular";
    font-size: 16px;
    margin: 0 90px 0 0;
  }

  .ant-radio {
    top: .1em;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.green};
    border-width: 4px;
    width: 18px;
    height: 18px;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 0;
  }

  span.ant-radio-inner::after {
    background-color: ${(props) => props.theme.white};
  }
`;

export const StyledLabel = styled.h5`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.06em;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  font-family: "NotoMedium";
  font-size: 1.06em;
  border-radius: 50px;
  padding: 0.5em 7em;

  i {
    position: absolute;
    right: 1.1em;
    top: 0.7em;
    height: 18px;
  }
`
export const StyledSwitch = styled(Switch)`
`;

interface StyledLightLabelProps {
  isTrue?: boolean
}

export const StyledLightLabel = styled.label<StyledLightLabelProps>`
  font-family: "NotoRegular";
  color: ${(props) => props.isTrue ? props.theme.black : props.theme.light_soft_gray};
  font-size: 1.06em;
`;

export const StyledInputText = styled(Input)`
  font-family: "NotoRegular";
  font-size: 1.06m;
  border: 0;
  border-bottom: ${(props) => props.theme.light_soft_gray} solid 1px;

  :focus {
    border-color: ${(props) => props.theme.green} !important;
    box-shadow: none !important;
  }

  :hover {
    border-color: ${(props) => props.theme.green} !important;
    box-shadow: none !important;
  }
`;

export const StyledSelect = styled(Select)`
  font-family: "NotoRegular";
  font-size: 1.06em;
  border-bottom: ${(props) => props.theme.light_soft_gray} solid 1px;
`;

export const StyledRadio = styled(Radio)`
  width: 250px;
  border-right: solid 1px lightgray;
  margin-right: 18px !important;
  font-size: 15px !important;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  border: ${(props) => props.theme.light_soft_gray} solid 1px;
  padding: 9px;
  border-radius: 9px;
  width: 830px;
`;


export const StyledInputNumber = styled(InputNumber)`
  border: 0;
  border-bottom: ${(props) => props.theme.light_soft_gray} solid 1px;
  width: 250px;
  box-shadow: none !important;
  color: ${(props) => props.theme.black};
  font-family: "NotoRegular";
  font-size: 1.06em;

  :hover {
    border-color: ${(props) => props.theme.green};
  }

  .ant-input-number-handler-wrap {
    opacity: 1;
    border: 0 !important;

    svg {
      color: ${(props) => props.theme.black};
      width: 11px;
      height: 11px;
    }
  }

  .ant-input-number-group,
  .ant-input-number,
  .ant-input-number-input,
  .ant-input-number-handler-wrap
  .ant-input-number-handler-up,
  .ant-input-number-handler-down,
  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    border: none;
  }
`;

export const StyledInputPlaceholderInner = styled.div`
    position: absolute;
    font-size: 1em;
    right: 27px;
    top: 5px;
    pointer-events: none;
    opacity: 0.4;
    z-index: 1;
`;

export const StyledInputDate = styled(Input)`
  border: 0;
  border-bottom: ${(props) => props.theme.light_soft_gray} solid 1px;
`;

interface LabelProps {
  visibility: boolean
}

export const StyledVisibleLabel = styled.label<LabelProps>`
  visibility:  ${(p) => (p.visibility ? 'hidden' : 'visible')};
  font-family: "NotoRegular";
  color: ${(props) => props.theme.light_soft_gray};
`;