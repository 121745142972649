import styled from 'styled-components';

export const FlexContainer =  styled.div`
    display: flex;
    justify-content: left;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px 30px;
`;

export const ItemContainer = styled.div`
    flex-grow: 0;
`;

export const StyledItemContainer = styled.div`
    flex-grow: 0;
    border: ${(props) => props.theme.light_soft_gray} 1px solid;
    padding: 10px;
    border-radius: 12px;
`;

export const StyledLabel = styled.h5`
    color: ${(props) => props.theme.black};
    font-family: "NotoMedium";
    font-size: 1.06em;
`;

