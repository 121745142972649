import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FlexContainer, ItemContainer, StyledEditPrimaryButton, StyledLabel, StyledRedirectPrimaryButton, StyledSwitch, StyledViewPrimaryButton } from './styles';
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton';
import { CaretRightOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';

interface Props {
}

const IndicatorExtra: React.FC<Props> = ({
    ...rest}) => {

    const { t } = useTranslation('translation');

    const [isPin, setIsPin] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);

    return (

        <ItemContainer>
            <FlexContainer style={{gap: "30px", alignContent: "center", alignItems: "center"}}>
                <FlexContainer style={{width: 200}}>
                    <StyledLabel style={{paddingRight: 20}}>{t("dashboard.my_switches.pin_to")}</StyledLabel>
                    <StyledSwitch
                        checked={isPin}
                        onChange={(value) => setIsPin(value)}
                    />
                </FlexContainer>
                <FlexContainer>
                    <StyledLabel style={{paddingRight: 20}}>{t("dashboard.my_switches.template")}</StyledLabel>
                    <StyledSwitch
                        checked={isTemplate}
                        onChange={(value) => setIsTemplate(value)}
                    />
                </FlexContainer>
                <StyledLabel style={{padding: "10px 20px", border: "1px solid lightgray", borderRadius: 50, width: 270}}>{t("dashboard.my_switches.last_simulation")}</StyledLabel>
                <StyledViewPrimaryButton icon={<EyeOutlined />} onClick={() => console.log('onClick view')} />
                <StyledEditPrimaryButton icon={<FormOutlined />} onClick={() => console.log('onClick edit')} />
                <StyledRedirectPrimaryButton icon={<CaretRightOutlined />} onClick={() => console.log('onClick edit')} />
            </FlexContainer>
        </ItemContainer>
              
    );
}

export default IndicatorExtra;