import { BlocContent } from 'components/wrappers';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  GridContainer,
  PageWrapper,
  StyledTitle,
} from './styles';
import { AgGridReact } from 'ag-grid-react';
import ExportModal from './components/ExportModal';
import { ColDef } from 'ag-grid-community';
import {currencyFormatter, dateFormatter} from 'components/AgGridComponents/valueFormatters';
import { SwitchResult, useGetAllValidSwitchResultsQuery } from 'services/graphql/main';
import { useError } from 'services/utils';
import { useLocation } from "react-router-dom";

const SwitchHistory: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const [isGridReady, setIsGridReady] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<SwitchResult[]>();
  const [resultId, setResultId] = useState<number | null>(null);
  const [switchResult, setSwitchResult] = useState<SwitchResult | null>(null);

  const location = useLocation();

  const gridRef = useRef<AgGridReact>(null);

  const {
    loading: loadingValidResultsQuery,
    data: validResultsDataQuery,
    refetch: refetchValidResultsQuery,
  } = useGetAllValidSwitchResultsQuery ({ onError: (err) => addError(err, 'error') });

  useEffect(() => {
    if (validResultsDataQuery) {
      setRowData(validResultsDataQuery.allValidSwitchResults as SwitchResult[]);
    }
  }, [validResultsDataQuery]);

  useEffect(() => {
    if (location.state && location.state.resultId) {
      setResultId(location.state.resultId);
    }
  }, [location]);

  useEffect(() => {
    if (rowData && resultId) {
      const swr = rowData.filter(switchResult => switchResult.id == resultId)[0];
      setSwitchResult(swr);
      setExportModalOpen(true);
    }
  }, [rowData]);


  const onSelectionChanged = () => {
    const rows = gridRef.current!.api.getSelectedRows();
    if (rows.length === 1) {
      setSwitchResult(rows[0]);
      setExportModalOpen(true);
    }
  };

  const onClose = () => {
    gridRef.current!.api.deselectAll();
    setSwitchResult(null);
    setExportModalOpen(false);
  };

  const colDefs: ColDef[] = useMemo( () => [
      {field: 'validatedAt', headerName: t('history.date_time'), sortable: true, width: 200, filter: 'agDateColumnFilter', resizable: true, valueFormatter: dateFormatter},
      {field: 'user.username', headerName: t('history.user'), sortable: true, width: 150, filter: 'agTextColumnFilter', resizable: true},
      {field: 'config.name', headerName: t('history.name_switch'), sortable: true, width: 150, filter: 'agTextColumnFilter', resizable: true},
      {field: 'metadata.stats.totalProductsSent', headerName: t('history.transfer_quantity'), sortable: true, width: 200, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'metadata.stats.numberOfProduct', headerName: t('history.nbr_products'), sortable: true, width: 180, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'metadata.stats.totalPackagesSent', headerName: t('history.nbr_transfers'), sortable: true, width: 180, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'metadata.stats.globalEstimatedRevenue', headerName: t('simulate_switch.sales_gain'), sortable: true, width: 150, filter: 'agNumberColumnFilter', resizable: true, valueFormatter: currencyFormatter},
      {field: 'metadata.stats.globalEstimatedRevenueBenef', headerName: t('simulate_switch.sales_profit'), sortable: true, width: 150, filter: 'agNumberColumnFilter', resizable: true, valueFormatter: currencyFormatter},
  ], [t]);

  return (
    <PageWrapper>
      <BlocContent style={{flexGrow:1, padding: "20px 20px"}}>
        <StyledTitle>{t('history_switch.list_of_actions')}</StyledTitle>
        <GridContainer gridWidth={1400}>
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={rowData}
            rowHeight={40}
            rowSelection={"single"}
            onSelectionChanged={onSelectionChanged}
            onGridReady={() => { setIsGridReady(true); }}
          />
        </GridContainer>
      </BlocContent>

      {switchResult && <>
        <ExportModal
          text={''}
          open={exportModalOpen}
          onCancel={onClose}
          showOnlyCloseBtn={false}
          width= {1660}
          height={800}
          switchResult={switchResult}
        />
      </>}
    </PageWrapper>
  );
};

export default SwitchHistory;