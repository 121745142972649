import styled from 'styled-components';
import {Select} from "antd";
import PrimaryButton from "../../../../../components/UI/PrimaryButton/PrimaryButton";

export const Title = styled.span`
  text-align: left;
  color: ${(p) => p.theme.black};
  font-family: "NotoMedium";
  font-size: 1.2em;
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  align-content: center;
  //padding-bottom: 10px;
`;

export const TextContainer = styled.p`
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
  color: ${(p) => p.theme.more_light_blue_background};
  font-family: "NotoMedium";
  font-size: 1.5em;
`;

interface GridContainerProps {
  gridWidth?: number,
  gridHeight?: number
}

export const GridContainer = styled.div.attrs({
  className: 'ag-theme-material'})<GridContainerProps>`

    height: ${(props) => props.gridHeight ? props.gridHeight + 'px' : '600px'};
    width: ${(props) => props.gridWidth ? 'calc('+ props.gridWidth + 'px -50 px)' : '850px'};
    //border: lightgray solid 1px;
    //border-radius: 18px;
    padding: 10px;

    .ant-switch-checked {
      background-color: ${(props) => props.theme.green} !important;
    }
  
    //.ag-row {
    //  //border: lightgray solid 1px;
    //  //border-radius: 18px;
    //  margin: 5px;
    //}

    .ag-cell-label-container {
      flex-direction: column-reverse;
      align-content: center;
      align-items: center;
    }
  
    .ag-header-cell-label {
      text-align: center;
      justify-content: center;
    }

    --ag-range-selection-border-style: none;
    --ag-range-selection-border-color: ${(props) => props.theme.green};
    --ag-selected-row-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-highlight-color: ${(props) => props.theme.light_green};
    --ag-material-accent-color: ${(props) => props.theme.green};
    --ag-data-color: ${(props) => props.theme.black};
    --ag-font-family: "NotoRegular";
    --ag-font-size: 1em;
`;

export const FlexContainer =  styled.div`
  display: flex;
  align-items: baseline;
  align-content: flex-start;
  gap: 10px 100px;
  height: 100%;
  overflow: scroll;
`;

interface ItemContainerProps {
  visibility?: boolean
}

export const ItemContainer = styled.div<ItemContainerProps>`
  visibility:  ${(p) => (p.visibility ? 'hidden' : 'visible')};
  flex-grow: 0;
`;

export const StyledLabel = styled.h5`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.06em;
`;

export const StyledSelect = styled(Select)`
  font-family: "NotoRegular";
  font-size: 1.06em;
  //border-bottom: ${(props) => props.theme.light_soft_gray} solid 1px;
  border-radius: 30px;
  padding: 0.50em 0em;
  height: auto;
  background-color: ${(p) => p.theme.light_green};
  border: 1px solid;
  border-color: ${(p) => p.theme.green};
  color: ${(p) => p.theme.black};
  text-align: center;
  :hover {
    background-color: ${(p) => p.theme.green};
    border-color: ${(p) => p.theme.green};
    color: ${(p) => p.theme.white};
    i {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }
  }
  :focus {
    background-color: ${(p) => p.theme.light_green};
    border-color: ${(p) => p.theme.green};
    color: ${(p) => p.theme.black};
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-itmes: center;
  align-content: center;
  gap: 15px;
  padding-bottom: 30px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    font-size: 20px;
    padding: 0.3em 0.2em 0.3em 0.6em;
`;

