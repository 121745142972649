import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import defaultTheme from "theme";

import { FlexContainer, ItemContainer, StyledInputNumber, StyledInputPlaceholderInner, StyledLabel, StyledLightLabel, StyledSelect, StyledSwitch } from "./styles";
import { ComparisonSign } from "services/graphql/main";
import { SwitchContext } from "context";

const PerformanceCriteria: React.FC = () => {

    const { t } = useTranslation('translation');
    const comparisonSign = Object.keys(ComparisonSign);

    const { currentSwitch, setCurrentSwitch } = useContext(SwitchContext);

  return (
    <>
      <FlexContainer style={{borderBottom: "lightgray solid 1px", flexDirection: "row", flexWrap: "wrap", marginTop: 30, paddingBottom: 30}}>

        <ItemContainer>
          <FlexContainer style={{flexDirection: "column", width: "850px"}}>

            <ItemContainer style={{marginBottom: 20}}>
                <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.shipper_criteria")}</StyledLabel>

                <FlexContainer style={{flexDirection: "row", marginBottom: 30}}>
                  <ItemContainer>
                    <StyledLabel style={{fontFamily: "NotoRegular", width: 250, paddingLeft: 40}}>{t("create_switch.flow_rate")}</StyledLabel>
                  </ItemContainer>
                  <ItemContainer>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      style={{width: 250}}
                      bordered={false}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                      placeholder={"-"}
                      value={currentSwitch.perfExpFlowRateSign}
                      onChange={(value) => setCurrentSwitch({...currentSwitch, perfExpFlowRateSign: (value ? value as ComparisonSign : null), perfExpFlowRatePercent: (value ? currentSwitch.perfExpFlowRatePercent : null)})}
                      allowClear
                    />
                  </ItemContainer>
                  <ItemContainer style={{position: "relative"}}>
                      <StyledInputNumber
                          controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                          value={currentSwitch.perfExpFlowRatePercent}
                          onChange={(value) => setCurrentSwitch({...currentSwitch, perfExpFlowRatePercent: value as number})}
                        />
                      <StyledInputPlaceholderInner>%</StyledInputPlaceholderInner>
                  </ItemContainer>
                </FlexContainer>

                <FlexContainer style={{flexDirection: "row"}}>
                  <ItemContainer>
                    <StyledLabel style={{fontFamily: "NotoRegular", width: 250, paddingLeft: 40}}>{t("create_switch.quantity_sold_period")}</StyledLabel>
                  </ItemContainer>
                  <ItemContainer>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      style={{width: 250}}
                      bordered={false}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                      placeholder={"-"}
                      value={currentSwitch.perfExpQtyPeriodSaleSign}
                      onChange={(value) => setCurrentSwitch({...currentSwitch, perfExpQtyPeriodSaleSign: (value ? value as ComparisonSign : null), perfExpQtyPeriodSale: (value ? currentSwitch.perfExpQtyPeriodSale : null)})}
                      allowClear
                    />
                  </ItemContainer>
                  <ItemContainer style={{position: "relative"}}>
                      <StyledInputNumber
                          controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                          value={currentSwitch.perfExpQtyPeriodSale}
                          onChange={(value) => setCurrentSwitch({...currentSwitch, perfExpQtyPeriodSale: value as number})}
                      />
                    <StyledInputPlaceholderInner>{t("create_switch.pieces")}</StyledInputPlaceholderInner>
                  </ItemContainer>
                </FlexContainer>
            </ItemContainer>

          </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{marginTop: 20}}>
            <FlexContainer style={{flexDirection: "column"}}>

                <ItemContainer style={{marginBottom: 50}}>
                    <FlexContainer style={{flexDirection: "row"}}>
                        <StyledLabel style={{width: 250}}>{t("create_switch.min_number_weeks_store_shipper")}</StyledLabel>
                        <ItemContainer style={{position: "relative"}}>
                            <StyledInputNumber
                              disabled={true}
                              controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                              value={currentSwitch.perfExpPresenceMin}
                              onChange={(value) => setCurrentSwitch({...currentSwitch, perfExpPresenceMin: value as number})}
                            />
                          <StyledInputPlaceholderInner>{t("create_switch.weeks")}</StyledInputPlaceholderInner>
                        </ItemContainer>
                    </FlexContainer>
                </ItemContainer>

            </FlexContainer>
        </ItemContainer>

      </FlexContainer>
      <FlexContainer style={{flexDirection: "row", flexWrap: "wrap", marginTop: 30, marginBottom: 30}}>
        <ItemContainer>
          <FlexContainer style={{flexDirection: "column", width: "850px"}}>
            <ItemContainer>
            <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.recipient_criteria")}</StyledLabel>

            <FlexContainer style={{flexDirection: "row", marginBottom: 30}}>
              <ItemContainer>
                <StyledLabel style={{fontFamily: "NotoRegular", width: 250, paddingLeft: 40}}>{t("create_switch.flow_rate")}</StyledLabel>
              </ItemContainer>
              <ItemContainer>
                <StyledSelect
                  getPopupContainer={(trigger)=> trigger.parentNode}
                  style={{width: 250}}
                  bordered={false}
                  suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                  options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                  placeholder={"-"}
                  value={currentSwitch.perfDestFlowRateSign}
                  onChange={(value) => setCurrentSwitch({...currentSwitch, perfDestFlowRateSign: (value ? value as ComparisonSign : null), perfDestFlowRatePercent: (value ? currentSwitch.perfDestFlowRatePercent : null)})}
                  allowClear
                />
              </ItemContainer>
              <ItemContainer style={{position: "relative"}}>
                <StyledInputNumber
                  controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                  value={currentSwitch.perfDestFlowRatePercent}
                  onChange={(value) => setCurrentSwitch({...currentSwitch, perfDestFlowRatePercent: value as number})}
                />
                <StyledInputPlaceholderInner>%</StyledInputPlaceholderInner>
              </ItemContainer>
            </FlexContainer>

            <FlexContainer style={{flexDirection: "row"}}>
              <ItemContainer>
                <StyledLabel style={{fontFamily: "NotoRegular", width: 250, paddingLeft: 40}}>{t("create_switch.quantity_sold_period")}</StyledLabel>
              </ItemContainer>
              <ItemContainer>
                <StyledSelect
                  getPopupContainer={(trigger)=> trigger.parentNode}
                  style={{width: 250}}
                  bordered={false}
                  suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                  options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                  placeholder={"-"}
                  value={currentSwitch.perfDestQtyPeriodSaleSign}
                  onChange={(value) => setCurrentSwitch({...currentSwitch, perfDestQtyPeriodSaleSign: (value ? value as ComparisonSign : null), perfDestQtyPeriodSale: (value ? currentSwitch.perfDestQtyPeriodSale : null)})}
                  allowClear
                />
              </ItemContainer>
              <ItemContainer style={{position: "relative"}}>
                <StyledInputNumber
                  controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                  value={currentSwitch.perfDestQtyPeriodSale}
                  onChange={(value) => setCurrentSwitch({...currentSwitch, perfDestQtyPeriodSale: value as number})}
                />
                <StyledInputPlaceholderInner>{t("create_switch.pieces")}</StyledInputPlaceholderInner>
              </ItemContainer>
            </FlexContainer>
          </ItemContainer>
          </FlexContainer>
        </ItemContainer>
        <ItemContainer style={{marginTop: 20}}>
          <FlexContainer style={{flexDirection: "column"}}>
            <StyledLabel style={{width: 250}}>{t("create_switch.ideal_stock")} &#62; 0</StyledLabel>
            <FlexContainer style={{flexDirection: "row", borderBottom: "solid 1px lightgray", paddingBottom: "10px"}}>
              <ItemContainer style={{flexGrow: 1}}>
                <StyledLightLabel isTrue={!!currentSwitch.perfIdealStock}>{currentSwitch.perfIdealStock ? t("create_switch.yes") : t("create_switch.no")}</StyledLightLabel>
              </ItemContainer>
              <ItemContainer style={{flexGrow: 1}}>
                <StyledSwitch
                  checked={currentSwitch.perfIdealStock as boolean}
                  disabled={true}
                  onChange={(value) => setCurrentSwitch({...currentSwitch, perfIdealStock: value as boolean})}
                />
              </ItemContainer>
            </FlexContainer>
          </FlexContainer>
        </ItemContainer>
      </FlexContainer>
    </>
  )
};

export default PerformanceCriteria;