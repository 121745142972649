import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import { TitleContainer, Title, GridContainer } from './styles';
import {ColDef, FirstDataRenderedEvent, IDetailCellRendererParams} from 'ag-grid-community';
import { StyledModal } from 'components/Modals';
import {
  ProductCustomFieldsDto,
  ProductSettingsDto,
  StoreCustomFieldsDto,
  useGetProductsListQuery,
  useProductCustomFieldsQuery
} from 'services/graphql/main';
import { useError } from 'services/utils';
import { currencyFormatter } from 'components/AgGridComponents/valueFormatters';
import {generateCustomFieldsColDefs} from "../../../../../helpers/customFields";

interface PropsProductModal {
  customElement?: React.ReactNode;
  customTitle?: string;
  customTitleKey?: string;
  onCancel?: () => void;
  open: boolean;
  text?: string;
  textKey?: string;
  width?: string | number;
  height?: number;
}

const ProductModal: React.FC<PropsProductModal> = ({
  customElement,
  customTitle,
  customTitleKey,
  open,
  onCancel,
  width,
  height,
}) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const [isGridReady, setIsGridReady] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const [colDefs, setColDefs] = useState<ColDef[]>();
  const [rows, setRows] = useState<ProductSettingsDto[]>([]);

  const [customFieldNames, setCustomFieldNames] = useState<{ label: string; name?: string | null }[]>();

  const { data: dataProductCustomFields } = useProductCustomFieldsQuery({
    onError: (err) => addError(err, 'error'),
  });

  useEffect(() => {
    if (dataProductCustomFields) {
      const customFields = [];
      for (let i = 1; i <= 5; i++) {
        if (dataProductCustomFields?.productCustomFields[`customFieldName${i}` as keyof ProductCustomFieldsDto]) {
          customFields.push({
            label: `CustomFieldValue_${i}`,
            name: dataProductCustomFields?.productCustomFields[`customFieldName${i}` as keyof ProductCustomFieldsDto],
          });
        }
        const colDefs: ColDef[] =  [
          {field: 'code', headerName: t('parameters.product_code'), sortable: true, resizable: true, width: 150,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', cellRenderer: "agGroupCellRenderer"},
          {field: 'name', headerName: t('parameters.product_name'), sortable: true, resizable: true, width: 120,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          {field: 'genericReference', headerName: t('parameters.product_genericReference'), sortable: true, resizable: true, width: 120,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          {field: 'colorCode', headerName: t('parameters.product_colorCode'), sortable: true, resizable: true, width: 80,  autoHeaderHeight: false, wrapHeaderText: false, filter: 'agTextColumnFilter'},
          {field: 'colorName', headerName: t('parameters.product_colorName'), sortable: true, resizable: true, width: 120,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          {field: 'universeName', headerName: t('parameters.product_universeName'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          {field: 'seasonName', headerName: t('parameters.product_seasonName'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          {field: 'departmentName', headerName: t('parameters.product_departmentName'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          {field: 'familyName', headerName: t('parameters.product_familyName'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          {field: 'subFamilyName', headerName: t('parameters.product_subFamilyName'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter'},
          ...generateCustomFieldsColDefs(
            dataProductCustomFields.productCustomFields,
            'customFieldValue'
          ),
          {field: 'price', headerName: t('parameters.product_price'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter},
          {field: 'costPrice', headerName: t('parameters.product_costPrice'), sortable: true, resizable: true, width: 100,  autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter},
        ];
        setColDefs(colDefs);
      }
      setCustomFieldNames(customFields);
    }
  }, [dataProductCustomFields]);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {field: 'sKU', headerName: 'SKU'},
          {field: 'sizeName', headerName: 'SIZE'}
        ]
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.articles);
      },
    } as IDetailCellRendererParams
  }, []);

  // const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
  //   // arbitrarily expand a row for presentational purposes
  //   setTimeout(() => {
  //     params.api.getDisplayedRowAtIndex(1)!.setExpanded(true);
  //   }, 0);
  // }, []);

  const {
    loading: loadingProductsListDataQuery,
    data: productsListDataQuery,
    refetch: refetchProductsListQuery
  } = useGetProductsListQuery({ onError: (err) => addError(err, 'error')});

  useEffect(() => {
    if (productsListDataQuery?.productsList) {
      setRows(productsListDataQuery.productsList);
    }
  }, [open, productsListDataQuery]);

  return (
    <>
      <StyledModal
        width={!!width ? width : "900px"}
        centered
        open={open}
        zIndex={2000}
        onCancel={onCancel}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <TitleContainer>
          <Title>{customTitleKey ? t(customTitleKey) : customTitle}</Title>
        </TitleContainer>

        <GridContainer gridWidth={width} gridHeight={height}>
          <AgGridReact
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
            // onFirstDataRendered={onFirstDataRendered}
            ref={gridRef}
            columnDefs={colDefs}
            rowData={rows}
            rowHeight={40}
            // onSelectionChanged={onSelectionChanged}
            rowSelection='multiple'
            onGridReady={() => { setIsGridReady(true); }}
          />
        </GridContainer>

        {customElement && customElement}
      </StyledModal>
    </>
  );
};

export default ProductModal;
