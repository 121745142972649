import React, {useEffect, useState} from "react";

import { FlexContainer, ItemContainer, StyledLabel, StyledSwitch } from "./styles";

interface Props {
    text?: string;
    value?: boolean;
    onSwitchButton: (value: boolean) => void;
    style?: {};
}

const SwitchButton: React.FC<Props> = ({text, value, onSwitchButton, style}) => {

    const [state, setState] = useState(value);

    useEffect(() => {
      setState(value);
    }, [value]);

    const handleSwitchButton = (v: boolean) => {
        setState(v);
        onSwitchButton(v);
    }

    return (
        <FlexContainer style={style}>
            <ItemContainer>
                <StyledLabel>{text ? text : ''}</StyledLabel>
            </ItemContainer>
            <ItemContainer>
                <StyledSwitch checked={state} onChange={(v) => handleSwitchButton(v)}/>
            </ItemContainer>
        </FlexContainer>
    )
};

export default SwitchButton;