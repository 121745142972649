import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  color: #08245a;
  padding: 0;

  span {
    text-decoration: underline;
  }
`;
