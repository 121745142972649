import styled from 'styled-components';
import { Tabs } from "components/UI";

export const StyledTabsSwitch = styled(Tabs)`
    position: fixed;
    width: calc(100% - 72px);
    z-index: 2;
    margin-left: 72px;

    .ant-tabs-nav-wrap {
        background-color: ${(props) => props.theme.more_light_blue_background} !important;
        height: 4.5em;
    }

    .ant-tabs-tab {
        padding-left: 3.6em;
        padding-right: 3.6em;
        font-family: "NotoRegular";
        font-size: 0.9em;
    }

    .ant-tabs-tab-active {
        background-color: #758ca7 !important;
        font-family: "NotoBold" !important;
    }
`;