import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GridContainer } from './styles';
import { Switch } from 'antd';
import { ColDef, RowClassParams } from 'ag-grid-community';
import defaultTheme from 'theme';
import {SwitchResultStoreSenderRow} from "../../../../../types/switch-result-store-sender";
import {SwitchResultStoreReceiverRow} from "../../../../../types/switch-result-store-receiver";

interface Props {
  rowDataShip: SwitchResultStoreSenderRow[],
  rowDataRecip: SwitchResultStoreReceiverRow[],
  hasClearFilters: boolean,
  onExcludeSender: (storeId: number, exclude: boolean) => void,
  onExcludeRecipient: (storeId: number, exclude: boolean) => void,
}

const GridItem: React.FC<Props> = ({
  rowDataShip,
  rowDataRecip,
  hasClearFilters,
  onExcludeSender,
  onExcludeRecipient
  }) => {

    const { t } = useTranslation('translation');

    type RowDataShipper = {

    };

    const colDefsShip: ColDef[] = useMemo( () => [
        {field: 'storeFullName', headerName: t('grid_shipper.shipper'), headerTooltip: t('grid_shipper.shipper_tt'), sortable: true, filter: 'agTextColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 200, cellClass: "grid-column-center", resizable: true},
        {field: 'nbTransfert', headerName: t('grid_shipper.transfer_number'), headerTooltip: t('grid_shipper.transfer_number_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'transferQuantity', headerName: t('grid_shipper.transfert_quantity'), headerTooltip: t('grid_shipper.transfert_quantity_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'salesStoreShipper', headerName: t('grid_shipper.sales_store_shipper'), headerTooltip: t('grid_shipper.sales_store_shipper_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'stockStoreShipper',headerName: t('grid_shipper.stock_store_shipper'), headerTooltip: t('grid_shipper.stock_store_shipper_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'salesStoreRecipient', headerName: t('grid_shipper.sales_store_recipient'), headerTooltip: t('grid_shipper.sales_store_recipient_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'stockStoreRecipient', headerName: t('grid_shipper.stock_recipient_store'), headerTooltip: t('grid_shipper.stock_recipient_store_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'disable', headerName: t('grid_shipper.disable'), headerTooltip: t('grid_shipper.disable_tt'), sortable: true,  filter: true, width: 110, cellClass: "grid-column-center",
        cellRenderer: (row: any) =>
            <Switch checked={row.data.disable as boolean} onChange={(valueChange) => {
              rowDataGridShip[row.rowIndex].disable = valueChange;
              onExcludeSender(row.data.store.id, valueChange);
              setRowDataShip(rowDataGridShip);
                gridRefShip.current?.api.redrawRows({rowNodes: row});
              }}></Switch>
        }
      ], [t]);


    const [colDefsGridShip, setColDefsShip] = useState(colDefsShip);
    const [rowDataGridShip, setRowDataShip] = useState<SwitchResultStoreSenderRow[]>(rowDataShip);
    const [selectedRowsShip, setSelectedRowsShip] = useState<unknown[] | null>(null);

    const gridRefShip = useRef<AgGridReact>(null);

    const colDefsRecip: ColDef[] = useMemo( () => [
        {field: 'storeFullName', headerName: t('grid_recipient.recipient'), headerTooltip: t('grid_recipient.recipient_tt'), sortable: true, filter: 'agTextColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 200, cellClass: "grid-column-center", resizable: true},
        {field: 'transferQuantity', headerName: t('grid_recipient.transfert_quantity'), headerTooltip: t('grid_recipient.transfert_quantity_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'salesStoreShipper', headerName: t('grid_recipient.sales_store_shipper'), headerTooltip: t('grid_recipient.sales_store_shipper_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'stockStoreShipper',headerName: t('grid_recipient.stock_store_shipper'), headerTooltip: t('grid_recipient.stock_store_shipper_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'salesStoreRecipient', headerName: t('grid_recipient.sales_store_recipient'), headerTooltip: t('grid_recipient.sales_store_recipient_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'stockStoreRecipient', headerName: t('grid_recipient.stock_recipient_store'), headerTooltip: t('grid_recipient.stock_recipient_store_tt'), sortable: true, filter: 'agNumberColumnFilter', autoHeaderHeight: true, wrapHeaderText: true, width: 100, cellClass: "grid-column-center", resizable: true},
        {field: 'disable', headerName: t('grid_recipient.disable'), headerTooltip: t('grid_shipper.disable_tt'), sortable: true,  filter: true, width: 110, cellClass: "grid-column-center",
        cellRenderer: (row: any) =>
            <Switch checked={row.data.disable as boolean} onChange={(valueChange) => {
                rowDataGridRecip[row.rowIndex].disable = valueChange;
                onExcludeRecipient(row.data.store.id, valueChange);
                setRowDataRecip(rowDataGridRecip);
                gridRefDest.current?.api.redrawRows({rowNodes: row});
              }}></Switch>
        }
      ], [t]);

    const getRowStyle = (params: RowClassParams) => {
      if (params.node.data.disable)
        return {backgroundColor: defaultTheme.pale_gray};
      else
        return {backgroundColor: defaultTheme.white};
    };

    const [colDefsGridRecip, setColDefsRecip] = useState(colDefsRecip);
    const [rowDataGridRecip, setRowDataRecip] = useState<SwitchResultStoreReceiverRow[]>(rowDataRecip);
    const [selectedRowsRecip, setSelectedRowsRecip] = useState<unknown[] | null>(null);

    const gridRefDest = useRef<AgGridReact>(null);

    const onSelectionChangedShip = () => {
      const rows = gridRefShip.current!.api.getSelectedRows();
      setSelectedRowsShip(rows);
    };

    const onSelectionChangedRecip = () => {
      const rows = gridRefDest.current!.api.getSelectedRows();
      setSelectedRowsRecip(rows);
    };

    useEffect(() =>{
      if (rowDataShip) {
        setRowDataShip(rowDataShip);
      }
      if (rowDataRecip) {
        setRowDataRecip(rowDataRecip);
      }
    }, [rowDataShip, rowDataRecip]);

    useEffect(() =>{
      if(gridRefDest && gridRefDest.current && gridRefDest.current.api) gridRefDest.current!.api.setFilterModel({});
      if(gridRefShip && gridRefShip.current && gridRefShip.current.api) gridRefShip.current!.api.setFilterModel({});
    }, [hasClearFilters]);

  return (
    <>
        <GridContainer style={{width: "53%"}}>
            <AgGridReact
              ref={gridRefShip}
              columnDefs={colDefsGridShip}
              rowData={rowDataGridShip}
              rowHeight={40}
              onSelectionChanged={onSelectionChangedShip}
              getRowStyle={getRowStyle}
            />
        </GridContainer>
        <GridContainer style={{width: "47%"}}>
            <AgGridReact
              ref={gridRefDest}
              columnDefs={colDefsGridRecip}
              rowData={rowDataGridRecip}
              rowHeight={40}
              onSelectionChanged={onSelectionChangedRecip}
              getRowStyle={getRowStyle}
            />
        </GridContainer>
    </>
  );
};

export default GridItem;
