import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { GetColumnsWidths, GetView } from 'types/views';

interface Props {
  allColDefs: ColDef[];
  getView: GetView;
}

export const defaultNumberValueGetter = (params: ValueGetterParams) => {
  const value = params.data?.[params.column.getColId()];

  return typeof value === 'number' ? Math.round(value) : value;
};

export const getVisibleColDefs = ({ allColDefs, getView }: Props) => {
  const view = getView();

  let visibleColDefs = allColDefs;
  if (view?.cols) {
    visibleColDefs = view.cols.reduce<ColDef[]>((defs, col) => {
      if (!col.hidden) {
        const colDef = allColDefs.find((colDef) => colDef.field === col.field);
        //use pinned column from view if available else use default colDef pinned
        if (colDef) defs.push({ ...colDef, pinned: col.pinned !== undefined ? col.pinned : colDef.pinned });
      }

      return defs;
    }, []);
  }

  return visibleColDefs;
};

export const applyColWidths = (gridRef: React.RefObject<AgGridReact>, getColumnsWidths: GetColumnsWidths) => {
  const colWidths = getColumnsWidths();

  if (colWidths) {
    gridRef.current?.columnApi.setColumnWidths(colWidths);
  } else {
    gridRef.current?.columnApi.autoSizeAllColumns(true);
  }
};

export const sortEmpty = (a: any, b: any, rowNodeA: any, rowNodeB: any, isDescending: any) => {
  if (isDescending) {
    return a ? (b ? a.localeCompare(b) : 1) : -1;
  }

  return a ? (b ? a.localeCompare(b) : -1) : 1;
};
