import { NakedButton, PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const StyledTitle = styled.h5`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.8em;
  margin: 0px;
`;

export const FlexContainer =  styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-itmes: center;
  align-content: center;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  font-family: "NotoMedium";
  font-size: 1.06em;
  border-radius: 50px;
  padding: 0.5em 4em;

  i {
    position: absolute;
    right: 1.1em;
    top: 0.7em;
    height: 18px;
  }
`

export const FlexContainerSwitches =  styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itmes: center;
  align-content: center;
  gap: 20px;
`;

export const ContainerSwitches = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: calc(100vh - 210px);
  width: 970px;
`;

export const ContainerArticles = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: calc(100vh - 600px);
`;

export const DetailsBox = styled.div`
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.light_soft_gray};
  border-radius: 18px;
  padding: 10px;
  margin-top: 10px;
  //height: 90%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  border: lightgray solid 1px;
  border-radius: 50px;
  padding: 5px 5px;

  & div:not(:first-child) {
    margin-top: 0px;
    margin-left: 5px;
  }

  & svg {
    font-size: 15px;
  }

  & span {
    margin-left: auto;
  }
`;

export const BoxTitle = styled.div`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 14px;
  border-radius: 50px;
  background-color: ${(props) => props.theme.light_green};
  padding: 3px 30px;
`;

export const SubTitle = styled.div`
  color: ${(props) => props.theme.black};
  font-family: "NotoMedium";
  font-size: 14px;
  padding: 0px 10px;
`;

interface GridContainerProps {
  gridWidth?: string,
  gridHeight?: string
}

export const GridContainer = styled.div.attrs({
  className: 'ag-theme-material'})<GridContainerProps>`

  max-height: 500px;
  height: ${(props) => props.gridHeight ? props.gridHeight : '90%'};
  width: ${(props) => props.gridWidth ? props.gridWidth : '100%'};
  padding: 10px;

  .ag-cell-label-container {
      flex-direction: column-reverse;
      align-content: center;
      align-items: center;
  }

  //.ag-center-cols-viewport {
  //  min-height: unset !important;
  //}

  --ag-range-selection-border-style: none;
  --ag-range-selection-border-color: ${(props) => props.theme.green};
  --ag-selected-row-background-color: ${(props) => props.theme.light_green};
  --ag-range-selection-background-color: ${(props) => props.theme.light_green};
  --ag-range-selection-highlight-color: ${(props) => props.theme.light_green};
  --ag-material-accent-color: ${(props) => props.theme.green};
  --ag-data-color: ${(props) => props.theme.black};
  --ag-font-family: "NotoRegular";
  --ag-font-size: 1em;
`;

export const StyledNakedButton = styled(NakedButton)`
    background-color: ${(props) => props.theme.pale_gray};
    border-radius: 50px;
    width: 30px;
    height: 30px;

    :hover {
      background-color: ${(p) => p.theme.black};
      border-color: ${(p) => p.theme.black};
      color: ${(p) => p.theme.white};
      i {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
      }
    }
    :focus {
      background-color: ${(p) => p.theme.pale_gray};
      border-color: ${(p) => p.theme.pale_gray};
      color: ${(p) => p.theme.black};
    }
`;
