import React, { useMemo } from 'react';

import { TabsProps as AntTabsProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledTabs } from './styles';

type TabsProps = AntTabsProps & {
  additionalStyles?: React.CSSProperties;
  fullContentHeight?: boolean;
  itemsWithTextKeys?: { children?: React.ReactNode; forceRender?: boolean; key: string; labelKey: string }[];
};

const Tabs: React.FC<TabsProps> = ({ itemsWithTextKeys, items, fullContentHeight, additionalStyles, ...rest }) => {
  const { t } = useTranslation('translation');

  const translatedItems = useMemo(() => {
    return itemsWithTextKeys?.map((item) => ({
      key: item.key,
      label: t(item.labelKey),
      children: item.children,
      forceRender: item.forceRender,
    }));
  }, [itemsWithTextKeys, t]);

  return (
    <StyledTabs
      style={additionalStyles}
      items={translatedItems || items}
      {...rest}
    />
  );
};

export default Tabs;
