export default {
  Root: '/',
  Dashboard: '/dashboard',
  Login: '/login',
  AuthorizationCallback: '/authorization-callback',
  Unauthorized: '/unauthorized',
  SwitchAll: '/switch-all',
  SwitchCreate: '/switch-create',
  SwitchSimulate: '/switch-simulate',
  SwitchHistory: '/switch-history',
  Parameters: '/parameters',
  Customers: '/customers',
  Exports: '/exports',
  Account: '/account'
};
