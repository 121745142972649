import styled from 'styled-components';

export const StyledTitle = styled.h5`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.4em;
  margin-bottom: 20px;
`;

export const PageWrapper = styled.div`
  position: fixed;
  top: 4.5em;
  width: calc(100% - 80px);
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0px 20px;
`;

export const FlexContainer =  styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px 30px;
    width: 100%;
`;

export const ItemContainer = styled.div`
    flex-grow: 0;
`;