import React, { useEffect, useMemo, useRef, useState } from 'react';

import { PlusCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { StyledModal } from 'components/Modals/index';
import { NakedButton } from 'components/UI';
import { composeTranslatedMessage } from 'helpers/translations';
import { useGetFileImportLogsQuery, useGetFileImportDetailsLazyQuery } from 'services/graphql/main';
import { useError } from 'services/utils';

import { GridContainer } from './styles';
import {Title, TitleContainer} from "../../../Parameters/components/modals/ProductModal/styles";
import checkSvg from "../../../../assets/images/check.svg";
import {StyledIcon} from "../../../../components/UI/PrimaryButton/styles";
import warningSvg from "../../../../assets/images/warning.svg";
import pictoPlusSvg from "../../../../assets/images/picto+.svg";
import {StyledNakedButton} from "../styles";

type Props = {
  handleCancel: () => void;
  isOpen: boolean;
};

const LastImportDetails: React.FC<Props> = ({ isOpen, handleCancel }) => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const [isGridReady, setIsGridReady] = useState(false);

  const gridRef = useRef<AgGridReact<{ object: string }[]>>(null);

  const [selectedLineId, setSelectedLineId] = useState<number>();
  const [errorsData, setErrorsData] = useState();

  const { data: fileImportLogs } = useGetFileImportLogsQuery({
    onError: (err) => addError(err, 'warning'),
  });
  const [getFileImportDetails] = useGetFileImportDetailsLazyQuery({
    onError: (err) => addError(err, 'warning'),
  });

  useEffect(() => {
    const fetchDetailedErrors = async () => {
      if (selectedLineId) {
        const detailedErrors = await getFileImportDetails({ variables: { fileImportLogId: selectedLineId } });
        setErrorsData(JSON.parse(detailedErrors.data?.fileImportLog.errors || ''));
      }
    };
    fetchDetailedErrors();
  }, [selectedLineId, getFileImportDetails]);

  const columnDetailsDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'importDate',
        headerName: t('dashboard.daily_summary.import_date'),
        valueFormatter: (params) => {
          return moment(params.value).local().format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        field: 'object',
        headerName: t('dashboard.daily_summary.lib_interface'),
      },
      {
        field: 'errorsCount',
        headerName: t('dashboard.daily_summary.errors_count'),
      },
      {
        field: 'isSuccessful',
        headerName: t('dashboard.daily_summary.integration'),
        cellRenderer: (props: ICellRendererParams) => {
          return props.data.isSuccessful ? (
            <StyledIcon $iconPath={checkSvg} />
          ) : (
            <StyledIcon $iconPath={warningSvg} />
          );
        },
      },
      {
        suppressMenu: true,
        sortable: false,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        cellRenderer: (props: ICellRendererParams) => {
          return (
            <StyledNakedButton type="text" onClick={() => setSelectedLineId(props.data.id)}>
              <StyledIcon style={{width: 12, height: 12}} $iconPath={pictoPlusSvg} />
            </StyledNakedButton>
          );
        },
        pinned: 'right',
      },
    ],
    [t],
  );

  const columnErrorsDefs: ColDef[] = [
    {
      field: 'lineIndex',
      headerName: t('dashboard.daily_summary.line_index'),
    },
    {
      field: 'code',
      headerName: t('dashboard.daily_summary.error_message'),
      valueFormatter: (params) => {
        if (params.data.args?.length) {
          return composeTranslatedMessage(t(`errors.${params.value}`), params.data.args);
        } else {
          return t(`errors.${params.value}`);
        }
      },
    },
    {
      field: 'field',
      headerName: t('dashboard.daily_summary.field'),
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
    };
  }, []);

  return (
    <StyledModal
      open={isOpen}
      onCancel={() => {
        setSelectedLineId(undefined);
        setErrorsData(undefined);
        handleCancel();
      }}
      footer={null}
      width="70%"
      centered={true}
    >
      <TitleContainer>
        <Title>{t('dashboard.daily_summary.history_import')}</Title>
      </TitleContainer>

      <GridContainer>
        {selectedLineId ? (
          <>
            <NakedButton type="text" onClick={() => setSelectedLineId(undefined)}>
              <ArrowLeftOutlined
                style={{ cursor: 'pointer', textAlign: 'center', fontSize: 20, marginBottom: 10 }}
              />
            </NakedButton>
            <AgGridReact
              containerStyle={{ width: '100%' }}
              ref={gridRef}
              columnDefs={columnErrorsDefs}
              rowData={errorsData}
              defaultColDef={defaultColDef}
              // domLayout={'autoHeight'}
            />
          </>
        ) : (
          <AgGridReact
            containerStyle={{ width: '100%' }}
            ref={gridRef}
            columnDefs={columnDetailsDefs}
            rowData={fileImportLogs?.fileImportLogs}
            defaultColDef={defaultColDef}
            onGridReady={() => { setIsGridReady(true); }}
            // domLayout={'autoHeight'}
          />
        )}
      </GridContainer>
    </StyledModal>
  );
};
export default LastImportDetails;
