import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useNavigate } from 'react-router-dom';

import { BlocContent } from 'components/wrappers';
import { FlexContainer, ItemContainer } from 'screens/layouts/Footer/styles';
import {StyledIconPrimaryButton, StyledItemContainer, StyledLabel, StyledPrimaryButton, StyledSelect} from './styles';
import { ReactComponent as RequireSvg } from 'assets/images/Picto-champ-oligatoire.svg';
import { ReactComponent as CollapseIconSvg } from 'assets/images/fleche-onglet.svg';
import defaultTheme from 'theme';
import { useGetSimpleSwitchesConfigsQuery } from 'services/graphql/main';
import { useError } from 'services/utils';
import {FormOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import AllRoutes from "../../../AllRoutes";

type Props = {
    children?: React.ReactNode;
    switchId?: number;
    switchName?: string;
    onSwitchSelect: (switchId: number | null | undefined) => void;
    getSwitchName: (switchName: string | null) => void;
    onSimulate: () => void;
  }

const SwitchSelect: React.FC<Props> = ({children, switchId, switchName, onSwitchSelect, getSwitchName, onSimulate}) => {

    const { t } = useTranslation('translation');
    const { addError } = useError();

    const navigate = useNavigate();

    const [selectedSwitch, setSelectedSwitch] = useState<number | null | undefined>(switchId);
    const [simpleSwitchesConfigs, setSimpleSwitchesConfigs] = useState<({id: number; name?: string | null | undefined} | null)[] | null | undefined>(null);

    const {
        loading: loadingSimpleSwitchesConfigsQuery,
        data: simpleSwitchesConfigsDataQuery,
        refetch: refetchSwitchesConfigsQuery,
    } = useGetSimpleSwitchesConfigsQuery({ variables: {isSaved: true}, onError: (err) => addError(err, 'error') });

    useEffect(() => {
        if (simpleSwitchesConfigsDataQuery) { 
          setSimpleSwitchesConfigs(simpleSwitchesConfigsDataQuery.switchesConfigs);
        }
    }, [simpleSwitchesConfigsDataQuery]);

    const onEdit = () => {
        navigate(AllRoutes.SwitchCreate, {state: {id: selectedSwitch}});
    };

    const getOptions = () => {
        const opts = simpleSwitchesConfigs?.map((simpleSC) => ({value: simpleSC?.id as number, label: simpleSC?.name as string}))
        if (switchId && switchName) {
            opts?.push({value: switchId, label: switchName});
        }
        return opts;
    };

    return (
        <BlocContent style={{flexGrow:1, padding: "10px 10px", height: 80}}>
            <FlexContainer style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>

                <StyledItemContainer style={{width: 900}}>
                    <FlexContainer style={{flexDirection: "row"}}>
                        <ItemContainer>
                            <FlexContainer style={{flexDirection: "row", gap: 0}}>
                                <RequireSvg style={{width: 20}} />
                                <StyledLabel style={{width: 250, marginBottom: 0}}>{t("simulate_switch.select_switch")}</StyledLabel>
                            </FlexContainer>
                        </ItemContainer>
                        <StyledSelect
                            value={selectedSwitch}
                            onChange={(value, option) => {setSelectedSwitch(value as number); getSwitchName((option as DefaultOptionType).label as string);}}
                            style={{width: 500, backgroundColor: "#f8f9fb", borderRadius: "50px", textAlign: "center"}}
                            suffixIcon={<CollapseIconSvg style={{color: defaultTheme.black, fontSize: "0.7em", transform: "rotate(180deg)"}}/>}
                            bordered={false}
                            options={getOptions()}
                            placeholder={"-"}
                        />
                    </FlexContainer>
                </StyledItemContainer>

                <StyledPrimaryButton isactive={true as boolean} onClick={() => {onSwitchSelect(selectedSwitch); onSimulate();}} key="confirm" textKey='simulate_switch.start_simulation' />

                {/*<Tooltip title={t('simulate_switch.modify_criteria')}><StyledIconPrimaryButton icon={<FormOutlined />} onClick={() => onEdit()} /></Tooltip>*/}

            </FlexContainer>
        </BlocContent>
    );
};

export default SwitchSelect;