import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { percentFormatter } from 'components/AgGridComponents/valueFormatters';
import { StyledModal } from 'components/Modals';
import { FooterContainer, GridContainer, Title, TitleContainer } from 'components/Modals/ExcludeModal/styles';
import { StyledFilterPrimaryButton } from 'components/Modals/StyledModal/styles';
import { SwitchContext } from 'context';
import Indicator from './Indicator';
import pictoPiecesTransfert from 'assets/images/Picto-pieces-transfert.svg';
import { ProductRow } from 'types/product';
import { ColDef } from 'ag-grid-community';
import pictoFiltres from 'assets/images/picto-désactiver-filtres.svg';
import pictoHistoriqueImport from "../../../../assets/images/picto-historique-import.svg";


type Props = {
    open: boolean,
    onCancel: () => void;
}

const ListProductModal: React.FC<Props> = ({open, onCancel, ...rest}) => {

    const { t } = useTranslation('translation');

    const { countProduct, currentProductData, setCurrentProductData, currentSwitch, setCurrentSwitch } = useContext(SwitchContext);

    const [isGridReady, setIsGridReady] = useState(false);

    const [rows, setRows] = useState<ProductRow[]>([]);

    const gridRef = useRef<AgGridReact>(null);

    const [hasClearFilters, setHasClearFilters] = useState(false);

    useEffect(() => {
      const rowsWithoutExclude = currentProductData.filter(data => !data.exclude);
      setRows(rowsWithoutExclude);
    }, [currentSwitch, countProduct, currentProductData]);

    useEffect(() =>{
      if(gridRef && gridRef.current && gridRef.current.api) gridRef.current!.api.setFilterModel({});
    }, [hasClearFilters]);

    const colDefs: ColDef[] = useMemo( () => [
        {field: 'productCode', headerName: t('grid_exclude_product.product_code'), headerClass: 'centered-columns', sortable: true, width: 200, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
        {field: 'productName', headerName: t('grid_exclude_product.label_reference'), headerClass: 'centered-columns', sortable: true, width: 250, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
        {field: 'colorName', headerName: t('grid_exclude_product.color_label'), sortable: true, width: 150, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
        {field: 'familyName', headerName: t('grid_exclude_product.family'), sortable: true, width: 200, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
        {field: 'theme', headerName: t('grid_exclude_product.theme'), sortable: true, width: 130, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
        {field: 'productPrice', headerName: t('grid_exclude_product.tariff'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
        {field: 'salesPeriod', headerName: t('grid_exclude_product.sales_period'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
        {field: 'stockStore', headerName: t('grid_exclude_product.store_stock'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
        {field: 'storeFlowRate', headerName: t('grid_exclude_product.store_flow_rate'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, valueFormatter: percentFormatter, filter: 'agNumberColumnFilter', resizable: true},
        {field: 'warehouseStock', headerName: t('grid_exclude_product.warehouse_stock'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
        {field: 'totalFlowRate', headerName: t('grid_exclude_product.total_flow_rate'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, valueFormatter: percentFormatter, filter: 'agNumberColumnFilter', resizable: true},
        {field: 'stkStoreVsStkTotal', headerName: t('grid_exclude_product.%_store_vs_total_stock'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, valueFormatter: percentFormatter, filter: 'agNumberColumnFilter', resizable: true},
        /*{field: 'exclude', headerName: t('footer.validate'), headerTooltip: t('footer.validate'),sortable: true,  filter: true, maxWidth: 120,
          cellRenderer: (row: any) =>
            <Switch
                checked={!(row.data.exclude) as boolean}
                onChange={(valueChange) => {
                    let productDataChange = currentProductData.map( data => {
                      if(data.articleId == row.data.articleId) {
                        data.exclude = !valueChange;
                      }
                      return data;});
                    setCurrentProductData(productDataChange as ProductRow[]);
                    gridRef.current?.api.redrawRows({rowNodes: row});
              }}/>
      }*/
    ], [t]);

    const onSelectExcludeProduct = () => {

    }

    const onApply = () => {

    }

    const onSelectionChanged = () => {
        const rows = gridRef.current!.api.getSelectedRows();
    };

    const onCancelModal = () => {
        onCancel();
    }

    const onExport = () => {
      gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        fileName: currentSwitch.name + '_' + t('footer.products')
      });
    }

    return (
        <>
        <StyledModal
          width={"95%"}
          centered
          closable={true}
          open={open}
          zIndex={2000}
          onCancel={onCancelModal}
          okButtonProps={{style: {display: 'none'}}}
          cancelButtonProps={{style: {display: 'none'}}}
          /*footer={
            <FooterContainer style={{justifyContent: "flex-end"}}>
                <StyledPrimaryButton onClick={onApply} key="save" text={t('footer.apply_changes')} />
            </FooterContainer>
          }*/
        >
          <TitleContainer style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
            <Title style={{marginRight: 50}}>{t('footer.list_products')}</Title>
            <Indicator number={countProduct} textKey='footer.products' iconPath={pictoPiecesTransfert} theme='blue' />
            <Tooltip title={t('simulate_switch.show_all')}><StyledFilterPrimaryButton style={{marginLeft: 10}} iconPath={pictoFiltres} onClick={() => setHasClearFilters(!hasClearFilters)}/></Tooltip>
                        <Tooltip title={t('export.export')}><StyledFilterPrimaryButton style={{marginLeft: 10, marginRight: 10}} iconPath={pictoHistoriqueImport} onClick={onExport}/></Tooltip>
          </TitleContainer>
  
          <GridContainer gridWidth={1600} gridHeight={700}>
            <AgGridReact
              ref={gridRef}
              columnDefs={colDefs}
              rowData={rows}
              rowHeight={40}
              onSelectionChanged={onSelectionChanged}
              rowSelection='multiple'
              onGridReady={() => { setIsGridReady(true); }}
            />
          </GridContainer>
  
        </StyledModal>
      </>
    );
}

export default ListProductModal;