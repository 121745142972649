import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { Outlet } from 'react-router-dom';
import I18nWrapper from 'I18nWrapper';
import defaultTheme from 'theme';

import { ErrorNotification } from 'components/UI';
import { apolloClient } from 'config/apolloClient';

import { ErrorContextProvider } from 'context';
import envconfig from 'config/envconfig';
import {LicenseManager} from "ag-grid-enterprise";

const App: React.FC = () => {
  envconfig.agGridKey && LicenseManager.setLicenseKey(envconfig.agGridKey);


  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={defaultTheme}>
        <I18nWrapper>
          <ErrorContextProvider>
            <Outlet />
            <ErrorNotification />
          </ErrorContextProvider>
        </I18nWrapper>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
