import styled from 'styled-components';

export const StyledTitle = styled.h5`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.4em;
  margin-bottom: 20px;
`;

export const PageWrapper = styled.div`
  position: fixed;
  top: 4.5em;
  width: calc(100% - 80px);
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0px 20px;
`;

interface GridContainerProps {
  gridWidth?: number,
  gridHeight?: number
}

export const GridContainer = styled.div.attrs({
  className: 'ag-theme-material'})<GridContainerProps>`

    height: calc(100vh - 200px);
    width: ${(props) => props.gridWidth + "px"};
    border: lightgray solid 1px;
    border-radius: 18px;
    padding: 10px;

    --ag-range-selection-border-style: none;
    --ag-range-selection-border-color: ${(props) => props.theme.green};
    --ag-selected-row-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-highlight-color: ${(props) => props.theme.light_green};
    --ag-material-accent-color: ${(props) => props.theme.green};
    --ag-data-color: ${(props) => props.theme.black};
    --ag-font-family: "NotoRegular";
    --ag-font-size: 1em;
`;