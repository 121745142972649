import React from 'react';

import { useTranslation } from 'react-i18next';

import StyledModal from '../StyledModal/StyledModal';
import { TitleContainer, TextContainer, StyledWarningOutlined, IconContainer, StyledPrimaryButton, StyledCancelPrimaryButton } from './styles';

interface PropsConfirmModal {
  customElement?: React.ReactNode;
  customTitle?: string;
  customTitleKey?: string;
  icon?: JSX.Element | JSX.Element[] | null;
  onCancel?: () => void;
  onOk: () => void;
  open: boolean;
  showOnlyCloseBtn?: boolean;
  text?: string;
  textKey?: string;
}

const ConfirmModal: React.FC<PropsConfirmModal> = ({
  text,
  textKey,
  customTitle,
  customTitleKey,
  icon = <StyledWarningOutlined />,
  open,
  onCancel,
  onOk,
  customElement,
  showOnlyCloseBtn = false,
}) => {
  const { t } = useTranslation('translation');

  return (
    <div>
      <StyledModal
        centered
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={
          showOnlyCloseBtn ? (
            <StyledCancelPrimaryButton onClick={onOk} key="confirm" text={t('common.close')} />
          ) : (
            [
              <StyledCancelPrimaryButton key="cancel" onClick={onCancel} text={t('common.cancel')} style={{marginRight: 20}} />,
              <StyledPrimaryButton onClick={onOk} key="confirm" text={t('common.confirm')} />,
            ]
          )
        }
        zIndex={2000}
      >
        {icon && <IconContainer>{icon}</IconContainer>}
        <TitleContainer>{customTitleKey ? t(customTitleKey) : customTitle}</TitleContainer>
        <TextContainer>{textKey ? t(textKey) : text}</TextContainer>
        {customElement && customElement}
      </StyledModal>
    </div>
  );
};

export default ConfirmModal;
