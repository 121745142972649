import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';

import { GridContainer } from './styles';
import { currencyFormatter, percentFormatter } from 'components/AgGridComponents/valueFormatters';
import { ColDef, RowClassParams } from 'ag-grid-community';
import defaultTheme from 'theme';
import {SwitchResultProductRow} from "../../../../../types/switch-result-product";

interface Props {
  rowData: SwitchResultProductRow[],
  hasClearFilters: boolean,
  onExcludeProduct: (productId: number, exclude: boolean) => void
}

const GridItem: React.FC<Props> = ({
  rowData,
  hasClearFilters,
  onExcludeProduct
  }) => {

    const { t } = useTranslation('translation');

    type PinnedType = boolean | 'left' | 'right' | null;

    const colDefs: ColDef[] = useMemo( () => [
        {field: 'productCode', headerName: t('grid_product.code'), headerTooltip: t('grid_product.code'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agTextColumnFilter', width: 125, pinned: 'left' as PinnedType, cellClass: "grid-pinned-column", resizable: true},
        {field: 'productName', headerName: t('grid_product.name'), headerTooltip: t('grid_product.name_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agTextColumnFilter', width: 125, pinned: 'left' as PinnedType, cellClass: "grid-pinned-column", resizable: true},
        {field: 'colorLabel', headerName: t('grid_product.label_color'), headerTooltip: t('grid_product.label_color_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agTextColumnFilter', width: 100, pinned: 'left' as PinnedType, cellClass: "grid-pinned-column", resizable: true},
        {field: 'price',headerName: t('grid_product.price'), headerTooltip: t('grid_product.price_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, pinned: 'left' as PinnedType, cellClass: "grid-pinned-column", resizable: true, valueFormatter: currencyFormatter},
        {field: 'periodSales', headerName: t('grid_product.sales_period'), headerTooltip: t('grid_product.sales_period_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'stockStore', headerName: t('grid_product.store_stock'), headerTooltip: t('grid_product.store_stock_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'storeFlowRate', headerName: t('grid_product.store_flow_rate'), headerTooltip: t('grid_product.store_flow_rate_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true, valueFormatter: percentFormatter},
        {field: 'warehouseStock', headerName: t('grid_product.warehouse_stock'), headerTooltip: t('grid_product.warehouse_stock_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'totalflowrate', headerName: t('grid_product.total_flow_rate'), headerTooltip: t('grid_product.total_flow_rate_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true, valueFormatter: percentFormatter},
        {field: 'warehouseVsTotalStock', headerName: t('grid_product.%_warehouse_vs_total_stock'), headerTooltip: t('grid_product.%_warehouse_vs_total_stock_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true, valueFormatter: percentFormatter},
        {field: 'transferQuantity', headerName: t('grid_product.transfer_quantity'), headerTooltip: t('grid_product.transfer_quantity_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'shipperSales', headerName: t('grid_product.shipper_sales'), headerTooltip: t('grid_product.shipper_sales_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'shipperStocks', headerName: t('grid_product.shipper_stocks'), headerTooltip: t('grid_product.shipper_stocks_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'shipperFlowRate', headerName: t('grid_product.shipper_flow_rate'), headerTooltip: t('grid_product.shipper_flow_rate_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true, valueFormatter: percentFormatter},
        {field: 'salesRecipient', headerName: t('grid_product.sales_recipient'), headerTooltip: t('grid_product.sales_recipient_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'recipientStock', headerName: t('grid_product.recipient_stock'), headerTooltip: t('grid_product.recipient_stock_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'recipientFlowRate', headerName: t('grid_product.recipient_flow_rate'), headerTooltip: t('grid_product.recipient_flow_rate_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true, valueFormatter: percentFormatter},
        {field: 'recipientBreakageRate', headerName: t('grid_product.recipient_breakage_rate'), headerTooltip: t('grid_product.recipient_breakage_rate_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true, valueFormatter: percentFormatter},
        {field: 'salesGain', headerName: t('grid_product.sales_gain'), headerTooltip: t('grid_product.sales_gain_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true},
        {field: 'gainInSales', headerName: t('grid_product.gain_in_sales'), headerTooltip: t('grid_product.gain_in_sales_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true, filter: 'agNumberColumnFilter', width: 90, cellClass: "grid-column-center", resizable: true, valueFormatter: currencyFormatter},
        {field: 'disable', headerName: t('grid_product.disable'), headerTooltip: t('grid_product.disable_tt'), autoHeaderHeight: true, wrapHeaderText: true, sortable: true,  filter: true, width: 90, cellClass: "grid-column-center",
        cellRenderer: (row: any) =>
          <Switch checked={row.data.disable as boolean} onChange={(valueChange) => {
            rowData[row.rowIndex].disable = valueChange;
            onExcludeProduct(row.data.productId, valueChange);
            setRowData(rowData);
            gridRef.current?.api.redrawRows({rowNodes: row});
          }}></Switch>
      }
    ], [t]);

    const getRowStyle = (params: RowClassParams) => {
      if (params.node.data.disable)
        return {backgroundColor: defaultTheme.pale_gray};
      else
        return {backgroundColor: defaultTheme.white};
    };

    const [colDefsGrid, setColDefs] = useState(colDefs);
    const [rowDataGrid, setRowData] = useState<SwitchResultProductRow[]>(rowData);
    const [selectedRows, setSelectedRows] = useState<unknown[] | null>(null);

    const gridRef = useRef<AgGridReact>(null);

    const onSelectionChanged = () => {
      const rows = gridRef.current!.api.getSelectedRows();
      setSelectedRows(rows);
    };

    useEffect(() =>{
      if (rowData) {
        setRowData(rowData);
      }
    },[rowData]);

    useEffect(() =>{
      if(gridRef && gridRef.current && gridRef.current.api) gridRef.current!.api.setFilterModel({});
    }, [hasClearFilters]);
  
  return (

        <GridContainer gridWidth={100} gridHeight={100}>
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefsGrid}
            rowData={rowDataGrid}
            rowHeight={40}
            onSelectionChanged={onSelectionChanged}
            getRowStyle={getRowStyle}
          />
        </GridContainer>

  );
};

export default GridItem;
