import React from 'react';

import ReactDOM from 'react-dom/client';

import Routing from 'screens/Routing';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.min.css'
import './assets/fonts/NotoSansJP-Bold.ttf'
import './assets/fonts/NotoSansJP-Medium.ttf'
import './assets/fonts/NotoSansJP-Regular.ttf'
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-material.css";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
