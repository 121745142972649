import React, {useCallback, useContext, useEffect, useState} from 'react';

import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import { BlocContent } from 'components/wrappers';

import {
  BlocSettings,
  ContainerSwitchButtons,
  FlexContainer,
  ItemContainer,
  PageWrapper,
  StyledLabel,
  StyledSettingTitle,
  StyledTitle,
  StyledPrimaryButton,
  StyledInputNumber,
  StyledInputPlaceholderInner,
  StyledRadio,
  StyledRadioGroup
} from './styles';
import SwitchButton from './components/SwitchButton/SwitchButton';
import {
  ReferencyColor,
  ReferencyTransfert, SwitchSetting, SwitchSettingFormInput,
  useGetSwitchSettingLazyQuery, useUpdateSettingsMutation,
} from 'services/graphql/main';
import { useError } from 'services/utils';
import pictoSave from 'assets/images/picto-save.svg';
import {SwitchSettingContext} from "../../context";
import {ExcludeModal, StyledModal} from "../../components/Modals";
import {GridContainer, Title, TitleContainer} from "../../components/Modals/ExcludeModal/styles";
import Indicator from "../layouts/Footer/components/Indicator";
import pictoPiecesTransfert from "../../assets/images/Picto-pieces-transfert.svg";
import {Tooltip} from "antd";
import {StyledFilterPrimaryButton} from "../../components/Modals/StyledModal/styles";
import pictoFiltres from "../../assets/images/picto-désactiver-filtres.svg";
import pictoHistoriqueImport from "../../assets/images/picto-historique-import.svg";
import {AgGridReact} from "ag-grid-react";
import ImportModal from "./components/modals/ImportModal/ImportModal";
import StoreModal from "./components/modals/StoreModal/StoreModal";
import ProductModal from "./components/modals/ProductModal/ProductModal";
import SwitchModal from "./components/modals/SwitchModal/SwitchModal";


const Parameters: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();

  const [update, setUpdate] = useState<boolean>(false);
  const {switchSetting, setSwitchSetting, tenantSettings} = useContext(SwitchSettingContext)

  const [countInactivStores, setCountInactivStores] = useState(0);

  const [switchConfigModalOpen, setSwitchConfigModalOpen] = useState<boolean>(false);
  const [storeModalOpen, setStoreModalOpen] = useState<boolean>(false);
  const [productModalOpen, setProductModalOpen] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);

  const [updateSettings] = useUpdateSettingsMutation({
    onError: (err) => addError(err, 'error'),
  });

  useEffect(() => {
    if (update) {
      const save = async () => {
        const result = await updateSettings({variables: {switchSettingInput: switchSetting}});
        if (result) {
          console.log(result.data?.updateSettings.productFamilyEnabled);
        }
      }
      save().catch(console.error);
    }
    setUpdate(false);
  }, [update]);

  const onClickImportSettings = () => {
    console.log("onClickListActivStores");
    setIsImportModalOpen(true);
  }

  // const onSaveSettings = async () => {
  //   console.log("onSave");
  //   const result = await updateSettings({variables: {switchSettingInput: switchSetting}});
  //   console.log(result);
  // }

  return (
    <PageWrapper>
        <FlexContainer>
            <StyledTitle>{t('parameters.parameters_title')}</StyledTitle>
            <BlocContent style={{flexGrow:1}}>
                <BlocSettings style={{padding: "10px 40px 30px 40px"}}>
                    <StyledSettingTitle>{t('parameters.active_criteria_title')}</StyledSettingTitle>
                    <FlexContainer style={{flexDirection: "row", gap: 20}}>

                        <ItemContainer>
                            <StyledLabel>{t("parameters.products")}</StyledLabel>
                            <FlexContainer style={{flexDirection: "row", gap: 10}}>
                                <ContainerSwitchButtons style={{width: 400}}>
                                    <SwitchButton text={t("create_switch.department")} value={switchSetting.productDepartementEnabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productDepartementEnabled: value as boolean}); setUpdate(true);} } />
                                    <SwitchButton text={t("create_switch.family")} value={switchSetting.productFamilyEnabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productFamilyEnabled: value as boolean}); setUpdate(true);} } />
                                    <SwitchButton text={t("create_switch.subfamily")} value={switchSetting.productSubFamilyEnabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productSubFamilyEnabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings && tenantSettings.productCustomFieldName1 ? "" : "none"}}/>
                                    { tenantSettings && tenantSettings.productCustomFieldName1 && <SwitchButton text={tenantSettings.productCustomFieldName1} value={switchSetting.productCustomFieldValues1Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productCustomFieldValues1Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.productCustomFieldName2 ? "" : "none"}}/> }
                                    { tenantSettings && tenantSettings.productCustomFieldName2 && <SwitchButton text={tenantSettings.productCustomFieldName2} value={switchSetting.productCustomFieldValues2Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productCustomFieldValues2Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.productCustomFieldName3 ? "" : "none"}}/> }
                                    { tenantSettings && tenantSettings.productCustomFieldName3 && <SwitchButton text={tenantSettings.productCustomFieldName3} value={switchSetting.productCustomFieldValues3Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productCustomFieldValues3Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.productCustomFieldName4 ? "" : "none"}}/> }
                                    { tenantSettings && tenantSettings.productCustomFieldName4 && <SwitchButton text={tenantSettings.productCustomFieldName4} value={switchSetting.productCustomFieldValues4Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productCustomFieldValues4Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.productCustomFieldName5 ? "" : "none"}}/> }
                                    { tenantSettings && tenantSettings.productCustomFieldName5 && <SwitchButton text={tenantSettings.productCustomFieldName5} value={switchSetting.productCustomFieldValues5Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, productCustomFieldValues5Enabled: value as boolean}); setUpdate(true);} } style={{border: "none"}}/> }
                                </ContainerSwitchButtons>
                            </FlexContainer>
                        </ItemContainer>

                        <ItemContainer>
                            <StyledLabel>{t("parameters.stores")}</StyledLabel>
                            <FlexContainer style={{flexDirection: "row", gap: 10}}>
                              <ContainerSwitchButtons style={{width: 400}}>
                                  <SwitchButton text={t("create_switch.region")} value={switchSetting.storeRegionEnabled as boolean} onSwitchButton={(value) => { setSwitchSetting({...switchSetting, storeRegionEnabled: value as boolean}); setUpdate(true);}} />
                                  <SwitchButton text={t("create_switch.type")} value={switchSetting.storeTypeEnabled as boolean} onSwitchButton={(value) => { setSwitchSetting({...switchSetting, storeTypeEnabled: value as boolean}); setUpdate(true);}} style={{border: tenantSettings && tenantSettings.storeCustomFieldName1 ? "" : "none"}}/>
                                  { tenantSettings && tenantSettings.storeCustomFieldName1 && <SwitchButton text={tenantSettings.storeCustomFieldName1} value={switchSetting.storeCustomFieldValues1Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, storeCustomFieldValues1Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.storeCustomFieldName2 ? "" : "none"}}/> }
                                  { tenantSettings && tenantSettings.storeCustomFieldName2 && <SwitchButton text={tenantSettings.storeCustomFieldName2} value={switchSetting.storeCustomFieldValues2Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, storeCustomFieldValues2Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.storeCustomFieldName3 ? "" : "none"}}/> }
                                  { tenantSettings && tenantSettings.storeCustomFieldName3 && <SwitchButton text={tenantSettings.storeCustomFieldName3} value={switchSetting.storeCustomFieldValues3Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, storeCustomFieldValues3Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.storeCustomFieldName4 ? "" : "none"}}/> }
                                  { tenantSettings && tenantSettings.storeCustomFieldName4 && <SwitchButton text={tenantSettings.storeCustomFieldName4} value={switchSetting.storeCustomFieldValues4Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, storeCustomFieldValues4Enabled: value as boolean}); setUpdate(true);} } style={{border: tenantSettings.storeCustomFieldName5 ? "" : "none"}}/> }
                                  { tenantSettings && tenantSettings.storeCustomFieldName5 && <SwitchButton text={tenantSettings.storeCustomFieldName5} value={switchSetting.storeCustomFieldValues5Enabled as boolean} onSwitchButton={(value) => {setSwitchSetting({...switchSetting, storeCustomFieldValues5Enabled: value as boolean}); setUpdate(true);} } style={{border: "none"}}/> }
                              </ContainerSwitchButtons>
                            </FlexContainer>
                      </ItemContainer>

                    </FlexContainer>
                </BlocSettings>
                <BlocSettings>
                  <ItemContainer>
                    <StyledSettingTitle>{t("parameters.default_transfer")}</StyledSettingTitle>
                    <FlexContainer style={{flexDirection: "row", gap: 10}}>
                      <StyledRadioGroup
                        value={switchSetting.transfertDefaultRC}
                        onChange={(event) => { setSwitchSetting({...switchSetting, transfertDefaultRC: event.target.value as ReferencyColor}); setUpdate(true); }}
                      >
                        <StyledRadio value={ReferencyColor.Complete}>{t("create_switch.part_no_color_complete")}</StyledRadio>
                        <StyledRadio value={ReferencyColor.SizeOnly} style={{width: 350, borderRight: "none", marginRight: 0}}>{t("create_switch.reference_color_size_only")}</StyledRadio>
                      </StyledRadioGroup>
                      <StyledRadioGroup
                        value={switchSetting.transfertDefaultRCT}
                        onChange={(event) => { setSwitchSetting({...switchSetting, transfertDefaultRCT: event.target.value as ReferencyTransfert}); setUpdate(true); }}
                      >
                        <StyledRadio value={ReferencyTransfert.InterStore}>{t("create_switch.inter_store_transfer")}</StyledRadio>
                        <StyledRadio value={ReferencyTransfert.ReturnWarehouse} style={{width: 250}}>{t("create_switch.return_to_warehouse")}</StyledRadio>
                        <StyledRadio value={ReferencyTransfert.Both} style={{width: 200, borderRight: "none", marginRight: 0}}>{t("create_switch.transfert_return")}</StyledRadio>
                      </StyledRadioGroup>
                    </FlexContainer>
                  </ItemContainer>
                </BlocSettings>
                <BlocSettings>
                  <FlexContainer>
                    <ItemContainer>
                      <StyledSettingTitle>{t('dashboard.my_switches.title')}</StyledSettingTitle>
                      <StyledPrimaryButton style={{width: 530, padding: "0.5em 0em", marginLeft: 20}} textKey="parameters.view_list_switch" onClick={() => setSwitchConfigModalOpen(true)} />
                    </ItemContainer>
                  </FlexContainer>
                </BlocSettings>
                <BlocSettings>
                  <FlexContainer style={{flexDirection: "row"}}>
                    <ItemContainer>
                      <StyledSettingTitle>{t('parameters.list_active_stores_title')}</StyledSettingTitle>
                      <StyledPrimaryButton countBadge={countInactivStores} style={{width: 530, padding: "0.5em 0em", marginLeft: 20}} textKey="parameters.view_list_active_stores" onClick={() => setStoreModalOpen(true)} />
                    </ItemContainer>
                    <ItemContainer style={{marginLeft: 100}}>
                      <StyledSettingTitle>{t('parameters.list_active_products_title')}</StyledSettingTitle>
                      <StyledPrimaryButton countBadge={countInactivStores} style={{width: 530, padding: "0.5em 0em", marginLeft: 20}} textKey="parameters.view_list_active_products" onClick={() => setProductModalOpen(true)} />
                    </ItemContainer>
                  </FlexContainer>
                </BlocSettings>
                <BlocSettings>
                    <StyledSettingTitle>{t('parameters.plant_size_threshold_title')}</StyledSettingTitle>
                    <FlexContainer style={{flexDirection: "row", marginLeft: 20}}>
                        <ItemContainer>
                            <StyledLabel style={{fontFamily: "NotoRegular", width: 150}}>{t("parameters.percent_sales")}</StyledLabel>
                        </ItemContainer>
                        <ItemContainer style={{position: "relative"}}>
                            <StyledInputNumber
                              controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                              value={switchSetting.centralSizeThreshold}
                              onChange={(value) => { setSwitchSetting({...switchSetting, centralSizeThreshold: value as number}); setUpdate(true);}}
                            />
                            <StyledInputPlaceholderInner>{'%'}</StyledInputPlaceholderInner>
                        </ItemContainer>
                    </FlexContainer>
                </BlocSettings>
                <BlocSettings>
                    <StyledSettingTitle>{t('parameters.transport_title')}</StyledSettingTitle>
                    <FlexContainer style={{flexDirection: "row", marginLeft: 20}}>
                        <ItemContainer>
                            <StyledLabel style={{fontFamily: "NotoRegular", width: 150}}>{t("parameters.removal_cost")}</StyledLabel>
                        </ItemContainer>
                        <ItemContainer style={{position: "relative"}}>
                            <StyledInputNumber
                              controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                              value={switchSetting.transportRemovalCost}
                              onChange={(value) => { setSwitchSetting({...switchSetting, transportRemovalCost: value as number}); setUpdate(true);}}
                            />
                            <StyledInputPlaceholderInner>{'€'}</StyledInputPlaceholderInner>
                        </ItemContainer>
                        <ItemContainer style={{marginLeft: 100}}>
                            <StyledLabel style={{fontFamily: "NotoRegular", width: 150}}>{t("parameters.cost_per_piece")}</StyledLabel>
                        </ItemContainer>
                        <ItemContainer style={{position: "relative"}}>
                            <StyledInputNumber
                              controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                              value={switchSetting.transportCostPerItem}
                              onChange={(value) => { setSwitchSetting({...switchSetting, transportCostPerItem: value as number}); setUpdate(true);}}
                            />
                            <StyledInputPlaceholderInner>{'€'}</StyledInputPlaceholderInner>
                        </ItemContainer>
                        <ItemContainer style={{marginLeft: 100}}>
                        <StyledLabel style={{fontFamily: "NotoRegular", width: 150}}>{t("parameters.co2_per_km")}</StyledLabel>
                        </ItemContainer>
                        <ItemContainer style={{position: "relative"}}>
                          <StyledInputNumber
                            style={{width: 150}}
                            controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                            value={switchSetting.co2PerKm}
                            onChange={(value) => { setSwitchSetting({...switchSetting, co2PerKm: value as number}); setUpdate(true);}}
                          />
                          <StyledInputPlaceholderInner>{'kg C02 / km'}</StyledInputPlaceholderInner>
                        </ItemContainer>
                    </FlexContainer>
                </BlocSettings>
                <BlocSettings style={{border: "none"}}>
                    <StyledSettingTitle>{t('parameters.file_import_title')}</StyledSettingTitle>
                    <StyledPrimaryButton style={{width: 530, padding: "0.5em 0em", marginLeft: 20}} textKey="parameters.import_settings" onClick={() => onClickImportSettings()} />
                </BlocSettings>
                {/*<StyledPrimaryButton iconPath={pictoSave} style={{position: "fixed", top: "6em", width: 200, padding: "0.5em 1em", marginLeft: "75%"}} textKey="common.save" onClick={() => onSaveSettings()} />*/}
            </BlocContent>

          {switchConfigModalOpen && <SwitchModal height={150} customTitleKey={'parameters.list_switch_title'} open={switchConfigModalOpen} onCancel={() => setSwitchConfigModalOpen(false)} />}
          {storeModalOpen && <StoreModal width={1100} customTitleKey={'parameters.list_active_stores_title'} open={storeModalOpen} onCancel={() => setStoreModalOpen(false)} />}
          {productModalOpen && <ProductModal width={"95%"} height={700} customTitleKey={'parameters.list_active_products_title'} open={productModalOpen} onCancel={() => setProductModalOpen(false)} />}
          {isImportModalOpen && <ImportModal isOpen={isImportModalOpen} handleCancel={() => setIsImportModalOpen(false)}/* refetchData={refetchData}*/ />}
        </FlexContainer>
    </PageWrapper>
  );
};

export default Parameters;
