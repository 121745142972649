import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { Radio, RadioChangeEvent } from "antd";

import { useTranslation } from "react-i18next";
import { ReactComponent as RequireSvg } from 'assets/images/Picto-champ-oligatoire.svg';
import { FlexContainer, ItemContainer, StyledInputText, StyledLabel, StyledRadio, StyledRadioGroup, StyledSelect, StyledVisibleLabel } from "./styles";
import { CaretDownOutlined } from "@ant-design/icons";
import defaultTheme from "theme";
import {
  ReferencyColor,
  ReferencyTransfert, Universe,
  useGetSimpleSwitchesConfigsQuery,
  useGetSwitchConfigByIdLazyQuery,
  useGetSwitchConfigByIdQuery,
  useGetSwitchConfigFormByIdLazyQuery,
  useGetUniversesQuery
} from "services/graphql/main";
import {SwitchContext, SwitchSettingContext} from "context";
import { useError } from "services/utils";
import { BaseOptionType } from "antd/lib/select";
import {toast} from "react-toastify";

type Props = {
  children?: React.ReactNode;
  switchId?: number;
  onMySwitch: () => void;
}

const MySwitch: React.FC<Props> = ({children, switchId, onMySwitch}) => {

    const { t } = useTranslation('translation');
    const { addError } = useError();

    const {currentSwitch, setCurrentSwitch, resetSwitch, switchConfigFormToform} = useContext(SwitchContext);

    const {switchSetting} = useContext(SwitchSettingContext);

    const [simpleSwitchesConfigs, setSimpleSwitchesConfigs] = useState<({id: number; name?: string | null | undefined} | null)[] | null | undefined>(null);

    const [selectedSwitch, setSelectSwitch] = useState<number | null | undefined>(switchId);

    const [selectedUniverse, setSelectedUniverse] = useState<number | null>(null);

  const {
      loading: loadingSimpleSwitchesConfigsQuery,
      data: simpleSwitchesConfigsDataQuery,
      refetch: refetchSwitchesConfigsQuery,
    } = useGetSimpleSwitchesConfigsQuery({ variables: {isSaved: true}, onError: (err) => addError(err, 'error') });

    const [
      getSwitchConfigByIdLazyQuery,
      { loading: loadingSwitchConfigByIdQuery, error: errorSwitchConfigByIdDataQuery, refetch: switchConfigByIdDataQuery },
    ] = useGetSwitchConfigFormByIdLazyQuery({ onError: (err) => addError(err, 'error') });

    const [isDisabledSelectName, setIsDisabledSelect] = useState<boolean>(true);
    const [isDisabledInputName, setIsDisabledInputName] = useState<boolean>(true);
    const [valRadioSwitchType, setValRadioSwitchType] = useState<string | null>(null)

    const {
      loading: loadingUniversesDataQuery,
      data: universesDataQuery,
      refetch: refetchUniversesQuery,
    } = useGetUniversesQuery({ onError: (err) => addError(err, 'error') });

    const [universes, setUniverses] = useState<(Universe | null)[] | null | undefined>();

    useEffect(() => {
      if (simpleSwitchesConfigsDataQuery) { 
        setSimpleSwitchesConfigs(simpleSwitchesConfigsDataQuery.switchesConfigs);
      }
    }, [simpleSwitchesConfigsDataQuery]);

    useEffect(() => {
        resetSwitch(switchSetting);
    }, []);

    const loadSwitchConfigData =  useCallback( async () =>{
      let result = await getSwitchConfigByIdLazyQuery({variables: {id: selectedSwitch as number}})
      if (result.data?.switchConfigForm) { 
        switchConfigFormToform(result.data, switchSetting);
      }
    }, [getSwitchConfigByIdLazyQuery, selectedSwitch, switchSetting])

    useEffect(() => {
      if (selectedSwitch && switchSetting) {
        loadSwitchConfigData();
        onMySwitch();
        setValRadioSwitchType('exist');
        setIsDisabledSelect(false);
      }
    }, [selectedSwitch, switchSetting]);

    useEffect(() => {
      if (universesDataQuery) {
        setUniverses(universesDataQuery.universes);
        if (universesDataQuery.universes) {
          setCurrentSwitch({...currentSwitch, universeId: universesDataQuery.universes[0].id});
        }
      }
    }, [universesDataQuery]);

    const onChangeRadioSwitchType = (e: RadioChangeEvent) => {
        let value = e.target.value;
        setValRadioSwitchType(value);
        if ( value == 'exist' ) {
          setIsDisabledSelect(false);
          setIsDisabledInputName(true);
          setCurrentSwitch({...currentSwitch, name: ''});
          resetSwitch(switchSetting, currentSwitch.universeId);
        } else {
          setIsDisabledSelect(true);
          setIsDisabledInputName(false);
          setSelectSwitch(null);
          resetSwitch(switchSetting, currentSwitch.universeId);
        }
    };

    const onChangeSelectSwith = (value: unknown) => {
        setSelectSwitch(value as number);
        onMySwitch();
    };

    const onKeyUpSwitchName = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        let alreadyUse = simpleSwitchesConfigs?.map(a => a?.name).includes(value);
        if (alreadyUse) {
          let msg = t('create_switch.already_use');
          toast.warning(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: 'colored'
          });
        }
        if (value || value == '') {
            onMySwitch();
            setCurrentSwitch({...currentSwitch, name: value}, alreadyUse);
        }
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input: string, option?: BaseOptionType) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
      <>
        <FlexContainer style={{flexDirection: "row"}}>
          <ItemContainer style={{marginBottom: 20}}>
            <FlexContainer style={{flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end"}}>
              <ItemContainer>
                <StyledLabel style={{width: 250}}>{t("create_switch.brand_choice")}</StyledLabel>
              </ItemContainer>
              <ItemContainer>
                <FlexContainer style={{flexDirection: "row", gap: 0, alignItems: "flex-end"}}>
                  <ItemContainer style={{height: 30}}>
                    <RequireSvg style={{width: 20}}/>
                  </ItemContainer>
                  <ItemContainer>
                    <FlexContainer style={{flexDirection: "column"}}>
                      <StyledVisibleLabel visibility={!selectedUniverse}>{t("create_switch.brand")}</StyledVisibleLabel>
                      <StyledSelect
                        getPopupContainer={(trigger)=> trigger.parentNode}
                        value={(currentSwitch.universeId == 0 ? '' : currentSwitch.universeId)}
                        onChange={(value) => setCurrentSwitch({...currentSwitch, universeId: value as number, storeSenderExcludesIds: [], storeRecipientExcludesIds: [], productExcludesIds: []}) }
                        style={{width: 230}}
                        suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                        bordered={false}
                        options={universes?.map((universe) => ({value: universe?.id, label: universe?.name}))}
                        placeholder={t("create_switch.brand")}
                        filterOption={filterOption}
                      />
                    </FlexContainer>
                  </ItemContainer>
                </FlexContainer>
              </ItemContainer>
            </FlexContainer>
          </ItemContainer>
        </FlexContainer>
        <FlexContainer style={{flexDirection: "row", flexWrap: "wrap", marginTop: 30}}>

        <ItemContainer style={{marginBottom: 30}}>
          <FlexContainer style={{flexDirection: "column", width: 850}}>

              <ItemContainer style={{marginBottom: 20}}>
                <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.switch_type")}</StyledLabel>
                <Radio.Group value={valRadioSwitchType} onChange={onChangeRadioSwitchType}>
                  <Radio value={'exist'}>{t("create_switch.use_an_existing_switch")}</Radio>
                  <Radio value={'new'}>{t("create_switch.new_switch")}</Radio>
                </Radio.Group>
              </ItemContainer>

              <ItemContainer>
                <FlexContainer style={{flexDirection: "row"}}>

                  <ItemContainer>
                    <FlexContainer style={{flexDirection: "column"}} id="containerYourSwitch">
                    <StyledVisibleLabel visibility={(!(selectedSwitch))}>{t("create_switch.your_switch")}</StyledVisibleLabel>
                      <StyledSelect
                        getPopupContainer={(trigger)=> trigger.parentNode}
                        disabled={isDisabledSelectName}
                        value={selectedSwitch}
                        suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                        style={{ color: 'black', width: 300}}
                        bordered={false}
                        options={ simpleSwitchesConfigs?.map((simpleSC) => ({value: simpleSC?.id as number, label: simpleSC?.name as string})) }
                        placeholder={t("create_switch.select_a_switch")}
                        onChange={onChangeSelectSwith}
                        filterOption={filterOption}
                      />
                    </FlexContainer>
                  </ItemContainer>

                  <ItemContainer>
                    <FlexContainer style={{flexDirection: "column"}}>
                      <StyledVisibleLabel visibility={!(currentSwitch.name)}>{t("create_switch.your_switch_name")}</StyledVisibleLabel>
                      <StyledInputText
                        maxLength={30}
                        disabled={isDisabledInputName}
                        value={currentSwitch.name as string}
                        type={"text"} 
                        placeholder={t("create_switch.your_switch_name")} 
                        style={{width: 300}}
                        onChange={onKeyUpSwitchName}
                      />
                    </FlexContainer>
                  </ItemContainer>

                </FlexContainer>
              </ItemContainer>

          </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{marginBottom: 30}}>
          <FlexContainer style={{flexDirection: "column"}}>
          <ItemContainer style={{marginBottom: 20}}>
            <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.reference")}</StyledLabel>
            <StyledRadioGroup
              value={currentSwitch.referencyColor} 
              onChange={(event) => setCurrentSwitch({...currentSwitch, referencyColor: event.target.value as ReferencyColor})}
            >
              <StyledRadio value={ReferencyColor.Complete}>{t("create_switch.part_no_color_complete")}</StyledRadio>
              <StyledRadio value={ReferencyColor.SizeOnly} style={{width: 350, borderRight: "none", marginRight: 0}}>{t("create_switch.reference_color_size_only")}</StyledRadio>
            </StyledRadioGroup>
            </ItemContainer>
            <ItemContainer>
            <StyledRadioGroup
              value={currentSwitch.referencyTransfert}
              onChange={(event) => setCurrentSwitch({...currentSwitch, referencyTransfert: event.target.value as ReferencyTransfert})}
            >
              <StyledRadio value={ReferencyTransfert.InterStore}>{t("create_switch.inter_store_transfer")}</StyledRadio>
              <StyledRadio value={ReferencyTransfert.ReturnWarehouse} style={{width: 250}}>{t("create_switch.return_to_warehouse")}</StyledRadio>
              <StyledRadio value={ReferencyTransfert.Both} style={{width: 200, borderRight: "none", marginRight: 0}}>{t("create_switch.transfert_return")}</StyledRadio>
            </StyledRadioGroup>
            </ItemContainer>
          </FlexContainer>
        </ItemContainer>

      </FlexContainer>
      </>
    )
};

export default MySwitch;