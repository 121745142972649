import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import AllRoutes from "screens/AllRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledTabsSwitch } from "./styles";

const TabsSwitch: React.FC = () => {
    const { t } = useTranslation('translation');
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.pathname);
    const navigate = useNavigate();

    const handleTabChange = useCallback(
      (activeKey: string) => {
        navigate(activeKey);
        setActiveTab(activeKey);
      },
      [navigate],
    );

    const itemsTabsList = [
        {
          key: AllRoutes.Dashboard,
          label: t('dashboard.dashboard_title'),
        },
        {
          key: AllRoutes.SwitchCreate,
          label: t('create_switch.create_title'),
        },
        {
          key: AllRoutes.SwitchSimulate,
          label: t('simulate_switch.simulate_title'),
        },
        {
          key: AllRoutes.SwitchHistory,
          label: t('history_switch.history_title'),
        },
    ];

    useEffect(() => {
      setActiveTab(location.pathname);
    }, [location]);

    return (
      <StyledTabsSwitch
        activeKey={activeTab}
        onChange={handleTabChange}
        items={itemsTabsList}
        fullContentHeight={false}
     />
    )
};

export default TabsSwitch;