import React from 'react';

import { useTranslation } from 'react-i18next';

import { BlocContent } from 'components/wrappers';
import Summary from './components/Summary';
import Newsletter from './components/Newsletter';
import {
  FlexContainer,
  PageWrapper,
  BlurOverlay
} from './styles';
import Myswitches from './components/Myswitches';


const Dashboard: React.FC = () => {
  const { t } = useTranslation('translation');

  return (
    <PageWrapper>
      <FlexContainer style={{width: '100%', height: '100%'}}>
        <BlocContent style={{flexGrow:1, padding: "20px 20px", width: '50%', height: '100%'}}>
          <Summary></Summary>
        </BlocContent>
        {/*<BlocContent style={{flexGrow:1, padding: "20px 20px"}}>
          <BlurOverlay>
            <Newsletter></Newsletter>
          </BlurOverlay>
        </BlocContent>*/}
      </FlexContainer>
      {/*<BlocContent style={{flexGrow:1, padding: "20px 20px"}}>
          <BlurOverlay>
            <Myswitches></Myswitches>
          </BlurOverlay>
      </BlocContent>*/}
    </PageWrapper>
  );
};

export default Dashboard;
