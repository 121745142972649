import { Switch } from 'antd';
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton';
import styled from 'styled-components';

export const IndicatorContainer =  styled.div<{$theme: string}>`
    height: 8.0em;
    border-radius: 11px;
    padding: 6px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-flow: center;
    align-items: center;
    gap: 8px;
    ${(props) => {
        switch (props.$theme) {
            case "blue":
                return `background-color: ${props.theme.blue_background_indicator}`
            case "purple":
                return `background-color: ${props.theme.purple_background_indicator}`
            case "yellow":
                return `background-color: ${props.theme.yellow_background_indicator}`
            case "green":
                return `background-color: ${props.theme.green_background_indicator}`
            default:
                return `background-color: ${props.theme.white}`
        }
    }}
`;

export const IndicatorSubContainer = styled.div<{$theme: string}>`

    height: 2.5em;
    border-radius: 11px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex-flow: center;
    align-items: center;
    gap: 20px 10px;
    ${(props) => {
        switch (props.$theme) {
            case "blue":
                return `background-color: ${props.theme.blue_foreground_indicator}`
            case "purple":
                return `background-color: ${props.theme.purple_foreground_indicator}`
            case "yellow":
                return `background-color: ${props.theme.yellow_foreground_indicator}`
            case "green":
                    return `background-color: ${props.theme.green_foreground_indicator}`
            default:
                return `background-color: ${props.theme.white}`
        }
    }}
`;

export const IndicatorSubIconContainer = styled.div`
    width: 36px;
    height: 2.5em;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    left: 10px;
`;

export const StyledLabelNumber = styled.label<{unit?: string}>`
    width: 45px;
    text-align: center;
    font-family: "NotoBold";
    font-size: 14pt;
    padding-left: 5px;
    color: ${(props) => props.theme.black};

    :after {
        font-family: "NotoRegular";
        font-size: 9pt;
        position: relative;
        top: -14px;
        left: 1px;
        content: '${(props) => props.unit}';
    }

`;

export const StyledLabelText = styled.label`
    width: 105px;
    font-family: "NotoMedium";
    font-size: 8.6pt;
    margin-right: 10px;
    color: ${(props) => props.theme.black};
`;

export const StyledIcon = styled.i<{$iconPath: string}>`
    display: inline-block;
    margin: 8px;
    height: 18px;
    width: 19px;
    background-image:  ${(props) => `url(${props.$iconPath})`};
    background-size: cover;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;

    .ant-switch-checked {
        background-color: ${(props) => props.theme.green} !important;
      }
`;

export const ItemContainer = styled.div`
    flex-grow: 0;
`;

interface GridContainerProps {
    gridWidth?: number,
    gridHeight?: number
}

export const GridContainer = styled.div.attrs({
    className: 'ag-theme-material'})<GridContainerProps>`

    height: 64%;
    width: 100%;

    border: lightgray solid 1px;
    border-radius: 18px;
    padding: 10px;

    --ag-range-selection-border-style: none;
    --ag-range-selection-border-color: ${(props) => props.theme.green};
    --ag-selected-row-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-highlight-color: ${(props) => props.theme.light_green};
    --ag-material-accent-color: ${(props) => props.theme.green};
    --ag-data-color: ${(props) => props.theme.black};
    --ag-font-family: "NotoRegular";
    --ag-font-size: 1em;
  `;

  export const StyledSwitch = styled(Switch)`
`;

export const StyledLabel = styled.h5`
  color: ${(props) => props.theme.black};
  font-family: "NotoMedium";
  font-size: 0.9em;
`;

export const StyledViewPrimaryButton = styled(PrimaryButton)`
    font-size: 20px;
    padding: 0.3em 0.2em 0.3em 0.6em;
`;

export const StyledEditPrimaryButton = styled(PrimaryButton)`
    font-size: 20px;
    padding: 0.3em 0.2em 0.3em 0.6em;
`;

export const StyledRedirectPrimaryButton = styled(PrimaryButton)`
    font-size: 20px;
    padding: 0.3em 0.2em 0.3em 0.6em;
`;
