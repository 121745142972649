import { Radio } from 'antd';
import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const FlexContainer =  styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px 30px;
`;

export const ItemContainer = styled.div`
  flex-grow: 0;

  .ant-switch-checked {
    background-color: ${(props) => props.theme.green} !important;
  }

  .ant-radio-wrapper {
    flex-direction: row;
    color: ${(props) => props.theme.black};
    font-family: "NotoMedium";
    font-size: 16px;
    padding: 15px;
  }

  .ant-radio {
    top: .1em;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.green};
    border-width: 5px;
    width: 20px;
    height: 20px;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 0;
  }

  span.ant-radio-inner::after {
    background-color: ${(props) => props.theme.white};
  }
`;

interface StyledItemContainerProps {
  width?: number
}

export const StyledItemContainer = styled.div<StyledItemContainerProps>`
  width: ${(props) => props.width ? props.width + "px" : "100%"};
  border: ${(props) => props.theme.light_soft_gray} solid 1px;
  padding: 10px;
  border-radius: 10px;
`;

export const Title = styled.span`
  color: ${(p) => p.theme.black};
  font-family: "NotoMedium";
  font-size: 1.6em;
  margin-right: 30px;
`;

export const TitleContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-itmes: center;
  align-content: center;
  align-items: baseline;
  gap: 10px;
  padding-bottom: 10px;
`;

export const TilteSeparator = styled.span`
  color: ${(p) => p.theme.light_gray};
  font-family: "NotoMedium";
  font-size: 1.1em;
`;

export const TilteSecond = styled.span`
  color: ${(p) => p.theme.blue};
  font-family: "NotoMedium";
  font-size: 1.1em;
`;

export const StyledLabel = styled.h5`
    color: ${(props) => props.theme.black};
    font-family: "NotoMedium";
    font-size: 1.06em;
`;

export const StyledRadio = styled(Radio)`
  font-size: 16px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-itmes: center;
  align-content: center;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  border: ${(props) => props.theme.light_soft_gray} solid 1px;
  padding: 10px;
  border-radius: 10px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  font-family: "NotoMedium";
  font-size: 1.06em;
  border-radius: 50px;
  padding: 0.5em 8em;
`
export const StyledViewPrimaryButton = styled(PrimaryButton)`
    font-size: 20px;
    padding: 0.3em 0.2em 0.3em 0.6em;
    margin-left: 20px;
`;