import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ColDef, ICellRendererParams, MenuItemDef } from 'ag-grid-community';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';

import { useError } from 'services/utils';
import { NakedButton } from 'components/UI';
import { BoxTitle, DetailsBox, FlexContainer, GridContainer, StyledNakedButton, StyledPrimaryButton, StyledTitle, SubTitle, TitleWrapper } from './styles';
import pictoHistoryImport from 'assets/images/picto-historique-import.svg';
import checkSvg from 'assets/images/check.svg';
import warningSvg from 'assets/images/warning.svg';
import pictoPlusSvg from 'assets/images/picto+.svg';
import { StyledIcon } from 'components/UI/PrimaryButton/styles';
import {useGetDashboardInfoQuery} from "../../../services/graphql/main";
import LastImportDetails from "./components/LastImportDetails";

export type GetDashboardInfoQuery = {
  __typename?: 'queries';
  dashboard: {
    __typename?: 'DashboardDto';
    exportId: number;
    exportDate: any;
    newsletterTitle?: string | null;
    newsletterSubtitle?: string | null;
    newsletterBody?: string | null;
    newsletterImgUrl?: string | null;
    importLogs?: Array<{
      __typename?: 'FileImportLog';
      id: number;
      importDate: any;
      object?: string | null;
      errorsCount: number;
      isSuccessful: boolean;
      errors?: string | null;
    } | null> | null;
  };
};

const Summary: React.FC = () => {
  const { t } = useTranslation('translation');
  const { addError } = useError();
  const gridRef = useRef<AgGridReact<{ object: string }[]>>(null);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedLineId, setSelectedLineId] = useState<number>();
  const [errorsData, setErrorsData] = useState();

  const { data: dashboardData } = useGetDashboardInfoQuery({
    onError: (err) => addError(err, 'warning'),
  });
  useEffect(() => {
    if (selectedLineId) {
      const errors = dashboardData?.dashboard?.importLogs?.find((log) => log?.id === selectedLineId)?.errors;
      setErrorsData(errors ? JSON.parse(errors) : []);
    }
  }, [selectedLineId, dashboardData?.dashboard?.importLogs]);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {field: 'importDate', headerName: t('dashboard.daily_summary.import_date'), minWidth: 200, valueFormatter: (params) => {
        return moment(params.value).local().format('DD-MM-YYYY HH:mm:ss');}, cellClass: "grid-column"},
      {field: 'object', headerName: t('dashboard.daily_summary.lib_interface'), minWidth: 250, cellClass: "grid-column"},
      {field: 'errorsCount', headerName: t('dashboard.daily_summary.errors_count'), minWidth: 180, cellClass: "grid-column-center"},
      {field: 'isSuccessful',headerName: t('dashboard.daily_summary.integration'), minWidth: 130,
        cellRenderer: (props: ICellRendererParams) => {
          return props.data.isSuccessful ? (
            <StyledIcon $iconPath={checkSvg} />
          ) : (
            <StyledIcon $iconPath={warningSvg} />
          );
        },
        cellClass: "grid-column-center"
      },
      {
        suppressMenu: true,
        sortable: false,
        cellStyle: { textAlign: 'center' },
        maxWidth: 60,
        editable: false,
        cellRenderer: (props: ICellRendererParams) => {
          return (
            <StyledNakedButton type="text" onClick={() => setSelectedLineId(props.data.id)}>
              <StyledIcon style={{width: 12, height: 12}} $iconPath={pictoPlusSvg} />
            </StyledNakedButton>
          );
        }
      },
    ],
    [t],
  );

  const columnErrorsDefs: ColDef[] = [
    {field: 'lineIndex', headerName: t('dashboard.daily_summary.line_index'),},
    {field: 'code', headerName: t('dashboard.daily_summary.error_message'),
      valueFormatter: (params) => {
        if (params.data.args?.length) {
          return t(`errors.${params.value}`);// à modifier
        } else {
          return t(`errors.${params.value}`);
        }
      },
    },
    {field: 'field', headerName: t('dashboard.daily_summary.field'),
      valueFormatter: (params) => {
        if (params.data.field) {
          return params.data.field;
        } else if (params.data.args?.length) {
          return params.data.args.join(', ');
        }
      },
    },
  ];

  const onExport = useCallback(() => {
    gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        processCellCallback: function (params) {
          if (params.column.getColId() === 'field') {
            if (params?.node?.data.field) {
              return params?.node?.data.field;
            } else if (params?.node?.data.args?.length) {
              return params?.node?.data.args.join(', ');
            }
          }

          return params.value;
        },
        fileName: `${t('dashboard.daily_summary.data_import_errors')}_${moment(new Date()).local().format('YYYY-MM-DD HH:mm:ss')}`, // à modifier
      });
  }, [t]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
    };
  }, []);

  const getContextExcelExportMenu = (onExport: () => void) => {
    const result: (string | MenuItemDef)[] = [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'paste',
      {
        name: 'Excel Export',
        icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
        action: () => {
          onExport();
        },
      },
    ];
  
    return result;
  };


  return (
    <>
    <StyledTitle>{t('dashboard.daily_summary.title')}</StyledTitle>

    <DetailsBox>
      <FlexContainer>
        <TitleWrapper>
          <BoxTitle>
            {dashboardData?.dashboard?.importLogs
              ? moment(dashboardData?.dashboard?.importLogs[0]?.importDate).local().format('DD-MM-YY')
              : ''}
          </BoxTitle>
          <BoxTitle>
            {dashboardData?.dashboard?.importLogs
              ? moment(dashboardData?.dashboard?.importLogs[0]?.importDate).local().format('HH:mm:ss')
              : ''}
          </BoxTitle>
          <SubTitle>{t('dashboard.daily_summary.last_data_import')}</SubTitle>
        </TitleWrapper>
        <StyledPrimaryButton iconPath={pictoHistoryImport} style={{padding: "0.5em 1em", fontSize: "14px", width: 240}} onClick={() => setDetailsModalOpen(true)} key="history" textKey={'dashboard.daily_summary.history_import'} />
      </FlexContainer>
    {errorsData ? (
      <>
        <NakedButton
          type="text"
          onClick={() => {
            setErrorsData(undefined);
            setSelectedLineId(undefined);
          }}
        >
          <ArrowLeftOutlined style={{ cursor: 'pointer', textAlign: 'center', fontSize: 20, marginTop: 20 }} />
        </NakedButton>
        <GridContainer style={{border: 'lightgray solid 1px', borderRadius: '18px'}}>
            <AgGridReact
              containerStyle={{ width: '100%', marginTop: 10, height: 287 }}
              ref={gridRef}
              columnDefs={columnErrorsDefs}
              rowData={errorsData}
              defaultColDef={defaultColDef}
              getContextMenuItems={() => getContextExcelExportMenu(onExport)}
              domLayout={'normal'}
            />
        </GridContainer>
      </>
    ) : (
      <GridContainer>
          <AgGridReact
            containerStyle={{ width: '100%', height: '500px'}}
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={dashboardData?.dashboard.importLogs}
            defaultColDef={defaultColDef}
            domLayout={'normal'}
          />
      </GridContainer>
    )}
  </DetailsBox>
  <LastImportDetails isOpen={detailsModalOpen} handleCancel={() => setDetailsModalOpen(false)} />
  </>
  );
};

export default Summary;
