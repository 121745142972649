import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  StyledTitle,
} from './styles';

const Customers: React.FC = () => {
  const { t } = useTranslation('translation');

  return (
    <>
      <StyledTitle>{t('customers.customers_title')}</StyledTitle>
    </>
  );
};

export default Customers;
