import React from 'react';

import { SiderProps } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import styled, { DefaultTheme } from 'styled-components';

export type StyledSiderProps = SiderProps & { theme?: DefaultTheme };

const LightSider: React.FC<StyledSiderProps> = (props) => <Sider {...props} theme="light" />;

export const StyledSider = styled(LightSider)`
  background-color: ${(p) => p.theme.white};
  padding: 10px 18px;
  min-width: 72px !important;
  max-width: 72px !important;
  width: 72px !important;
  position: fixed;
  height: 100%;

  .ant-layout-sider-trigger {
    background: transparent;
    color: ${(p) => p.theme.white};
  }
`;
