import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
  background: transparent;
  min-height: 100vh;
  overflow: hidden;
`;

interface PageWrapperProps {
  noPadding: boolean;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  background-color: ${(p) => p.theme.dark_blue_background};
  margin-left: 72px;
  width: 100%;
  padding: ${(p) => (p.noPadding ? 0 : '20px')};
  overflow-y: auto;

  & .ant-upload-list {
    width: 270px;
  }
`;
