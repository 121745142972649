import React from 'react';

import { ButtonProps } from 'antd/lib/button';
import { useTranslation } from 'react-i18next';

import { StyledBadge, StyledButton, StyledIcon } from './styles';

type Props = ButtonProps & {
  text?: string;
  textKey?: string;
  countBadge?: number;
  iconPath?: string
};

const PrimaryButton: React.FC<Props> = ({ text, textKey, countBadge, iconPath, ...rest }) => {
  const { t } = useTranslation('translation');

  return  <StyledButton {...rest}>
              {textKey ? t(textKey) : text}
              {countBadge && countBadge > 0 ? <StyledBadge count={countBadge} /> : ''}
              {iconPath ? <StyledIcon $iconPath={iconPath} /> : ''}
          </StyledButton>;
};

export default PrimaryButton;
