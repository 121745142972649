import { Modal } from 'antd';
import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const StyledAntdModal = styled(Modal)`
  padding: 0px;

  & .ant-modal-content,
  & .ant-modal-header,
  & .ant-modal-footer {
    background-color: ${(p) => p.theme.white};
    border-color: ${(p) => p.theme.white};
    border-radius: 18px;
  }

  & .ant-modal-close-icon,
  & .ant-modal-title {
    color: ${(p) => p.theme.black};
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  font-family: "NotoMedium";
  font-size: 1.17em;
  border-radius: 50px;
  width: 500px;
  margin-left: 0px !important;
`;

export const StyledCancelPrimaryButton = styled(PrimaryButton)`
  font-family: "NotoMedium";
  font-size: 1.17em;
  border-radius: 50px;
  background-color: ${(p) => p.theme.white};
  border-color: ${(p) => p.theme.black};
  width: 500px;
  margin-left: 0px !important;
  :hover {
    background-color: ${(p) => p.theme.black};
    border-color: ${(p) => p.theme.black};
    color: ${(p) => p.theme.white};
  }
  :focus {
    background-color: ${(p) => p.theme.black};
    border-color: ${(p) => p.theme.black};
    color: ${(p) => p.theme.white};
  }
`;

export const StyledFilterPrimaryButton = styled(PrimaryButton)`
  padding: 0.7em 0.9em;

  i {
    width: 21px;
    height: 21px;
  }
`

