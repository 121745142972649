import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FlexContainer, ItemContainer, StyledFooter, StyledFooterWrapper, StyledPrimaryButton } from "./styles";
import Indicator from './components/Indicator';

import pictoPiecesTransfert from 'assets/images/Picto-pieces-transfert.svg';
import pictoBoutique from 'assets/images/picto-boutique.svg';
import SaveModal from 'components/Modals/SaveModal/SaveModal';
import { SwitchContext } from 'context';
import { useCreateSwitchConfigMutation } from 'services/graphql/main';
import { useError } from 'services/utils';
import ListProductModal from './components/ListProductModal';
import ListStoreShipperModal from './components/ListStoreShipperModal';
import ListStoreRecipientModal from './components/ListStoreRecipientModal';
import AllRoutes from 'screens/AllRoutes';
import {Tooltip} from "antd";

const Footer: React.FC = () => {

  const { t } = useTranslation('translation');
  const { addError } = useError();
  const navigate = useNavigate();

  const { countProduct, countShipping, countRecipient, currentSwitch, setCurrentSwitch, isSaveable, saveableMsg } = useContext(SwitchContext);

  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const [productModalOpen, setProductModalOpen] = useState<boolean>(false);
  const [storeShippingModalOpen, setStoreShippingModalOpen] = useState<boolean>(false);
  const [storeRecipientModalOpen, setStoreRecipientModalOpen] = useState<boolean>(false);

  const [createSwitchConfig] = useCreateSwitchConfigMutation({
    onError: (err) => addError(err, 'error'),
  });

  const onSave = async () => {
    let saveCurrentSwitch = currentSwitch;
    saveCurrentSwitch.saved = true;
    const result = await createSwitchConfig({variables: {switchConfigInput : saveCurrentSwitch}});
    if (!result.errors) {
      setCurrentSwitch({...currentSwitch, saved: true, id: result?.data?.createSwitchConfig.id});
      setSaveModalOpen(false);
      let switchId = result.data?.createSwitchConfig.id;
      let switchName = result.data?.createSwitchConfig.name;
      navigate(AllRoutes.SwitchSimulate, {state: {switchId: switchId, switchName: switchName, simulate: true}});
    }
  }

  const onSimulate = async () => {
    let simulateCurrentSwitch = currentSwitch;
    simulateCurrentSwitch.saved = false;
    const result = await createSwitchConfig({variables: {switchConfigInput : simulateCurrentSwitch}});
    if (!result.errors) {
      setCurrentSwitch({...currentSwitch, saved: false, id: result?.data?.createSwitchConfig.id});
      setSaveModalOpen(false);
      let switchId = result.data?.createSwitchConfig.id;
      let switchName = result.data?.createSwitchConfig.name;
      navigate(AllRoutes.SwitchSimulate, {state: {switchId: switchId, switchName: switchName, simulate: true}});
    }
  }

  const onToggleButton = (value: boolean) => {
    setCurrentSwitch({...currentSwitch, pinned: value});
  };

  return (
    <StyledFooter>
      <StyledFooterWrapper>

        <FlexContainer style={{flexDirection: "row", paddingTop: 8, paddingLeft: 20}}>
          <ItemContainer>
            <FlexContainer style={{flexDirection: "row"}}>

              <ItemContainer onClick={() => setProductModalOpen(true)}>
                <Indicator number={countProduct} textKey='footer.products' iconPath={pictoPiecesTransfert} theme='blue' />
              </ItemContainer>

              <ItemContainer onClick={() => setStoreShippingModalOpen(true)}>
                <Indicator number={countShipping} textKey='footer.shippers' iconPath={pictoBoutique} theme='purple'/>
              </ItemContainer>

              <ItemContainer onClick={() => setStoreRecipientModalOpen(true)}>
                <Indicator number={countRecipient} textKey='footer.recipients' iconPath={pictoBoutique} theme='purple'/>
              </ItemContainer>

            </FlexContainer>
          </ItemContainer>

          <ItemContainer>
            {!isSaveable && <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} placement="top" title={saveableMsg} trigger={'hover'}>
              <span>
                <StyledPrimaryButton disabled={!isSaveable} textKey="footer.simulate_this_switch" onClick={() => setSaveModalOpen(true)}/>
              </span>
            </Tooltip>}
            {isSaveable && <StyledPrimaryButton disabled={!isSaveable} textKey="footer.simulate_this_switch" onClick={() => setSaveModalOpen(true)}/>}
          </ItemContainer>
        </FlexContainer>

      </StyledFooterWrapper>

      <ListProductModal open={productModalOpen} onCancel={() => setProductModalOpen(false)}/>
      <ListStoreShipperModal open={storeShippingModalOpen} onCancel={() => setStoreShippingModalOpen(false)}/>
      <ListStoreRecipientModal open={storeRecipientModalOpen} onCancel={() => setStoreRecipientModalOpen(false)}/>

      <SaveModal
        customTitle1={t('save_modal.title1')}
        customTitle2={t('save_modal.title2')}
        text1={t('save_modal.content1')}
        text2={t('save_modal.content2')}
        onSave={onSave}
        onSimulate={onSimulate}
        open={saveModalOpen}
        onCancel={() => setSaveModalOpen(false)}
        toggleButton={currentSwitch.pinned as boolean}
        onToggleButton={onToggleButton}
        labelToggle={t('save_modal.toggleButton')}
      />

    </StyledFooter>
  );
};

export default Footer;
