import React, { useEffect, useState } from 'react';

import { StyledModal } from 'components/Modals';
import { FlexContainer, TilteSecond, TilteSeparator, Title, TitleContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { Indicators } from 'components/UI';
import Export from './Export';
import { SwitchResult } from 'services/graphql/main';

interface PropsExportModal {
  customElement?: React.ReactNode;
  customTitle?: string;
  customTitleKey?: string;
  onCancel?: () => void;
  open: boolean;
  text?: string;
  textKey?: string;
  width?: number;
  height?: number;
  showOnlyCloseBtn?: boolean;
  switchResult: SwitchResult;
}

const ExportModal: React.FC<PropsExportModal> = ({
    customElement,
    customTitle,
    customTitleKey,
    open,
    onCancel,
    width,
    height,
    showOnlyCloseBtn,
    switchResult
  }) => {

    const { t } = useTranslation('translation');

    return (
        <StyledModal
            width={!!width ? width : "900px"}
            centered
            closable={!showOnlyCloseBtn}
            open={open}
            zIndex={2000}
            onCancel={onCancel}
            okButtonProps={{style: {display: 'none'}}}
            cancelButtonProps={{style: {display: 'none'}}}
        >
          <FlexContainer>
            <TitleContainer>
              <Title>{switchResult ? switchResult.config?.name : ""}</Title>

              {switchResult && switchResult.user && <>
              <TilteSeparator>{t("common.by")}</TilteSeparator>
              <TilteSecond>{switchResult.user.username}</TilteSecond>
              </>}
            </TitleContainer>

            <Indicators switchResult={switchResult} transfer={true} textKey8='simulate_switch.carbon_footprint'></Indicators>

            <Export switchResult={switchResult}></Export>

            {customElement && customElement}
          </FlexContainer>
        </StyledModal>
    )
  }

  export default ExportModal;