import { Tabs } from 'antd';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  color: ${(p) => p.theme.white};
  .ant-tabs-content,
  .ant-tabs-content-holder,
  .ant-tabs-tabpane {
    height: '100%'};
  }
  .ant-tabs-tab:hover {
    color: ${(p) => p.theme.white};
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(p) => p.theme.white};
    font-weight: 500;
  }

  .ant-tabs-ink-bar {
    border: 1px solid ${(p) => p.theme.green};
  }

  .ant-tabs-nav-wrap {
    background-color: ${(p) => p.theme.dark_blue_background};
  }
`;
