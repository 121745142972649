import React, {useEffect, useState} from 'react';
import {
  GetSwitchSettingQuery,
  SwitchSetting,
  SwitchSettingFormInput, TenantSettings, useGetSwitchSettingQuery, useGetTenantSettingsQuery
} from "../services/graphql/main";
import {useError} from "../services/utils";

type SwitchSettingContextType = {
  switchSetting: SwitchSettingFormInput;
  setSwitchSetting: (switchSetting: SwitchSettingFormInput) => void;
  switchSettingFormToForm: (data: GetSwitchSettingQuery) => void;
  tenantSettings: TenantSettings | undefined;
  setTenantSettings: (tenantSettings: TenantSettings) => void;
};

export const SwitchSettingContext = React.createContext<SwitchSettingContextType>({} as SwitchSettingContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const SwitchSettingContextProvider: React.FC<ContextProviderType> = ({ children }) => {

  const { addError } = useError();

  const [switchSetting, setSwitchSetting] = useState<SwitchSettingFormInput>({});

  const [tenantSettings, setTenantSettings] = useState<TenantSettings | undefined>();

  const switchSettingFormToForm = (data: GetSwitchSettingQuery) => {
    setSwitchSetting({...switchSetting,
      productDepartementEnabled: data.switchSetting.productDepartementEnabled,
      productFamilyEnabled: data.switchSetting.productFamilyEnabled,
      productSubFamilyEnabled: data.switchSetting.productSubFamilyEnabled,
      productCustomFieldValues1Enabled: data.switchSetting.productCustomFieldValues1Enabled,
      productCustomFieldValues2Enabled: data.switchSetting.productCustomFieldValues2Enabled,
      productCustomFieldValues3Enabled: data.switchSetting.productCustomFieldValues3Enabled,
      productCustomFieldValues4Enabled: data.switchSetting.productCustomFieldValues4Enabled,
      productCustomFieldValues5Enabled: data.switchSetting.productCustomFieldValues5Enabled,
      storeRegionEnabled: data.switchSetting.storeRegionEnabled,
      storeTypeEnabled: data.switchSetting.storeTypeEnabled,
      storeCustomFieldValues1Enabled: data.switchSetting.storeCustomFieldValues1Enabled,
      storeCustomFieldValues2Enabled: data.switchSetting.storeCustomFieldValues2Enabled,
      storeCustomFieldValues3Enabled: data.switchSetting.storeCustomFieldValues3Enabled,
      storeCustomFieldValues4Enabled: data.switchSetting.storeCustomFieldValues4Enabled,
      storeCustomFieldValues5Enabled: data.switchSetting.storeCustomFieldValues5Enabled,
      transfertDefaultRC: data.switchSetting.transfertDefaultRC,
      transfertDefaultRCT: data.switchSetting.transfertDefaultRCT,
      centralSizeThreshold: data.switchSetting.centralSizeThreshold,
      transportRemovalCost: data.switchSetting.transportRemovalCost,
      transportCostPerItem: data.switchSetting.transportCostPerItem,
      co2PerKm: data.switchSetting.co2PerKm,
    });
  }

  const {
    loading: loadingSwitchSettingQuery,
    data: switchSettingDataQuery,
    refetch: refetchSwitchSettingQuery,
  } = useGetSwitchSettingQuery({ onError: (err) => addError(err, 'error') });

  const {
    loading: loadingTenantSettingsQuery,
    data: tenantSettingsDataQuery,
    refetch: refetchTenantSettingsQuery,
  } = useGetTenantSettingsQuery({ onError: (err) => addError(err, 'error') });

  useEffect(()=>{
    if (switchSettingDataQuery) {
      switchSettingFormToForm(switchSettingDataQuery);
    }
  }, [switchSettingDataQuery]);

  useEffect(()=>{
    if (tenantSettingsDataQuery) {
      if (tenantSettingsDataQuery) {
        setTenantSettings(tenantSettingsDataQuery.tenantSettings as TenantSettings);
      }
    }
  }, [tenantSettingsDataQuery]);

  return (
    <SwitchSettingContext.Provider
      value={{
        switchSetting,
        setSwitchSetting,
        switchSettingFormToForm,
        tenantSettings,
        setTenantSettings
      }}
    >
      {children}
    </SwitchSettingContext.Provider>

  );
};
