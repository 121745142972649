import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {Radio, RadioChangeEvent, Tag, Tooltip} from "antd";

import { useTranslation } from "react-i18next";
import {
    FlexContainer,
    ItemContainer,
    GridContainer,
    StyledInputDate,
    StyledInputNumber,
    StyledInputPlaceholderInner,
    StyledLabel,
    StyledPrimaryButton,
    StyledRadio,
    StyledRadioGroup,
    StyledSelect,
    StyledVisibleLabel } from "./styles";
import { ReactComponent as RequireSvg } from 'assets/images/Picto-champ-oligatoire.svg';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import defaultTheme from "theme";
import moment from "moment";
import { ExcludeModal } from "components/Modals";
import {SwitchContext, SwitchSettingContext} from "context";
import { ProductRow } from "types/product"
import {
  ComparisonSign,
  Department,
  Family,
  GetProductsQuery,
  SalePeriod,
  Season,
  SubFamily,
  Universe, useGetDepartmentsQuery,
  useGetFamiliesQuery,
  useGetProductsQuery,
  useGetSeasonsQuery,
  useGetSubFamiliesQuery,
  useGetUniversesQuery,
  WarehouseStock
} from "services/graphql/main";
import { useError } from "services/utils";
import { StoreRow } from "types/store";
import pictoReglageActif from "assets/images/picto-reglage.svg";
import { percentFormatter } from "components/AgGridComponents/valueFormatters";
import { BaseOptionType } from "antd/lib/select";
import { ColDef, GridApi } from "ag-grid-community";
import {CustomTagProps} from "rc-select/lib/BaseSelect";
import theme from "theme";

type Props = {
  children?: React.ReactNode;
  onProduct: () => void;
}

const defaultValue: string = '';

const Product: React.FC<Props> = ({children, onProduct}) => {

    const { t } = useTranslation('translation');
    const { addError } = useError();

    const comparisonSign = Object.keys(ComparisonSign);

    const { setCountProduct, currentProductData, setCurrentProductData, currentSwitch, setCurrentSwitch, currentStoreShipperData, currentStoreRecipientData } = useContext(SwitchContext);

    const { switchSetting, tenantSettings } = useContext(SwitchSettingContext);

    const [selectedSeason, setSelectedSeason] = useState<number[] | []>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<number[] | null>(currentSwitch.productDepartmentIds as number[]);
    const [selectedFamily, setSelectedFamily] = useState<number[] | null>(currentSwitch.productFamilyIds as number[]);
    const [selectedSubfamily, setSelectedSubfamily] = useState<number[] | null>(currentSwitch.productSubFamilyIds as number[]);

    const formatCustomFieldOptions = (customFieldValues: string) => {
      const nullOption = {value: 'NULL', label: 'Non renseigné'};
      const formatedOptions = JSON.parse(customFieldValues).map((v: any) => ({value: v.Code, label: v.Label}));
      return [nullOption, ...formatedOptions];
    }

    const optsCustomField1 = tenantSettings && tenantSettings.productCustomFieldValues1 ? formatCustomFieldOptions(tenantSettings.productCustomFieldValues1) : [];
    const optsCustomField2 = tenantSettings && tenantSettings.productCustomFieldValues2 ? formatCustomFieldOptions(tenantSettings.productCustomFieldValues2) : [];
    const optsCustomField3 = tenantSettings && tenantSettings.productCustomFieldValues3 ? formatCustomFieldOptions(tenantSettings.productCustomFieldValues3) : [];
    const optsCustomField4 = tenantSettings && tenantSettings.productCustomFieldValues4 ? formatCustomFieldOptions(tenantSettings.productCustomFieldValues4) : [];
    const optsCustomField5 = tenantSettings && tenantSettings.productCustomFieldValues5 ? formatCustomFieldOptions(tenantSettings.productCustomFieldValues5) : [];

    const [isFromDate, setIsFromDate] = useState<boolean>(false);

    const [excludeProductModalOpen, setExcludeProductModalOpen] = useState<boolean>(false);
    const [countSelectedExcludeProduct, setCountSelectedExcludeProduct] = useState<number | 0>(0);

    const {
      loading: loadingSeasonsDataQuery,
      data: seasonsDataQuery,
      refetch: refetchSeasonsQuery,
    } = useGetSeasonsQuery({ onError: (err) => addError(err, 'error') });

    const [seasons, setSeasons] = useState<(Season | null)[] | null | undefined>();

    const {
      loading: loadingDepartmentsDataQuery,
      data: departmentsDataQuery,
      refetch: refetchDepartmentsQuery,
    } = useGetDepartmentsQuery({ onError: (err) => addError(err, 'error') });

    const [departments, setDepartments] = useState<(Department | null)[] | null | undefined>();

    const {
      loading: loadingFamiliesDataQuery,
      data: familiesDataQuery,
      refetch: refetchFamiliesQuery,
    } = useGetFamiliesQuery({ onError: (err) => addError(err, 'error') });

    const [families, setFamilies] = useState<(Family | null)[] | null | undefined>();

    const {
      loading: loadingSubFamiliesDataQuery,
      data: subFamiliesDataQuery,
      refetch: refetchSubFamiliesQuery,
    } = useGetSubFamiliesQuery({ onError: (err) => addError(err, 'error') });

    const [subFamilies, setSubFamilies] = useState<(SubFamily | null)[] | null | undefined>();

    const {
      loading: loadingProductsDataQuery,
      data: productsDataQuery,
      refetch: refetchProductsQuery,
    } = useGetProductsQuery({ 
      variables: {
          seasonsIds: currentSwitch.productSeasonsIds as number[],
          storeIds: currentStoreShipperData.map((store) => store.id),// currentStoreRecipientData,
          storeTypeIds: currentSwitch.storeTypesIds as number[],
          storesExpExcludesIds: currentSwitch.storeSenderExcludesIds as number[],
          storesDestExcludesIds: currentSwitch.storeRecipientExcludesIds as number[],
          universeId: currentSwitch.universeId as number,
          familyIds: currentSwitch.productFamilyIds as number[],
          subFamilyIds: currentSwitch.productSubFamilyIds as number[],
          departmentIds: currentSwitch.productDepartmentIds as number[],
          productCustomField1Codes: currentSwitch.productCustomField1ValueCodes as string[],
          productCustomField2Codes: currentSwitch.productCustomField2ValueCodes as string[],
          productCustomField3Codes: currentSwitch.productCustomField3ValueCodes as string[],
          productCustomField4Codes: currentSwitch.productCustomField4ValueCodes as string[],
          productCustomField5Codes: currentSwitch.productCustomField5ValueCodes as string[],
          minPrice: currentSwitch.productMinimumPrice as number,
          size: currentSwitch.productSize as WarehouseStock,
          sizeComparisionSign: currentSwitch.productSizeSign as ComparisonSign,
          nbPieces: currentSwitch.productNbItem as number,
          couvSize: currentSwitch.productCouv as WarehouseStock,
          couvSizeComparisionSign: currentSwitch.productCouvSign as ComparisonSign,
          couvNbWeeks: currentSwitch.productNbWeek as number,
          period: currentSwitch.productSalePeriod as SalePeriod,
          fromPeriod: currentSwitch.productFromPeriod as string,
          qtyPeriodSign: currentSwitch.productQtyPeriodSign as ComparisonSign,
          qtyPeriodNbPiece: currentSwitch.productQtyPeriodNbItem as number,
          inStoreSign: currentSwitch.productInStoreSign as ComparisonSign,
          inStoreNbWeeks: currentSwitch.productInStoreNbWeeks as number,
          restockNbWeeks: currentSwitch.productRestockNbWeeks as number,
          markDownPeriodSign: currentSwitch.productMarkDownPeriodSign as ComparisonSign,
          markDownPercent: currentSwitch.productMarkDownPercent as number
        },
      onError: (err) => {
        addError(err, 'error');
        setCountProduct();
        setCurrentProductData([]);
      } });

    const getRowsProductsDataQuery = useCallback( (rowsRawData: GetProductsQuery | undefined, excludes: number[]) => {
      const rows  = rowsRawData?.products?.map((product) => {
        return {
          exclude: excludes?.includes(product.productId),
          ...product
        }
      });
      return rows;
    }, []);

    useEffect(() => {
      if (loadingProductsDataQuery) {
        setCountProduct(-1);
      } else {
        setCountProduct(0);
      }
    }, [loadingProductsDataQuery]);

    useEffect(()=>{
        // const nullOption = { id: -1, name: 'Non renseigné' };
        if (seasonsDataQuery) {
            setSeasons(seasonsDataQuery.seasons);
        }
        if (departmentsDataQuery) {
          setDepartments(departmentsDataQuery.departments);
        }
        if (familiesDataQuery) {
            setFamilies(familiesDataQuery.families);
        }
        if (subFamiliesDataQuery) {
            setSubFamilies(subFamiliesDataQuery.subFamilies);
        }
        if (productsDataQuery && (currentSwitch.productSeasonsIds && currentSwitch.productSeasonsIds.length > 0)) {
            const rows = getRowsProductsDataQuery(productsDataQuery, currentSwitch.productExcludesIds as number[]);
            if (rows) {
              setCurrentProductData(rows as ProductRow[]);
              setCountProduct(rows.length - (currentSwitch.productExcludesIds ? currentSwitch.productExcludesIds?.length as number : 0));
              setCountSelectedExcludeProduct((currentSwitch.productExcludesIds ? currentSwitch.productExcludesIds?.length as number : 0));
            }
        }
        if (currentSwitch.productSalePeriod == SalePeriod.FromDate) setIsFromDate(true);
        checkOnProduct();
    }, [seasonsDataQuery, departmentsDataQuery, familiesDataQuery, productsDataQuery, subFamiliesDataQuery])

    const checkOnProduct = () => {
      if (currentSwitch.productSeasonsIds && currentSwitch.productSeasonsIds.length > 0 &&
        !!(currentSwitch.productQtyPeriodSign && currentSwitch.productQtyPeriodNbItem) && 
        !!( currentSwitch.productSalePeriod == SalePeriod.Accumulation || (currentSwitch.productSalePeriod == SalePeriod.FromDate  && currentSwitch.productFromPeriod)))
              onProduct();
    }

    const onChangeSelectSeason = (value: unknown) => {
      setCurrentSwitch({...currentSwitch, productSeasonsIds: value as number[], productExcludesIds: []});
      setCurrentProductData([]);
      setCountSelectedExcludeProduct(0);
      // setCountProduct(0);
      checkOnProduct();
    }

    const onChangeSalesPeriod = (e: RadioChangeEvent) => {
        const value = e.target.value as SalePeriod;
        setCurrentSwitch({...currentSwitch, productSalePeriod: value, productExcludesIds: []});
        (value === SalePeriod.FromDate)  ? setIsFromDate(true) : setIsFromDate(false);
        checkOnProduct();
    }

    const onChangeFromDate = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentSwitch({...currentSwitch, productFromPeriod: moment(new Date(e.target.value)).format('YYYY-MM-DD'), productExcludesIds: []});
        checkOnProduct();
    }

    const onSelectExcludeProduct = (value: ProductRow[]) => {
        setCountSelectedExcludeProduct(value.length);
        const calculatedCount = (currentProductData) ? (currentProductData.length - value.length) : 0;
        setCountProduct(calculatedCount);
        let ids = value.map( v => v.productId);
        setCurrentSwitch({...currentSwitch, productExcludesIds: ids});
        const rowsProduct = getRowsProductsDataQuery(productsDataQuery, ids);
        setCurrentProductData(rowsProduct as ProductRow[]);
    }

    const onClickParamsExcludeProducts = () => {
        setExcludeProductModalOpen(true);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input: string, option?: BaseOptionType) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const customTagRender = (props: CustomTagProps, selected?: any[] | null, options?: any[] | null, valueKey: string = 'value', labelKey: string = 'label') => {
      const { value, label } = props;
      if (selected && selected.length > 1 && selected.indexOf(value) === selected.length - 1) {
        let selectedLabels = '';
        if (options && valueKey && labelKey) {
          selectedLabels = selected.map((item) => options?.find((option) => option && option[valueKey] === item)[labelKey]).join(', ');
        }
        return (
          <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} placement="top" title={selectedLabels} trigger={'hover'}>
            {t('create_switch.many_elements')}
          </Tooltip>
        );
      }
      if (selected && selected.length > 1) {
        return <></>;
      }
      return (
        <>
          {label}
        </>
      );
    };

    const colDefs: ColDef[] = useMemo( () => [
      {
        field: 'exclude',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMenu: true,
        resizable: true,
        headerName: "",
        sortable: true,
        maxWidth: 120,
        valueFormatter: (params) => {
          return "";
          // if (params.data.exclude) {
          //   return t('grid_exclude_store.exception');
          // } else {
          //   return "";
          // }
        },
      },
      {field: 'productCode', headerName: t('grid_exclude_product.product_code'), sortable: true, width: 200, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
      {field: 'productName', headerName: t('grid_exclude_product.label_reference'), sortable: true, width: 200, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
      {field: 'colorName', headerName: t('grid_exclude_product.color_label'), sortable: true, width: 150, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
      {field: 'familyName', headerName: t('grid_exclude_product.family'), sortable: true, width: 200, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
      {field: 'theme', headerName: t('grid_exclude_product.theme'), sortable: true, width: 130, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agTextColumnFilter', resizable: true},
      {field: 'productPrice', headerName: t('grid_exclude_product.tariff'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'salesPeriod', headerName: t('grid_exclude_product.sales_period'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'stockStore', headerName: t('grid_exclude_product.store_stock'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'storeFlowRate', headerName: t('grid_exclude_product.store_flow_rate'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, valueFormatter: percentFormatter, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'warehouseStock', headerName: t('grid_exclude_product.warehouse_stock'), sortable: true, width: 120, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'totalFlowRate', headerName: t('grid_exclude_product.total_flow_rate'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, valueFormatter: percentFormatter, filter: 'agNumberColumnFilter', resizable: true},
      {field: 'stkStoreVsStkTotal', headerName: t('grid_exclude_product.%_store_vs_total_stock'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, valueFormatter: percentFormatter, filter: 'agNumberColumnFilter', resizable: true},
    ], [t]);

    return (
      <FlexContainer style={{flexDirection: "row", flexWrap: "wrap", marginTop: 15}}>

        <ItemContainer style={{marginBottom: 30}}>
          <FlexContainer style={{flexDirection: "column", width: "850px", paddingLeft: "10px"}}>

                <ItemContainer style={{marginBottom: 20}}>
                    <FlexContainer style={{flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end"}}>
                        <ItemContainer>
                            <StyledLabel style={{width: 250}}>{t("create_switch.management_season")}</StyledLabel>
                        </ItemContainer>
                        <ItemContainer> 
                            <FlexContainer style={{flexDirection: "row", gap: 0, alignItems: "flex-end"}}>
                                <ItemContainer style={{height: 30}}>
                                    <RequireSvg style={{width: 20}}/>
                                </ItemContainer>
                                <ItemContainer>
                                    <FlexContainer style={{flexDirection: "column"}}>
                                        <StyledVisibleLabel visibility={!(selectedSeason && selectedSeason.length > 0)}>{t("create_switch.season")}</StyledVisibleLabel>
                                        <StyledSelect
                                          getPopupContainer={(trigger)=> trigger.parentNode}
                                          style={{width: 510}}
                                          suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                                          bordered={false}
                                          value={currentSwitch.productSeasonsIds}
                                          onChange={onChangeSelectSeason}
                                          options={seasons?.map((season) => ({value: season?.id, label: season?.name}))}
                                          placeholder={t("create_switch.season")}
                                          mode="multiple"
                                          showArrow={true}
                                          filterOption={filterOption}
                                        />
                                    </FlexContainer>
                                </ItemContainer>
                            </FlexContainer>
                        </ItemContainer>
                    </FlexContainer>
                </ItemContainer>

                <ItemContainer>
                    <StyledLabel>{t("create_switch.hierarchical_levels")}</StyledLabel>
                </ItemContainer>

                <ItemContainer style={{marginLeft: 20}}>
                    <FlexContainer style={{flexDirection: "row"}}>
                        <ItemContainer>
                            <FlexContainer style={{flexDirection: "column"}}>
                                <StyledVisibleLabel visibility={!(selectedDepartment && selectedDepartment.length > 0)}>{t("create_switch.department")}</StyledVisibleLabel>
                                <StyledSelect
                                  getPopupContainer={(trigger)=> trigger.parentNode}
                                  disabled={!(switchSetting.productDepartementEnabled && switchSetting.productDepartementEnabled)}
                                  value={currentSwitch.productDepartmentIds}
                                  onChange={(value) => { setCurrentSwitch({...currentSwitch, productDepartmentIds: value as number[], productExcludesIds: []}); setSelectedDepartment(value as number[]); }}
                                  style={{width: 230}}
                                  suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                                  bordered={false}
                                  options={departments?.map((department) => ({value: department?.id, label: department?.name}))}
                                  placeholder={t("create_switch.department")}
                                  mode="multiple"
                                  filterOption={filterOption}
                                  allowClear
                                  tagRender={(props) => customTagRender(props, selectedDepartment, departments, 'id', 'name')}
                                />
                            </FlexContainer>
                        </ItemContainer>
                        <ItemContainer>
                            <FlexContainer style={{flexDirection: "column"}}>
                                <StyledVisibleLabel visibility={!(selectedFamily && selectedFamily.length > 0)}>{t("create_switch.family")}</StyledVisibleLabel>
                                <StyledSelect
                                  getPopupContainer={(trigger)=> trigger.parentNode}
                                  disabled={!(switchSetting.productFamilyEnabled && switchSetting.productFamilyEnabled)}
                                  value={currentSwitch.productFamilyIds}
                                  onChange={(value) => { setCurrentSwitch({...currentSwitch, productFamilyIds: value as number[], productExcludesIds: []}); setSelectedFamily(value as number[]); }}
                                  style={{width: 250}}
                                  suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                                  bordered={false}
                                  options={families?.map((family) => ({value: family?.id, label: family?.name}))}
                                  placeholder={t("create_switch.family")}
                                  mode="multiple"
                                  filterOption={filterOption}
                                  allowClear
                                  tagRender={(props) => customTagRender(props, selectedFamily, families, 'id', 'name')}
                                />
                            </FlexContainer>
                        </ItemContainer>
                        <ItemContainer>
                            <FlexContainer style={{flexDirection: "column"}}>
                                <StyledVisibleLabel visibility={!(selectedSubfamily && selectedSubfamily.length > 0)}>{t("create_switch.subfamily")}</StyledVisibleLabel>
                                <StyledSelect
                                  getPopupContainer={(trigger)=> trigger.parentNode}
                                  disabled={!(switchSetting.productSubFamilyEnabled && switchSetting.productSubFamilyEnabled)}
                                  value={currentSwitch.productSubFamilyIds}
                                  onChange={(value) => { setCurrentSwitch({...currentSwitch, productSubFamilyIds: value as number[], productExcludesIds: []}); setSelectedSubfamily(value as number[]); }}
                                  style={{width: 250}}
                                  suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                                  bordered={false}
                                  options={subFamilies?.map((subFamily) => ({value: subFamily?.id, label: subFamily?.name}))}
                                  placeholder={t("create_switch.subfamily")}
                                  mode="multiple"
                                  filterOption={filterOption}
                                  allowClear
                                  tagRender={(props) => customTagRender(props, selectedSubfamily, subFamilies, 'id', 'name')}
                                />
                            </FlexContainer>
                        </ItemContainer>
                    </FlexContainer>
                </ItemContainer>

                {(switchSetting.productCustomFieldValues1Enabled || switchSetting.productCustomFieldValues2Enabled || switchSetting.productCustomFieldValues3Enabled || switchSetting.productCustomFieldValues4Enabled || switchSetting.productCustomFieldValues5Enabled) && <>
                  <ItemContainer style={{marginBottom: 20, marginLeft: 20}}>
                  <GridContainer>
                    {tenantSettings && tenantSettings.productCustomFieldName1 && tenantSettings.productCustomFieldValues1 && switchSetting.productCustomFieldValues1Enabled && switchSetting.productCustomFieldValues1Enabled && <>
                    <ItemContainer>
                      <FlexContainer style={{flexDirection: "column"}}>
                        <StyledVisibleLabel visibility={!(currentSwitch.productCustomField1ValueCodes && currentSwitch.productCustomField1ValueCodes.length > 0)}>{tenantSettings.productCustomFieldName1}</StyledVisibleLabel>
                        <StyledSelect
                          getPopupContainer={(trigger)=> trigger.parentNode}
                          value={currentSwitch.productCustomField1ValueCodes}
                          onChange={(value) => { setCurrentSwitch({...currentSwitch, productCustomField1ValueCodes: value as string[], productExcludesIds: []}); }}
                          style={{width: 230}}
                          suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                          bordered={false}
                          options={optsCustomField1}
                          placeholder={tenantSettings.productCustomFieldName1}
                          mode="multiple"
                          filterOption={filterOption}
                          allowClear
                          tagRender={(props) => customTagRender(props, currentSwitch.productCustomField1ValueCodes, optsCustomField1)}
                        />
                      </FlexContainer>
                    </ItemContainer>
                    </> }
                    {tenantSettings && tenantSettings.productCustomFieldName2 && tenantSettings.productCustomFieldValues2 && switchSetting.productCustomFieldValues2Enabled && switchSetting.productCustomFieldValues2Enabled && <>
                    <ItemContainer>
                      <FlexContainer style={{flexDirection: "column"}}>
                        <StyledVisibleLabel visibility={!(currentSwitch.productCustomField2ValueCodes && currentSwitch.productCustomField2ValueCodes.length > 0)}>{tenantSettings.productCustomFieldName2}</StyledVisibleLabel>
                        <StyledSelect
                          getPopupContainer={(trigger)=> trigger.parentNode}
                          value={currentSwitch.productCustomField2ValueCodes}
                          onChange={(value) => { setCurrentSwitch({...currentSwitch, productCustomField2ValueCodes: value as string[], productExcludesIds: []}); }}
                          style={{width: 250}}
                          suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                          bordered={false}
                          options={optsCustomField2}
                          placeholder={tenantSettings.productCustomFieldName2}
                          mode="multiple"
                          filterOption={filterOption}
                          allowClear
                          tagRender={(props) => customTagRender(props, currentSwitch.productCustomField2ValueCodes, optsCustomField2)}
                        />
                      </FlexContainer>
                    </ItemContainer>
                    </> }
                    {tenantSettings && tenantSettings.productCustomFieldName3 && tenantSettings.productCustomFieldValues3 && switchSetting.productCustomFieldValues3Enabled && switchSetting.productCustomFieldValues3Enabled && <>
                    <ItemContainer>
                      <FlexContainer style={{flexDirection: "column"}}>
                        <StyledVisibleLabel visibility={!(currentSwitch.productCustomField3ValueCodes && currentSwitch.productCustomField3ValueCodes.length > 0)}>{tenantSettings.productCustomFieldName3}</StyledVisibleLabel>
                        <StyledSelect
                          getPopupContainer={(trigger)=> trigger.parentNode}
                          value={currentSwitch.productCustomField3ValueCodes}
                          onChange={(value) => { setCurrentSwitch({...currentSwitch, productCustomField3ValueCodes: value as string[], productExcludesIds: []}); }}
                          style={{width: 250}}
                          suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                          bordered={false}
                          options={optsCustomField3}
                          placeholder={tenantSettings.productCustomFieldName3}
                          mode="multiple"
                          filterOption={filterOption}
                          allowClear
                          tagRender={(props) => customTagRender(props, currentSwitch.productCustomField3ValueCodes, optsCustomField3)}
                        />
                      </FlexContainer>
                    </ItemContainer>
                  </> }
                  {tenantSettings && tenantSettings.productCustomFieldName4 && tenantSettings.productCustomFieldValues4 && switchSetting.productCustomFieldValues4Enabled && switchSetting.productCustomFieldValues4Enabled && <>
                    <ItemContainer>
                        <FlexContainer style={{flexDirection: "column"}}>
                          <StyledVisibleLabel visibility={!(currentSwitch.productCustomField4ValueCodes && currentSwitch.productCustomField4ValueCodes.length > 0)}>{tenantSettings.productCustomFieldName4}</StyledVisibleLabel>
                          <StyledSelect
                            getPopupContainer={(trigger)=> trigger.parentNode}
                            value={currentSwitch.productCustomField4ValueCodes}
                            onChange={(value) => { setCurrentSwitch({...currentSwitch, productCustomField4ValueCodes: value as string[], productExcludesIds: []}); }}
                            style={{width: 230}}
                            suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                            bordered={false}
                            options={optsCustomField4}
                            placeholder={tenantSettings.productCustomFieldName4}
                            mode="multiple"
                            filterOption={filterOption}
                            allowClear
                            tagRender={(props) => customTagRender(props, currentSwitch.productCustomField4ValueCodes, optsCustomField4)}
                          />
                        </FlexContainer>
                    </ItemContainer>
                  </> }
                  {tenantSettings && tenantSettings.productCustomFieldName5 && tenantSettings.productCustomFieldValues5 && switchSetting.productCustomFieldValues5Enabled && switchSetting.productCustomFieldValues5Enabled && <>
                    <ItemContainer>
                        <FlexContainer style={{flexDirection: "column"}}>
                          <StyledVisibleLabel visibility={!(currentSwitch.productCustomField5ValueCodes && currentSwitch.productCustomField5ValueCodes.length > 0)}>{tenantSettings.productCustomFieldName5}</StyledVisibleLabel>
                          <StyledSelect
                            getPopupContainer={(trigger)=> trigger.parentNode}
                            value={currentSwitch.productCustomField5ValueCodes}
                            onChange={(value) => { setCurrentSwitch({...currentSwitch, productCustomField5ValueCodes: value as string[], productExcludesIds: []}); }}
                            style={{width: 250}}
                            suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                            bordered={false}
                            options={optsCustomField5}
                            placeholder={tenantSettings.productCustomFieldName5}
                            mode="multiple"
                            filterOption={filterOption}
                            allowClear
                            tagRender={(props) => customTagRender(props, currentSwitch.productCustomField5ValueCodes, optsCustomField5)}
                          />
                        </FlexContainer>
                    </ItemContainer>
                  </> }
                  </GridContainer>
                </ItemContainer>
                </> }

                <ItemContainer>
                    <FlexContainer style={{flexDirection: "column"}}>
                        <ItemContainer>
                            <StyledLabel>{t("create_switch.warehouse_stock")}</StyledLabel>
                        </ItemContainer>

                        <ItemContainer style={{borderBottom: "lightgray solid 1px", paddingBottom: 30, marginLeft: 10}}>
                          <FlexContainer style={{flexDirection: "row", alignItems: "center"}}>
                            <ItemContainer>
                              <Radio.Group
                                value={currentSwitch.productSize}
                                onChange={(event) => setCurrentSwitch({...currentSwitch, productSize: event.target.value})}
                                style={{width: 250}}>
                                <Radio value={WarehouseStock.All} style={{display: "block"}}>{t("create_switch.all_sizes")}</Radio>
                                <Radio value={WarehouseStock.Center} style={{display: "block", marginRight: 50}}>{t("create_switch.central_sizes")}</Radio>
                              </Radio.Group>
                            </ItemContainer>
                            <ItemContainer>
                              <StyledSelect
                                getPopupContainer={(trigger)=> trigger.parentNode}
                                style={{width: 250}}
                                suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                                bordered={false}
                                options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                                placeholder={"-"}
                                value={currentSwitch.productSizeSign}
                                onChange={(value) => setCurrentSwitch({...currentSwitch, productSizeSign: (value ? value as ComparisonSign : null), productNbItem: (value ? currentSwitch.productNbItem : null), productExcludesIds: []})}
                                allowClear
                              />
                            </ItemContainer>
                            <ItemContainer style={{position: "relative"}}>
                                <StyledInputNumber
                                  controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                                  value={currentSwitch.productNbItem}
                                  onChange={(value) => setCurrentSwitch({...currentSwitch, productNbItem: value as number, productExcludesIds: []})}
                                />
                                <StyledInputPlaceholderInner>{t("create_switch.pieces")}</StyledInputPlaceholderInner>
                            </ItemContainer>
                          </FlexContainer>
                        </ItemContainer>

                        <ItemContainer style={{marginTop: 20, marginLeft: 10}}>
                          <FlexContainer style={{flexDirection: "row", alignItems: "center"}}>
                            <ItemContainer>
                              <Radio.Group
                                value={currentSwitch.productCouv}
                                onChange={(event) => setCurrentSwitch({...currentSwitch, productCouv: event.target.value})}
                                style={{width: 250}}>
                                <Radio value={WarehouseStock.All} style={{display: "block", marginRight: 30}}>{t("create_switch.cover_all_sizes")}</Radio>
                                <Radio value={WarehouseStock.Center} style={{display: "block", marginRight: 30}}>{t("create_switch.cover_central_sizes")}</Radio>
                              </Radio.Group>
                            </ItemContainer>
                            <ItemContainer>
                              <StyledSelect
                                getPopupContainer={(trigger)=> trigger.parentNode}
                                style={{width: 250}}
                                suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                                bordered={false}
                                options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                                placeholder={"-"}
                                value={currentSwitch.productCouvSign}
                                onChange={(value) => setCurrentSwitch({...currentSwitch, productCouvSign: (value ? value as ComparisonSign : null), productNbWeek: (value ? currentSwitch.productNbWeek : null), productExcludesIds: []})}
                                allowClear
                              />
                            </ItemContainer>
                            <ItemContainer style={{position: "relative"}}>
                                <StyledInputNumber 
                                  controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                                  value={currentSwitch.productNbWeek}
                                  onChange={(value) => setCurrentSwitch({...currentSwitch, productNbWeek: value as number, productExcludesIds: []})}
                                />
                                <StyledInputPlaceholderInner>{t("create_switch.weeks")}</StyledInputPlaceholderInner>
                            </ItemContainer>
                          </FlexContainer>
                        </ItemContainer>
                    </FlexContainer>
                </ItemContainer>

          </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{marginBottom: 30}}>
          <FlexContainer style={{flexDirection: "column"}}>

            <ItemContainer style={{marginBottom: 20}}>
              <FlexContainer style={{flexDirection: "row"}}>
                <ItemContainer>
                  <StyledLabel style={{width: 250}}>{t("create_switch.minimum_price")}</StyledLabel>
                </ItemContainer>
                <ItemContainer style={{position: "relative"}}>
                  <StyledInputNumber
                    value={currentSwitch.productMinimumPrice}
                    onChange={(value) => setCurrentSwitch({...currentSwitch, productMinimumPrice: value as number})}
                    controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                  />
                  <StyledInputPlaceholderInner>€</StyledInputPlaceholderInner>
                </ItemContainer>
              </FlexContainer>
            </ItemContainer>

            <ItemContainer style={{marginBottom: 20}}>
              <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.sales_period")}</StyledLabel>
              <FlexContainer style={{flexDirection: "row", alignItems: "center", gap: 0}}>
                  <RequireSvg style={{width: 20}}/>
                  <StyledRadioGroup
                      value={currentSwitch.productSalePeriod} 
                      onChange={onChangeSalesPeriod}
                      style={{width: 540, border: "none"}}
                    >
                      <StyledRadio value={SalePeriod.Accumulation} style={{width: 240}}>{t("sale_period.Accumulation")}</StyledRadio>
                      <StyledRadio value={SalePeriod.FromDate} style={{width: 240, borderRight: "none", marginRight: 0}}>{t("sale_period.FromDate")}</StyledRadio>
                  </StyledRadioGroup>
                  <StyledInputDate 
                    style={{width: 250}} 
                    disabled={!isFromDate}
                    type={"date"}
                    value={currentSwitch.productFromPeriod}
                    onChange={onChangeFromDate}
                  />
              </FlexContainer>
            </ItemContainer>

            <ItemContainer style={{marginBottom: 20}}>
              <FlexContainer style={{flexDirection: "row"}}>
                <ItemContainer>
                  <StyledLabel style={{width: 230}}>{t("create_switch.qty_sales_over_period")}</StyledLabel>
                </ItemContainer>
                <FlexContainer style={{flexDirection: "row", gap: 0}}>
                  <ItemContainer>
                    <RequireSvg style={{width: 20}}/>
                  </ItemContainer>
                  <ItemContainer>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      style={{width: 250}}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      bordered={false}
                      options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                      placeholder={"-"}
                      value={currentSwitch.productQtyPeriodSign}
                      onChange={(value) => {setCurrentSwitch({...currentSwitch, productQtyPeriodSign: (value ? value as ComparisonSign : null), productQtyPeriodNbItem: (value ? currentSwitch.productQtyPeriodNbItem : null), productExcludesIds: []}); checkOnProduct();}}
                      allowClear
                    />
                  </ItemContainer>
                </FlexContainer>
                <ItemContainer style={{position: "relative"}}>
                    <StyledInputNumber
                      value={currentSwitch.productQtyPeriodNbItem}
                      onChange={(value) => {setCurrentSwitch({...currentSwitch, productQtyPeriodNbItem: value as number, productExcludesIds: []}); checkOnProduct();}}
                      controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                    />
                    <StyledInputPlaceholderInner>{t("create_switch.pieces")}</StyledInputPlaceholderInner>
                  </ItemContainer>
              </FlexContainer>
            </ItemContainer>

            <ItemContainer style={{padding: 15, backgroundColor: '#758ca7', borderRadius: 10}}>
              <StyledLabel style={{paddingBottom: 20, color: theme.white}}>{t("create_switch.exclusion_products")}</StyledLabel>

              <FlexContainer style={{flexDirection: "row", marginBottom: 30}}>
                <ItemContainer>
                  <StyledLabel style={{fontFamily: "NotoRegular", width: 250, paddingLeft: 40, color: theme.white}}>{t("create_switch.in_store_presence")}</StyledLabel>
                </ItemContainer>
                <ItemContainer>
                  <StyledSelect
                    getPopupContainer={(trigger)=> trigger.parentNode}
                    style={{width: 250, color: theme.white}}
                    suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                    bordered={false}
                    options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                    value={currentSwitch.productInStoreSign}
                    onChange={(value) => setCurrentSwitch({...currentSwitch, productInStoreSign: (value ? value as ComparisonSign : null), productInStoreNbWeeks: (value ? currentSwitch.productInStoreNbWeeks : null), productExcludesIds: []})}
                    placeholder={"-"}
                    allowClear
                  />
                </ItemContainer>
                <ItemContainer style={{position: "relative"}}>
                    <StyledInputNumber 
                        controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                        value={currentSwitch.productInStoreNbWeeks}
                        onChange={(value) => setCurrentSwitch({...currentSwitch, productInStoreNbWeeks: value as number, productExcludesIds: []})}
                    />
                    <StyledInputPlaceholderInner>{t("create_switch.weeks")}</StyledInputPlaceholderInner>
                </ItemContainer>
              </FlexContainer>

              <FlexContainer style={{flexDirection: "row", marginBottom: 30}}>
                <ItemContainer>
                  <StyledLabel style={{fontFamily: "NotoRegular", width: 250, paddingLeft: 40, color: theme.white}}>{t("create_switch.of_which_restocking_delivered_in")}</StyledLabel>
                </ItemContainer>
                <ItemContainer style={{position: "relative"}}>
                    <StyledInputNumber 
                    style={{width: 530}}
                    controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                    value={currentSwitch.productRestockNbWeeks}
                    onChange={(value) => setCurrentSwitch({...currentSwitch, productRestockNbWeeks: value as number, productExcludesIds: []})}
                    />
                    <StyledInputPlaceholderInner>{t("create_switch.next_weeks")}</StyledInputPlaceholderInner>
                </ItemContainer>
              </FlexContainer>

              <FlexContainer style={{flexDirection: "row", marginBottom: 30}}>
                <ItemContainer>
                  <StyledLabel style={{fontFamily: "NotoRegular", width: 250, paddingLeft: 40, color: theme.white}}>{t("create_switch.of_which_markdown_rate_over_period")}</StyledLabel>
                </ItemContainer>
                <ItemContainer>
                  <StyledSelect
                    getPopupContainer={(trigger)=> trigger.parentNode}
                    style={{width: 250, color: theme.white}}
                    suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                    bordered={false}
                    options={comparisonSign.map((sign) =>({value: ComparisonSign[sign as keyof typeof ComparisonSign], label:t(`comparison_sign.${sign}`)}))}
                    placeholder={"-"}
                    value={currentSwitch.productMarkDownPeriodSign}
                    onChange={(value) => setCurrentSwitch({...currentSwitch, productMarkDownPeriodSign: (value ? value as ComparisonSign : null), productMarkDownPercent: (value ? currentSwitch.productMarkDownPercent : null), productExcludesIds: []})}
                    allowClear
                  />
                </ItemContainer>
                <ItemContainer style={{position: "relative"}}>
                    <StyledInputNumber 
                      controls={{upIcon: <CaretUpOutlined/>, downIcon:<CaretDownOutlined/>}}
                      value={currentSwitch.productMarkDownPercent}
                      onChange={(value) => setCurrentSwitch({...currentSwitch, productMarkDownPercent: value as number, productExcludesIds: []})}
                    />
                    <StyledInputPlaceholderInner>%</StyledInputPlaceholderInner>
                </ItemContainer>
              </FlexContainer>

              <FlexContainer style={{flexDirection: "row"}}>
                <ItemContainer>
                  <StyledLabel style={{fontFamily: "sans-serif", width: 250, paddingLeft: 40, color: theme.white}}>{t("create_switch.products_exclude")}</StyledLabel>
                </ItemContainer>
                <ItemContainer>
                  <StyledPrimaryButton iconPath={pictoReglageActif} countBadge={countSelectedExcludeProduct} style={{width: 530, padding: "0.5em 0em"}} textKey="create_switch.settings_exclusions" onClick={() => onClickParamsExcludeProducts()} />
                </ItemContainer>

              </FlexContainer>

            </ItemContainer>

          </FlexContainer>
        </ItemContainer>

        <ExcludeModal
          customTitle={t('exclude_modal.title_product')}
          text={''}
          onSelect={onSelectExcludeProduct}
          open={excludeProductModalOpen}
          onCancel={() => setExcludeProductModalOpen(false)}
          colDefs={colDefs}
          rowData={currentProductData as unknown[]}
          // showOnlyCloseBtn={true}
          width= {"95%"}
          height={700}
          selectedIds={currentSwitch.productExcludesIds as number[] | undefined}
          getRowId={(params) => params.data.productId}
        />

      </FlexContainer>
    )
};

export default Product;