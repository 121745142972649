import React, {useEffect, useState} from 'react';

import { useTranslation } from 'react-i18next';
import { ItemContainer, StyledPrimaryButton, StyledRadio, StyledRadioGroup, StyledViewPrimaryButton } from './styles';
import { EyeOutlined } from '@ant-design/icons';
import {
  SwitchConfig,
  SwitchResult,
  useGetAllValidSwitchResultsQuery,
  useGetResultOriginConfigQuery
} from 'services/graphql/main';
import { getCacheVal, isTokenCloseToExpiration, useError } from 'services/utils';
import axios from "axios";
import envconfig from 'config/envconfig';
import CriteriasModal from "./CriteriasModal";

interface PropsExport {
  switchResult: SwitchResult;
}

const Export: React.FC<PropsExport> = ({switchResult}) => {

  const { t } = useTranslation('translation');
  const { addError, addCustomError } = useError();

  const [exportFormat, setExportFormat] = useState("details");
  const [isExportLoading, setExportLoading] = useState<boolean>(false);
  const [criteriasModalOpen, setCriteriasModalOpen] = useState<boolean>(false);
  const [originConfig, setOriginConfig] = useState<SwitchConfig>();

  const {
    loading: loadingresultOriginConfigQuery,
    data: resultOriginConfigDataQuery,
    refetch: refetchresultOriginConfigQuery,
  } = useGetResultOriginConfigQuery({
    variables: {id: switchResult.id},
    onError: (err) => addError(err, 'error')
  });

  useEffect(() => {
    if (resultOriginConfigDataQuery) {
      console.log(resultOriginConfigDataQuery.resultOriginConfig);
      setOriginConfig(resultOriginConfigDataQuery.resultOriginConfig);
    }
  }, [resultOriginConfigDataQuery]);

  const onExportHandler = async () => {
    setExportLoading(true);
    const token = getCacheVal('token');
    let route : string | null = '';
    switch (exportFormat) {
      case 'details':
        route = 'ExportTransfers';
        break;
      case 'ideal':
        route = 'ExportSenderIdealStock';
        break;
      case 'memo':
        route = null;
        break;
      default:
        route = null
    }
    if (route) {
      token && isTokenCloseToExpiration(token);
      try {
        const res = await axios.get(
          `${envconfig.exportUrl}/${route}/${switchResult?.id}`,
          {
            responseType: 'blob',
            headers: {
              authorization: `Bearer ${token}`
            },
          }
        );
        setExportLoading(false);
        if (res) {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${switchResult?.config?.name}_${exportFormat}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }
      } catch (err) {
        setExportLoading(false);
        addCustomError(err, 'error');
      }
    }
  };

    return (
      <>
          <ItemContainer>
              <StyledRadioGroup
                  value={exportFormat}
                  onChange={(event) => setExportFormat(event.target.value)}
                >
                  <StyledRadio value={'details'}>{t("export.transfer_details")}</StyledRadio>
                  <StyledRadio value={'ideal'}>{t("export.ideal_stock_exception")}</StyledRadio>
                  <StyledRadio value={'memo'}>{t("export.criteria_memo")}
                  <StyledViewPrimaryButton icon={<EyeOutlined />} onClick={() => setCriteriasModalOpen(true)} />
                  </StyledRadio>
              </StyledRadioGroup>
          </ItemContainer>

          <StyledPrimaryButton style={{width: 570, padding: "0.5em 10em"}} textKey="export.export_format" onClick={onExportHandler} loading={isExportLoading} />

          <CriteriasModal open={criteriasModalOpen} onCancel={() => setCriteriasModalOpen(false)} originConfig={originConfig}></CriteriasModal>
      </>
    )
  }

  export default Export;