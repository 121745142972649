import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import StyledModal from '../StyledModal/StyledModal';
import { StyledCancelPrimaryButton, StyledPrimaryButton } from '../StyledModal/styles';
import { TitleContainer, TextContainer, FooterContainer, Title, ButtonContainer, StyledSwitch } from './styles';

interface PropsSaveModal {
  customElement?: React.ReactNode;
  customTitle1?: string;
  customTitleKey1?: string;
  customTitle2?: string;
  customTitleKey2?: string;
  onCancel?: () => void;
  onSave: () => void;
  onSimulate: () => void;
  onToggleButton: (value: boolean) => void;
  open: boolean;
  text1?: string;
  textKey1?: string;
  text2?: string;
  textKey2?: string;
  toggleButton?: boolean;
  labelToggle?: string;
}

const SaveModal: React.FC<PropsSaveModal> = ({
  text1,
  textKey1,
  text2,
  textKey2,
  customTitle1,
  customTitleKey1,
  customTitle2,
  customTitleKey2,
  open,
  onCancel,
  onSave,
  onSimulate,
  onToggleButton,
  toggleButton,
  labelToggle,
  customElement,
}) => {
  const { t } = useTranslation('translation');

  const [stateToggleButton, SetStateToggleButton] = useState<boolean | undefined>(toggleButton);

  return (
    <>
      <StyledModal
        width="850px"
        closable={false}
        centered
        open={open}
        onOk={onSave}
        onCancel={onCancel}
        footer={
          <FooterContainer>
              <StyledPrimaryButton onClick={onSave} key="save" text={t('save_modal.button1')} />
              <StyledPrimaryButton onClick={onSimulate} key="simulate" text={t('save_modal.button2')} />
              <StyledCancelPrimaryButton onClick={onCancel} key="cancel" text={t('save_modal.cancel')} />
          </FooterContainer>
        }
        zIndex={2000}
      >
        <TitleContainer>
          <Title>{customTitleKey1 ? t(customTitleKey1) : customTitle1}</Title>
          <Title>{customTitleKey2 ? t(customTitleKey2) : customTitle2}</Title>
        </TitleContainer>

        <TextContainer>
            {textKey1 ? t(textKey1) : text1}<br/>
            {textKey2 ? t(textKey2) : text2}
        </TextContainer>

        <ButtonContainer>
          <Title style={{fontSize: "1.2em"}}>{labelToggle}</Title>
          <StyledSwitch
                checked={stateToggleButton}
                onChange={(value) => {onToggleButton(value); SetStateToggleButton(value)}}
            />
        </ButtonContainer>

        {customElement && customElement}
      </StyledModal>
    </>
  );
};

export default SaveModal;
