import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  black: '#20204e',
  white: '#ffffff',
  red: '#dd0000',
  light_red: '#fadddd',
  primary_yellow: '#FFCF00',
  pale_yellow: '#FDE269',
  dark_gray: '#3C3C3F',
  light_gray: '#949496',
  light_soft_gray: '#ebebf1',
  pale_gray: '#f3f4f8',
  green: '#4cbb17',
  light_green: '#e7fadd',
  dark_blue_background: '#58728d',
  light_blue_background: '#4c5b6d',
  more_light_blue_background: '#657f9d',
  blue: '#2762ff',
  blue_background_indicator: '#d5e4ff',
  blue_foreground_indicator: '#eaf1ff',
  purple_background_indicator: '#eddefe',
  purple_foreground_indicator: '#f6eefe',
  yellow_background_indicator: '#fff3df',
  yellow_foreground_indicator: '#fff9ef',
  green_background_indicator: '#e9f9f1',
  green_foreground_indicator: '#f4fcf8',
};

export default defaultTheme;
