import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const PageWrapper = styled.div`;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: ${(p) => p.theme.white};
  padding: 20px;
  border-radius: 20px;
  margin: auto;
  width: fit-content;
  height: 440px;
  width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "NotoBold"
`;

export const UnauthorizedText = styled.h2`
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
  font-size: 1.25em;
  width: 400px;
  color: ${(p) => p.theme.black};
`;

export const StyledImg = styled.img`
  width: 270px;
  padding: 20px;
`;

export const MessageText = styled.h3`
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  font-size: 1.125em;
  width: 340px;
  color: ${(p) => p.theme.black};
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  font-size: 1.063em;
  padding: 1em;
`