import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const StyledTitle = styled.h1`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.35em;
  margin: 0px;
`;

export const FlexContainer =  styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-itmes: center;
  align-content: center;
`;

export const FlexContainerArticle =  styled.div`
  display: flex;
  flex-direction: column;
  align-itmes: center;
  align-content: flex-start;
  gap: 0px 20px;
`;

export const ItemContainer = styled.div`
  flex-grow: 0;
`;

export const StyledImg = styled.img`
  width: 300px;
  height: 250px;
  margin: auto;
  display: block;
  margin: 0px;
  border-radius: 10px;
`;

export const StyledCategoryTitle = styled.h3`
  color: ${(props) => props.theme.light_gray};
  font-family: "NotoBold";
  font-size: 1.06em;
  margin: 0px;
`

export const StyledArticleTitle = styled.h2`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.42em;
  margin: 0px;
`
export const StyledArticle = styled.p`
  color: ${(props) => props.theme.light_gray};
  font-family: "NotoBold";
  font-size: 0.9em;
  margin: 0px;
`
export const StyledImgPortrait = styled.img`
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
  margin: 0px;
  border-radius: 50%;
`;

export const StyledName = styled.p`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 0.9em;
  margin: 0px;
`

export const StyledDate = styled.p`
  color: ${(props) => props.theme.light_gray};
  font-family: "NotoMedium";
  font-size: 0.75em;
  margin: 0px;
`

export const StyledPrimaryButton = styled(PrimaryButton)`
  padding: 0.75em 1.25em;

  i {
    width: 8px;
    height: 13px;
  }
`
interface GridContainerProps {
  gridWidth?: number,
  gridHeight?: number
}


export const GridContainer = styled.div.attrs({
  className: 'ag-theme-material'})<GridContainerProps>`

  height: ${(props) => props.gridHeight ? props.gridHeight + 'px' : '600px'};
  width: ${(props) => props.gridWidth ? 'calc('+ props.gridWidth + 'px -50 px)' : '100%'};
  border: lightgray solid 1px;
  border-radius: 18px;
  padding: 10px;

  .ag-cell-label-container {
      flex-direction: column-reverse;
      align-content: center;
      align-items: center;
  }

  .ag-header-cell-label {
    text-align: center;
    justify-content: center;
  }

  .ag-details-row {
    padding: calc(var(--ag-grid-size) * 2);
  }

  --ag-range-selection-border-style: none;
  --ag-range-selection-border-color: ${(props) => props.theme.green};
  --ag-selected-row-background-color: ${(props) => props.theme.light_green};
  --ag-range-selection-background-color: ${(props) => props.theme.light_green};
  --ag-range-selection-highlight-color: ${(props) => props.theme.light_green};
  --ag-material-accent-color: ${(props) => props.theme.green};
  --ag-data-color: ${(props) => props.theme.black};
  --ag-font-family: "NotoRegular";
  --ag-font-size: 1em;
`;