import React, { useState } from 'react';
import {
  GetSwitchConfigFormByIdQuery,
  MaximizationCriterion,
  ReferencyColor,
  ReferencyTransfert,
  SalePeriod,
  SwitchConfigFormInput,
  SwitchSetting,
  WarehouseStock
} from 'services/graphql/main';
import { ProductRow } from 'types/product';
import { StoreRow } from 'types/store';
import {useTranslation} from "react-i18next";

export type numberOrZero = number | 0;

type SwitchContextType = {
    countProduct?: numberOrZero,
    countShipping?: numberOrZero,
    countRecipient?: numberOrZero,
    currentSwitch: SwitchConfigFormInput;
    currentProductData: ProductRow[];
    currentStoreShipperData: StoreRow[];
    currentStoreRecipientData: StoreRow[];
    setCountProduct: (count?: number) => void;
    setCountShipping: (count?: number) => void;
    setCountRecipient: (count?: number) => void;
    clearCounts: () => void;
    setCurrentSwitch: (currentSwitch: SwitchConfigFormInput, alreadyUse?: boolean) => void;
    resetSwitch: (settings: SwitchSetting, universeId?: number) => void;
    switchConfigFormToform: (data: GetSwitchConfigFormByIdQuery, settings: SwitchSetting) => void;
    setCurrentProductData: (products: ProductRow[]) => void;
    setCurrentStoreShipperData: (stores: StoreRow[]) => void;
    setCurrentStoreRecipientData: (stores: StoreRow[]) => void;
    isSaveable: boolean;
    saveableMsg: string;
};

export const SwitchContext = React.createContext<SwitchContextType>({} as SwitchContextType);

type ContextProviderType = {
  children: React.ReactNode;
};

export const SwitchContextProvider: React.FC<ContextProviderType> = ({ children }) => {
  const { t } = useTranslation('translation');

    const [countProduct, _setCountProduct] = useState<numberOrZero | undefined>(0);
    const [countShipping, _setCountShipping] = useState<numberOrZero | undefined>(0);
    const [countRecipient, _setCountRecipient] = useState<numberOrZero | undefined>(0);
    const [currentSwitch, _setCurrentSwitch] = useState<SwitchConfigFormInput>({universeId: 0});
    const [currentProductData, setCurrentProductData] = useState<ProductRow[]>([]);
    const [currentStoreShipperData, setCurrentStoreShipperData] = useState<StoreRow[]>([]);
    const [currentStoreRecipientData, setCurrentStoreRecipientData] = useState<StoreRow[]>([]);
    const [isSaveable, setIsSaveable] = useState<boolean>(false)
    const [saveableMsg, setSaveableMsg] = useState<string>('');

    const clearCounts = () => {
        setCountProduct(0);
        setCountShipping(0);
        setCountRecipient(0);
    };

    const resetSwitch = (settings: SwitchSetting, universeId?: number) => {
        setCurrentSwitch({
          name: '',
          universeId: (universeId ? universeId : 0),
          referencyColor: settings.transfertDefaultRC ? settings.transfertDefaultRC : ReferencyColor.Complete,
          referencyTransfert: settings.transfertDefaultRCT ? settings.transfertDefaultRCT : ReferencyTransfert.InterStore,
          productCouv: WarehouseStock.All,
          productSize: WarehouseStock.All,
          productSalePeriod: SalePeriod.Accumulation,
          storeCountriesIds: [],
          storeRegionsIds: [],
          storeTypesIds: [],
          productSeasonsIds: [],
          perfIdealStock: true,
          interRegion: true,
          maximizationCriterion: MaximizationCriterion.ProfitMargin,
          carbonFootprintBonus: true
        });
        setCurrentProductData([]);
        setCurrentStoreShipperData([]);
        setCurrentStoreRecipientData([]);
        clearCounts();
    }

    const switchConfigFormToform = (data: GetSwitchConfigFormByIdQuery, settings: SwitchSetting) => {
      setCurrentSwitch({...currentSwitch,
        productExcludesIds: data.switchConfigForm.productExcludesIds as number[] | null,
        carbonFootprintBonus: data.switchConfigForm.carbonFootprintBonus,
        id: data.switchConfigForm.id,
        interCountry: data.switchConfigForm.interCountry,
        interRegion: data.switchConfigForm.interRegion,
        maximizationCriterion: data.switchConfigForm.maximizationCriterion,
        name: data.switchConfigForm.name,
        nbDestMax: data.switchConfigForm.nbDestMax,
        nbItemMaxTransfer: data.switchConfigForm.nbItemMaxTransfer,
        nbItemMin: data.switchConfigForm.nbItemMin,
        perfDestFlowRateSign: data.switchConfigForm.perfDestFlowRateSign,
        perfDestFlowRatePercent: data.switchConfigForm.perfDestFlowRatePercent,
        perfDestQtyPeriodSale: data.switchConfigForm.perfDestQtyPeriodSale,
        perfDestQtyPeriodSaleSign: data.switchConfigForm.perfDestQtyPeriodSaleSign,
        perfExpFlowRateSign: data.switchConfigForm.perfExpFlowRateSign,
        perfExpFlowRatePercent: data.switchConfigForm.perfExpFlowRatePercent,
        perfExpPresenceMin: data.switchConfigForm.perfExpPresenceMin,
        perfExpQtyPeriodSale: data.switchConfigForm.perfExpQtyPeriodSale,
        perfExpQtyPeriodSaleSign: data.switchConfigForm.perfExpQtyPeriodSaleSign,
        perfIdealStock: data.switchConfigForm.perfIdealStock,
        productCouv: data.switchConfigForm.productCouv,
        productCouvSign: data.switchConfigForm.productCouvSign,
        productDepartmentIds: settings.productDepartementEnabled ? data.switchConfigForm.productDepartmentIds as number[] | null : [],
        productFamilyIds: settings.productFamilyEnabled ? data.switchConfigForm.productFamilyIds as number[] | null : [],
        productCustomField1ValueCodes: settings.productCustomFieldValues1Enabled ? data.switchConfigForm.productCustomField1Values : [],
        productCustomField2ValueCodes: settings.productCustomFieldValues2Enabled ? data.switchConfigForm.productCustomField2Values : [],
        productCustomField3ValueCodes: settings.productCustomFieldValues3Enabled ? data.switchConfigForm.productCustomField3Values : [],
        productCustomField4ValueCodes: settings.productCustomFieldValues4Enabled ? data.switchConfigForm.productCustomField4Values : [],
        productCustomField5ValueCodes: settings.productCustomFieldValues5Enabled ? data.switchConfigForm.productCustomField5Values : [],
        productFromPeriod: data.switchConfigForm.productFromPeriod?.split('T')[0],
        productInStoreNbWeeks: data.switchConfigForm.productInStoreNbWeeks,
        productInStoreSign: data.switchConfigForm.productInStoreSign,
        productMarkDownPercent: data.switchConfigForm.productMarkDownPercent,
        productMarkDownPeriodSign: data.switchConfigForm.productMarkDownPeriodSign,
        productMinimumPrice: data.switchConfigForm.productMinimumPrice,
        productNbItem: data.switchConfigForm.productNbItem,
        productNbWeek: data.switchConfigForm.productNbWeek,
        productQtyPeriodSign: data.switchConfigForm.productQtyPeriodSign,
        productQtyPeriodNbItem: data.switchConfigForm.productQtyPeriodNbItem,
        productRestockNbWeeks: data.switchConfigForm.productRestockNbWeeks,
        productSalePeriod: data.switchConfigForm.productSalePeriod,
        productSeasonsIds: data.switchConfigForm.productSeasonsIds as number[] | null,
        productSize: data.switchConfigForm.productSize,
        productSizeSign: data.switchConfigForm.productSizeSign,
        productSubFamilyIds: settings.productSubFamilyEnabled ? data.switchConfigForm.productSubFamilyIds as number[] | null : [],
        universeId: data.switchConfigForm.universeId as number,
        referencyColor: data.switchConfigForm.referencyColor,
        referencyTransfert: data.switchConfigForm.referencyTransfert,
        storeCountriesIds: data.switchConfigForm.storeCountriesIds as number[] | null,
        storeRecipientExcludesIds: data.switchConfigForm.storeRecipientExcludesIds as number[] | null,
        storeRegionsIds: settings.storeRegionEnabled ? data.switchConfigForm.storeRegionsIds as number[] | null : [],
        storeCustomField1ValueCodes: settings.storeCustomFieldValues1Enabled ? data.switchConfigForm.storeCustomField1Values : [],
        storeCustomField2ValueCodes: settings.storeCustomFieldValues2Enabled ? data.switchConfigForm.storeCustomField2Values : [],
        storeCustomField3ValueCodes: settings.storeCustomFieldValues3Enabled ? data.switchConfigForm.storeCustomField3Values : [],
        storeCustomField4ValueCodes: settings.storeCustomFieldValues4Enabled ? data.switchConfigForm.storeCustomField4Values : [],
        storeCustomField5ValueCodes: settings.storeCustomFieldValues5Enabled ? data.switchConfigForm.storeCustomField5Values : [],
        storeSenderExcludesIds: data.switchConfigForm.storeSenderExcludesIds as number[] | null,
        storeTypesIds: settings.storeTypeEnabled ? data.switchConfigForm.storeTypesIds as number[] | null : [],
        saved: data.switchConfigForm.saved,
        pinned: data.switchConfigForm.pinned
      });
    }

    const setCountProduct = (count?: number) => {
      _setCountProduct(count);
      checkSaveable(undefined, count);
    }
    const setCountShipping = (count?: number) => {
      _setCountShipping(count);
      checkSaveable(undefined, undefined, count);
    }
    const setCountRecipient = (count?: number) => {
      _setCountRecipient(count);
      checkSaveable(undefined, undefined, undefined, count);
    }

    const setCurrentSwitch = (switchConfig: SwitchConfigFormInput, alreadyUse?: boolean) => {
      _setCurrentSwitch(switchConfig);
      checkSaveable(switchConfig, undefined, undefined, undefined, alreadyUse);
    };

    const checkSaveable = (config?: SwitchConfigFormInput, nbProduct?: number, nbShipping?: number, nbRecipient?: number, alreadyUse?: boolean) => {
      let switchConfig = config ? config : currentSwitch;
      let switchProduct = nbProduct ? nbProduct : countProduct;
      let switchShipping = nbShipping ? nbShipping : countShipping;
      let switchRecipient = nbRecipient ? nbProduct : countRecipient;
      let msg = t('footer.verify_criterias');
      if (!alreadyUse &&
        switchConfig.universeId &&
        switchConfig.productSeasonsIds && switchConfig.productSeasonsIds.length > 0 &&
        switchConfig.storeCountriesIds && switchConfig.storeCountriesIds.length > 0 &&
        switchConfig.productQtyPeriodSign && switchConfig.productQtyPeriodNbItem &&
        switchConfig.nbDestMax && switchConfig.nbItemMaxTransfer && switchConfig.nbItemMin &&
        switchConfig.name &&
        switchProduct && switchProduct > 0 && switchShipping && switchShipping > 0 && switchRecipient && switchRecipient > 0
      ) {
        setIsSaveable(true);
      } else {
        if (alreadyUse) {
          msg += `
          
          ` + t('create_switch.already_use');
        }
        if (!(switchConfig.universeId &&
          switchConfig.productSeasonsIds && switchConfig.productSeasonsIds.length > 0 &&
          switchConfig.storeCountriesIds && switchConfig.storeCountriesIds.length > 0 &&
          switchConfig.productQtyPeriodSign && switchConfig.productQtyPeriodNbItem &&
          switchConfig.nbDestMax && switchConfig.nbItemMaxTransfer && switchConfig.nbItemMin &&
          switchConfig.name)) {
          msg += `
          
          ` + t('footer.mandatory_criterias');
          if (!switchConfig.universeId) {
            msg += `
            - ` +  t('create_switch.brand');
          }
          if (!switchConfig.name) {
            msg += `
            - ` +  t('create_switch.your_switch_name');
          }
          if (!switchConfig.storeCountriesIds || switchConfig.storeCountriesIds.length === 0) {
            msg += `
            - ` +  t('create_switch.country');
          }
          if (!switchConfig.productSeasonsIds || switchConfig.productSeasonsIds.length === 0) {
            msg += `
            - ` +  t('create_switch.management_season');
          }
          if (!switchConfig.productQtyPeriodSign || !switchConfig.productQtyPeriodNbItem) {
            msg += `
            - ` +  t('create_switch.qty_sales_over_period');
          }
          if (!switchConfig.nbDestMax) {
            msg += `
            - ` +  t('create_switch.max_number_recipients');
          }
          if (!switchConfig.nbItemMin) {
            msg += `
            - ` +  t('create_switch.min_number_pieces');
          }
          if (!switchConfig.nbItemMaxTransfer) {
            msg += `
            - ` +  t('create_switch.max_pieces_transferred');
          }
        }
        if (!switchProduct || switchProduct <= 0) {
          msg += `
          
            ` +  t('footer.no_products');
        }
        if (!switchShipping || switchShipping <= 0) {
          msg += `
          
            ` +  t('footer.no_shippers');
        }
        if (!switchRecipient || switchRecipient <= 0) {
          msg += `
          
            ` + t('footer.no_recipients');
        }
        setIsSaveable(false);
      }
      setSaveableMsg(msg);
    }

  return (
    <SwitchContext.Provider
      value={{
        countProduct,
        countShipping,
        countRecipient,
        currentSwitch,
        currentProductData,
        currentStoreShipperData,
        currentStoreRecipientData,
        setCountProduct,
        setCurrentStoreShipperData,
        setCurrentStoreRecipientData,
        setCountShipping,
        setCountRecipient,
        clearCounts,
        setCurrentSwitch,
        resetSwitch,
        switchConfigFormToform,
        setCurrentProductData,
        isSaveable,
        saveableMsg
      }}
    >
      {children}
    </SwitchContext.Provider>
  );
};
