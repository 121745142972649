import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GridContainer, ItemContainer } from './styles';
import { Switch } from 'antd';
import { percentFormatter } from 'components/AgGridComponents/valueFormatters';
import { ColDef } from 'ag-grid-community';
import {SwitchResultArticleStoreRow} from "../../../../../types/switch-result-article-store";

interface Props {
  rowData?: SwitchResultArticleStoreRow[]
  hasClearFilters: boolean
}

const GridItem: React.FC<Props> = ({
  rowData,
  hasClearFilters
}) => {

    const { t } = useTranslation('translation');

    type PinnedType = boolean | 'left' | 'right' | null;

    const colDefs: ColDef[] = useMemo( () => [
        {field: 'shipperName', headerName: t('grid_item.store_name_shipper'), headerTooltip: t('grid_item.store_name_shipper_tt'), sortable: true, width: 200, autoHeaderHeight: true, wrapHeaderText: true, pinned: 'left' as PinnedType, filter: 'agTextColumnFilter', cellClass: "grid-pinned-column", resizable: true},
        {field: 'receiverName', headerName: t('grid_item.recipient_store_name'), headerTooltip: t('grid_item.recipient_store_name_tt'), sortable: true, width: 200, autoHeaderHeight: true, wrapHeaderText: true, pinned: 'left' as PinnedType, filter: 'agTextColumnFilter', cellClass: "grid-pinned-column", resizable: true},
        {field: 'productCode', headerName: t('grid_item.code'), headerTooltip: t('grid_item.dcode'), sortable: true, width: 125, autoHeaderHeight: true, wrapHeaderText: true, pinned: 'left' as PinnedType, filter: 'agTextColumnFilter', cellClass: "grid-pinned-column", resizable: true},
        {field: 'productName', headerName: t('grid_item.name'), headerTooltip: t('grid_item.name_tt'), sortable: true, width: 125, autoHeaderHeight: true, wrapHeaderText: true, pinned: 'left' as PinnedType, filter: 'agTextColumnFilter', cellClass: "grid-pinned-column", resizable: true},
        {field: 'labelColor', headerName: t('grid_item.label_color'), headerTooltip: t('grid_item.label_color_tt'), sortable: true, width: 125, autoHeaderHeight: true, wrapHeaderText: true, pinned: 'left' as PinnedType, filter: 'agTextColumnFilter', cellClass: "grid-pinned-column", resizable: true},
        {field: 'size',headerName: t('grid_item.size'), headerTooltip: t('grid_item.size_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, pinned: 'left' as PinnedType, filter: 'agNumberColumnFilter', cellClass: "grid-pinned-column", resizable: true},
        {field: 'price', headerName: t('grid_item.price'), headerTooltip: t('grid_item.price_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'transferQuantity', headerName: t('grid_item.transfer_quantity'), headerTooltip: t('grid_item.transfer_quantity_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'shipperSales', headerName: t('grid_item.shipper_sales'), headerTooltip: t('grid_item.shipper_sales_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'shipperStocks', headerName: t('grid_item.shipper_stocks'), headerTooltip: t('grid_item.shipper_stocks_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'recipientSales', headerName: t('grid_item.recipient_sales'), headerTooltip: t('grid_item.recipient_sales_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'recipientStocks', headerName: t('grid_item.recipient_stocks'), headerTooltip: t('grid_item.recipient_stocks_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'flow', headerName: t('grid_item.flow'), headerTooltip: t('grid_item.flow_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true, valueFormatter: percentFormatter},
        {field: 'recipientNeeds', headerName: t('grid_item.recipient_needs'), headerTooltip: t('grid_item.recipient_needs_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'salesGain', headerName: t('grid_item.sales_gain'), headerTooltip: t('grid_item.sales_gain_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
        {field: 'gainInSales', headerName: t('grid_item.gain_in_sales'), headerTooltip: t('grid_item.gain_in_sales_tt'), sortable: true, width: 100, autoHeaderHeight: true, wrapHeaderText: true, filter: 'agNumberColumnFilter', cellClass: "grid-column-center", resizable: true},
    ], [t]);

    const [colDefsGrid, setColDefs] = useState(colDefs);
    const [rowDataGrid, setRowData] = useState(rowData);
    const [selectedRows, setSelectedRows] = useState<unknown[] | null>(null);

    const gridRef = useRef<AgGridReact>(null);

    const onSelectionChanged = () => {
      const rows = gridRef.current!.api.getSelectedRows();
      setSelectedRows(rows);
    };

    const customContextMenuItems = () => {
      return [
        'cut',
        'copy',
        'copyWithHeaders',
        'paste',
      ];
    }


    useEffect(() =>{
      if (rowData) {
        setRowData(rowData);
      }
    }, [rowData]);

    useEffect(() =>{
      if(gridRef && gridRef.current && gridRef.current.api) gridRef.current!.api.setFilterModel({});
    }, [hasClearFilters]);
  
  return (

        <GridContainer gridWidth={1000} gridHeight={1000}>
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefsGrid}
            rowData={rowDataGrid}
            rowHeight={40}
            onSelectionChanged={onSelectionChanged}
            rowSelection='multiple'
            getContextMenuItems={customContextMenuItems}
          />
        </GridContainer>

  );
};

export default GridItem;
