import React from 'react';

import App from 'App';

import { Route, Routes, BrowserRouter } from 'react-router-dom';

import MainLayout from './layouts/MainLayout/MainLayout';

import AllRoutes from './AllRoutes';
import LoginRedirect from './Login/LoginRedirect';
import ProtectedRoute from './Login/ProtectedRoute';
import RedirectCallback from './Login/RedirectCallback';
import Unauthorized from './Unauthorized/Unauthorized';
import Dashboard from './Dashboard/Dashboard';
import SwitchCreate from './Switch/Create/SwitchCreate';
import SwitchSimulate from './Switch/Simulate/SwitchSimulate';
import SwitchHistory from './Switch/History/SwitchHistory';
import Parameters from './Parameters/Parameters';
import Customers from './Customers/Customers';
import { SwitchSettingContextProvider } from 'context';

const Routing: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={AllRoutes.Login} element={<LoginRedirect />} />
        <Route path={AllRoutes.AuthorizationCallback} element={<RedirectCallback />} />
        <Route path={AllRoutes.Unauthorized} element={<Unauthorized />} />
        <Route
          element={
            <ProtectedRoute>
              <SwitchSettingContextProvider>
                <MainLayout hasMenuSider={true} hasTabs={true} hasFooter={true} noPadding={true} />
              </SwitchSettingContextProvider>
            </ProtectedRoute>
          }
        >
          <Route path={AllRoutes.SwitchCreate+'/:id?'} element={<SwitchCreate />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <SwitchSettingContextProvider>
                <MainLayout hasMenuSider={true} hasTabs={true} hasFooter={false} noPadding={true} />
              </SwitchSettingContextProvider>
            </ProtectedRoute>
          }
        >
          <Route path="" element={<Dashboard />} />
          <Route path={AllRoutes.Dashboard} element={<Dashboard />} />
          <Route path={AllRoutes.SwitchSimulate} element={<SwitchSimulate />} />
          <Route path={AllRoutes.SwitchHistory} element={<SwitchHistory />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <SwitchSettingContextProvider>
                <MainLayout hasMenuSider={true} hasTabs={false} hasFooter={false} noPadding={true} />
              </SwitchSettingContextProvider>
            </ProtectedRoute>
          }
        >
          <Route path={AllRoutes.Parameters} element={<Parameters />} />
          <Route path={AllRoutes.Customers} element={<Customers />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Routing;
