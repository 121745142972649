import { Badge, Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  border-radius: 30px;
  padding: 0.75em 4em;
  height: auto;
  background-color: ${(p) => p.theme.light_green};
  border-color: ${(p) => p.theme.green};
  color: ${(p) => p.theme.black};
  :hover {
    background-color: ${(p) => p.theme.green};
    border-color: ${(p) => p.theme.green};
    color: ${(p) => p.theme.white};
    i {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }
  }
  :focus {
    background-color: ${(p) => p.theme.light_green};
    border-color: ${(p) => p.theme.green};
    color: ${(p) => p.theme.black};
  }
`;

export const StyledBadge = styled(Badge)`
  margin-left: 1em;
`;

export const StyledIcon = styled.i<{$iconPath: string}>`
    display: inline-block;
    height: 20px;
    width: 20px;
    background-image:  ${(props) => `url(${props.$iconPath})`};
    background-size: cover;
`;