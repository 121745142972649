import { Radio } from "antd";
import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import { FlexContainer, ItemContainer, StyledLabel, StyledLightLabel, StyledSwitch } from "./styles";
import { SwitchContext } from "context";
import { MaximizationCriterion } from "services/graphql/main";

const MaximizationCriteria: React.FC = () => {

    const { t } = useTranslation('translation');

    const { currentSwitch, setCurrentSwitch } = useContext(SwitchContext);

    return (
      <FlexContainer style={{flexDirection: "row", flexWrap: "wrap", marginTop: 30, marginBottom: 30}}>

        <ItemContainer>
          <FlexContainer style={{flexDirection: "column", paddingLeft: 40}}>

              <ItemContainer>

                <Radio.Group
                  value={currentSwitch.maximizationCriterion}
                  onChange={(event) => setCurrentSwitch({...currentSwitch, maximizationCriterion: event.target.value as MaximizationCriterion})}
                >
                  <Radio value={MaximizationCriterion.GainPerSales} style={{width: "350px", borderRight: "solid 1px lightgray", marginRight: "20px"}}>{t("create_switch.estimated_gain_on_sales")}</Radio>
                  <Radio value={MaximizationCriterion.ProfitTurnover} style={{width: "350px", borderRight: "solid 1px lightgray", marginRight: "20px"}}>{t("create_switch.estimated_profit_on_sales")}</Radio>
                  <Radio value={MaximizationCriterion.ProfitMargin}>{t("create_switch.estimated_profit_margin")}</Radio>
                </Radio.Group>

              </ItemContainer>

          </FlexContainer>
        </ItemContainer>


        <ItemContainer style={{width: 350}}>
            <FlexContainer style={{flexDirection: "row"}}>

                <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.bonus_carbon")}</StyledLabel>
                <FlexContainer style={{flexDirection: "row", borderBottom: "solid 1px lightgray", paddingBottom: "10px"}}>
                    <ItemContainer style={{flexGrow: 1}}>
                        <StyledLightLabel isTrue={!!currentSwitch.carbonFootprintBonus}>{currentSwitch.carbonFootprintBonus ? t("create_switch.yes") : t("create_switch.no")}</StyledLightLabel>
                    </ItemContainer>
                    <ItemContainer style={{flexGrow: 1}}>
                        <StyledSwitch
                            checked={currentSwitch.carbonFootprintBonus as boolean}
                            onChange={(value) => setCurrentSwitch({...currentSwitch, carbonFootprintBonus: value as boolean})}
                        />
                    </ItemContainer>
                </FlexContainer>

            </FlexContainer>
        </ItemContainer>

      </FlexContainer>
    )
};

export default MaximizationCriteria;