import { Progress, Select } from 'antd';
import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const FlexContainer =  styled.div`
    display: flex;
    justify-content: left;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px 30px;
`;

export const ItemContainer = styled.div`
    flex-grow: 0;
`;

export const StyledItemContainer = styled.div`
    flex-grow: 0;
    border: ${(props) => props.theme.light_soft_gray} 1px solid;
    padding: 10px;
    border-radius: 12px;
`;

export const StyledLabel = styled.h5`
    color: ${(props) => props.theme.black};
    font-family: "NotoMedium";
    font-size: 1.06em;
`;

export const StyledSelect = styled(Select)`
  font-family: "NotoRegular";
  font-size: 1.06em;

  .rc-virtual-list-holder {
    overflow: auto !important;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)<{isactive?: boolean}>`
    font-family: "NotoMedium";
    font-size: 1.em;
    border-radius: 50px;
    padding: 1em 3em;
    margin-right: 20px;
    background-color: ${(props) => props.isactive ? props.theme.light_green : props.theme.dark_blue_background };
    color: ${(props) => props.isactive ? props.theme.black : props.theme.white };
    border-color: ${(props) => props.isactive ? props.theme.green : props.theme.dark_blue_background };
`;

export const StyledButtonContainer = styled.div`
    flex-grow: 0;
    background-color: ${(props) => props.theme.dark_blue_background};
    padding: 10px;
    border-radius: 50px;
`;

export const StyledFilterPrimaryButton = styled(PrimaryButton)`
  padding: 0.7em 0.9em;

  i {
    width: 21px;
    height: 21px;
  }
`
export const StyledIconPrimaryButton = styled(PrimaryButton)`
  margin-right: 20px;
  font-size: 22px;
  padding: 0.3em 
  0.2em 0.3em 0.6em;
`

export const StyledCancelIconPrimaryButton = styled(PrimaryButton)`
  margin-right: 20px;
  font-size: 22px;
  padding: 0.3em
  0.2em 0.3em 0.6em;
  background-color: ${(p) => p.theme.light_red};
  border-color: ${(p) => p.theme.red};
  :hover {
    background-color: ${(p) => p.theme.red};
    border-color: ${(p) => p.theme.red};
    color: ${(p) => p.theme.white};
  }
  :focus {
    background-color: ${(p) => p.theme.light_red};
    border-color: ${(p) => p.theme.red};
    color: ${(p) => p.theme.black};
  }
`;
export const FlexContainerGrid =  styled.div`
    display: flex;
    justify-content: left;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px 30px;
    height: calc(100vh - 500px);
`;

export const StyledProgress =  styled(Progress)`

    .ant-progress-bg {
        height: 20px !important;
        background-color: ${(props) => props.theme.light_green};
        border: 1px solid ${(props) => props.theme.green};
    }

    .ant-progress-text {
        font-size: 1.3em;
        color: ${(props) => props.theme.black};
        width: 1.3em;
        margin-left: 4px;

        .anticon {
            font-size: 18px;
            color: ${(props) => props.theme.green};
        }
    }

    .ant-progress-satus-success {
        background-color: ${(props) => props.theme.green};
        color: ${(props) => props.theme.green};
    }

`;