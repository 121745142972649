import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';

import { BlocContent } from 'components/wrappers';
import {
    FlexContainer,
    ItemContainer,
    StyledPrimaryButton,
    StyledButtonContainer,
    StyledFilterPrimaryButton,
    FlexContainerGrid,
    StyledIconPrimaryButton,
    StyledProgress,
    StyledLabel,
    StyledCancelIconPrimaryButton
} from './styles';
import { Indicators } from 'components/UI';

import pictoFiltres from 'assets/images/picto-désactiver-filtres.svg';
import GridItem from './components/GridItem';
import GridProduct from './components/GridProduct';
import GridStore from './components/GridStore';
import {DeleteOutlined, RedoOutlined} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    ProcessStatus,
    SwitchConfig, SwitchConfigFormInput,
    SwitchResult,
    SwitchResultMetadata,
    SwitchResultProduct, useCreateSwitchConfigMutation,
    useGetSimulateSwitchQuery,
    useGetSimulationProgressLazyQuery,
    useGetSwitchConfigByIdLazyQuery,
    useGetSwitchConfigFormByIdLazyQuery,
    useGetSwitchResultByGuidLazyQuery,
    useGetSwitchResultByGuidQuery,
    useStopSimulationLazyQuery,
    useStopSimulationQuery, useValidateSwitchResultMutation, ValidateSwitchResultDocument,
} from 'services/graphql/main';
import {logout, useError} from 'services/utils';
import AllRoutes from 'screens/AllRoutes';
import { useNavigate } from 'react-router-dom';
import {SwitchResultProductRow} from "../../../../types/switch-result-product";
import {SwitchResultStoreSenderRow} from "../../../../types/switch-result-store-sender";
import {SwitchResultStoreReceiverRow} from "../../../../types/switch-result-store-receiver";
import {SwitchResultArticleStoreRow} from "../../../../types/switch-result-article-store";
import {ConfirmModal} from "../../../../components/Modals";
import {SwitchContext, SwitchSettingContext} from "../../../../context";
import {toast, ToastContainer} from "react-toastify";

type Props = {
    children?: React.ReactNode;
    selectedSwitch: number;
    selectedSwitchName?: string | null;
    stopSimulate: () => void;
}

const SwitchSimulation: React.FC<Props> = ({children, selectedSwitch, selectedSwitchName, stopSimulate}) => {

    const { t } = useTranslation('translation');
    const { addError } = useError();
    const DELAY: number = 1000;
    const navigate = useNavigate();

    const [processId, setProcessId] = useState<string | null | undefined>(null);
    const [countProcess, setCountProcess] = useState<number>(0);
    const [messageProcess, setMessageProcess] = useState<string>("");
    const [switchResult, setSwitchResult] = useState<SwitchResult | null | undefined>();
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

    const {currentSwitch, setCurrentSwitch, switchConfigFormToform} = useContext(SwitchContext);

    const {switchSetting} = useContext(SwitchSettingContext);

    const [
        getSwitchConfigByIdLazyQuery,
        { loading: loadingSwitchConfigByIdQuery, error: errorSwitchConfigByIdDataQuery, refetch: switchConfigByIdDataQuery },
    ] = useGetSwitchConfigFormByIdLazyQuery({ onError: (err) => addError(err, 'error') });

    const {
        loading: loadingSimpleSwitchesConfigsQuery,
        data: simpleSwitchesConfigsDataQuery,
        refetch: refetchSwitchesConfigsQuery,
    } = useGetSimulateSwitchQuery({
        variables: {switchConfigId: selectedSwitch},
        onError: (err) => addError(err, 'error') });

    const [ getSimulationProcess, 
            {
                loading: loadingSimulationProcess,
                data: simulationProcessDataQuery,
                refetch: refetchSimulationProcess,
                called: simulationProcessCalled
            }
    ] = useGetSimulationProgressLazyQuery({
        variables: {processId: processId},
        onError: (err) => addError(err, 'error') });

    const [ stopSimulationLazyQuery,
        {
            loading: loadingStopSimulationQuery,
            data: stopSimulationDataQuery,
            refetch: refetchStopSimulationQuery,
        }
    ] = useStopSimulationLazyQuery({
        variables: {switchConfigId: selectedSwitch, processId: processId},
        onError: (err) => addError(err, 'error')
    });

    const [ getSwitchResult,
        {
            loading: loadingSwitchResults,
            data: switchResultsDataQuery,
            refetch: refetchSwitchResults,
          }
    ] = useGetSwitchResultByGuidLazyQuery({
        onError: (err) => addError(err, 'error') });

    const [createSwitchConfig] = useCreateSwitchConfigMutation({
        onError: (err) => addError(err, 'error'),
    });

    const [validateSwitchResult] = useValidateSwitchResultMutation({
        onError: (err) => addError(err, 'error'),
    });

    const loadSwitchConfigData = useCallback( async () => {
        let result = await getSwitchConfigByIdLazyQuery({variables: {id: selectedSwitch as number}});
        if (result.data?.switchConfigForm) {
            switchConfigFormToform(result.data, switchSetting);
        }
    }, [getSwitchConfigByIdLazyQuery, selectedSwitch]);

    const loadSwitchResultData = useCallback( async () => {
        let result = await getSwitchResult({variables: {guid: processId}});
        if (result.data?.switchResultByGuid) {
            setSwitchResult(result.data.switchResultByGuid);
        }
    }, [getSwitchResult, processId]);

    const setRefetchSimulationProcessTimeout = (delay: number) => {
        setTimeout(()=> {
            setCountProcess(countProcess + 1);
            refetchSimulationProcess();
        }, delay);
    }

    useEffect(() => {
        if (simpleSwitchesConfigsDataQuery) {
            console.log(simpleSwitchesConfigsDataQuery);
            setProcessId(simpleSwitchesConfigsDataQuery.simulateSwitch.processId);
            loadSwitchConfigData();
            setRefetchSimulationProcessTimeout(DELAY);
        }
    }, [simpleSwitchesConfigsDataQuery]);

    useEffect(() => {
        console.log(simulationProcessDataQuery);
        if (simulationProcessDataQuery && simulationProcessDataQuery.simulationProgress) {
            /*setSimulate(true);*/
            let progress = simulationProcessDataQuery.simulationProgress.processProgress;
            let status = simulationProcessDataQuery.simulationProgress.processStatus;
            console.log(progress, status);
            switch(status) {
                case ProcessStatus.Preparation:
                    setMessageProcess(t('simulate_switch.process_preparation'));
                    setRefetchSimulationProcessTimeout(DELAY);
                    break;
                case ProcessStatus.Waiting:
                    setMessageProcess(t('simulate_switch.process_waiting'));
                    setRefetchSimulationProcessTimeout(DELAY);
                    break;
                case ProcessStatus.Progress:
                    setMessageProcess(t('simulate_switch.process_progress'));
                    setRefetchSimulationProcessTimeout(DELAY);
                    break;
                case ProcessStatus.Done:
                    setMessageProcess(t('simulate_switch.process_done'));
                    setRefetchSimulationProcessTimeout(DELAY);
                    break;
                case ProcessStatus.PostDone:
                    setMessageProcess(t('simulate_switch.process_postdone'));
                    setRefetchSimulationProcessTimeout(DELAY);
                    break;
                case ProcessStatus.Finished:
                    setMessageProcess(t('simulate_switch.process_finished'));
                    loadSwitchResultData().then(() => {});
                    break;
                case ProcessStatus.Error:
                    setMessageProcess(t('simulate_switch.process_error'));
                    break;
                case ProcessStatus.Delete:
                    setMessageProcess(t('simulate_switch.process_unknow'));
                    break;
                case ProcessStatus.Unknown:
                    setMessageProcess(t('simulate_switch.process_unknow'));
                    break;
                default:
                    setMessageProcess(t('simulate_switch.process_other'));
            }
        }
    }, [simulationProcessDataQuery, countProcess]);

    const [isLevelProduct, setIsLevelProduct] = useState<boolean>(true);
    const [isLevelStore, setIsLevelStore] = useState<boolean>(false);
    const [isLevelItem, setIsLevelItem] = useState<boolean>(false);

    const [hasClearFilters, setHasClearFilters] = useState(false);

    const onCancel = () => {
        stopSimulationLazyQuery();
        setConfirmModalOpen(false);
        setProcessId(null);
        setCountProcess(0);
        stopSimulate();
    };

    const onEdit = () => {
        navigate(AllRoutes.SwitchCreate+'/'+switchResult?.config?.id);
    };

    const onValidate = async () => {
        const result = await validateSwitchResult({variables: {switchResultValidInput: {guid: processId, id: switchResult?.id}}});
        if (!result.errors && result.data?.validateSwitchResult) {
            toast.success('Switch validé', {
                position: toast.POSITION.TOP_CENTER,
                theme: 'colored'
            });
            navigate(AllRoutes.SwitchHistory, {state: {resultId: result.data.validateSwitchResult.id}});
        }
    };

    const onRecalculate = async () => {
        const result = await createSwitchConfig({variables: {switchConfigInput : currentSwitch}});
        if (!result.errors && result.data?.createSwitchConfig) {
            refetchSwitchesConfigsQuery({switchConfigId: result.data.createSwitchConfig.id});
        }
    };

    const onCheckItem = () => {
        setIsLevelItem(true);
        setIsLevelProduct(false);
        setIsLevelStore(false);
    }

    const onCheckProduct = () => {
        setIsLevelItem(false);
        setIsLevelProduct(true);
        setIsLevelStore(false);
    }

    const onCheckStore = () => {
        setIsLevelItem(false);
        setIsLevelProduct(false);
        setIsLevelStore(true);
    }

    const excludeProduct = (productId: number, exclude: boolean) => {
        let excludedProductIds = currentSwitch.productExcludesIds;
        if (excludedProductIds) {
            if (exclude) {
                excludedProductIds.push(productId);
                setCurrentSwitch({...currentSwitch, productExcludesIds: excludedProductIds});
            } else {
                let index = excludedProductIds.indexOf(productId);
                setCurrentSwitch({...currentSwitch, productExcludesIds: excludedProductIds?.splice(index, 1)});
            }
        }
    }

    const excludeSender = (storeId: number, exclude: boolean) => {
        let excludedSenderIds = currentSwitch.storeSenderExcludesIds;
        if (excludedSenderIds) {
            if (exclude) {
                excludedSenderIds.push(storeId);
                setCurrentSwitch({...currentSwitch, productExcludesIds: excludedSenderIds});
            } else {
                let index = excludedSenderIds.indexOf(storeId);
                setCurrentSwitch({...currentSwitch, productExcludesIds: excludedSenderIds?.splice(index, 1)});
            }
        }
    }

    const excludeRecipient = (storeId: number, exclude: boolean) => {
        let excludedRecipientIds = currentSwitch.storeRecipientExcludesIds;
        if (excludedRecipientIds) {
            if (exclude) {
                excludedRecipientIds.push(storeId);
                setCurrentSwitch({...currentSwitch, productExcludesIds: excludedRecipientIds});
            } else {
                let index = excludedRecipientIds.indexOf(storeId);
                setCurrentSwitch({...currentSwitch, productExcludesIds: excludedRecipientIds?.splice(index, 1)});
            }
        }
    }

    const onClearFilters = () => { return true};

    return (
        <BlocContent style={{flexGrow:1, padding: "10px 10px"}}>

        { switchResult && simulationProcessDataQuery?.simulationProgress?.processStatus == ProcessStatus.Finished && <>
            <FlexContainer style={{justifyContent: "space-between", marginBottom: 20}}>

                <Indicators transfer={true} textKey8='simulate_switch.carbon_footprint' switchName={selectedSwitchName} switchResult={switchResult}></Indicators>

                <FlexContainer style={{flexDirection: "column", marginRight: 20}}>
                    <StyledPrimaryButton style={{width: '100%'}} disabled={true} isactive={true as boolean} onClick={() => window.open(AllRoutes.SwitchCreate+'/'+switchResult?.config?.id,'_blank', )} key="visualise" textKey='simulate_switch.visualise_criteria' />
                    <StyledPrimaryButton style={{width: '100%'}} isactive={true as boolean} onClick={() => onEdit()} key="modify" textKey='simulate_switch.modify_criteria' />
                    <StyledPrimaryButton style={{width: '100%'}} isactive={true as boolean} onClick={() => onValidate()} key="validate" textKey='simulate_switch.validate_switch' />
                </FlexContainer>

            </FlexContainer>

            <FlexContainer style={{alignItems: "center", justifyContent: "space-between", marginBottom: 20}}>
                <ItemContainer>
                    <FlexContainer style={{alignItems: "center"}}>
                        <StyledButtonContainer>
                            <StyledPrimaryButton isactive={isLevelProduct} onClick={() => onCheckProduct()} key="checkProduct" textKey='simulate_switch.product_level'/>
                            <StyledPrimaryButton isactive={isLevelStore} onClick={() => onCheckStore()} key="checkStore" textKey='simulate_switch.Store_level' />
                            <StyledPrimaryButton isactive={isLevelItem} onClick={() => onCheckItem()} key="checkItem" textKey='simulate_switch.store_item_level'style={{marginRight: 0}}/>
                        </StyledButtonContainer>
                        <Tooltip title={t('simulate_switch.show_all')}><StyledFilterPrimaryButton iconPath={pictoFiltres} onClick={() => setHasClearFilters(!hasClearFilters)}/></Tooltip>
                    </FlexContainer>
                </ItemContainer>
                <ItemContainer>
                    <FlexContainer style={{justifyContent: "flex-end"}}>
                        <Tooltip title={t('simulate_switch.recalculate')}><StyledIconPrimaryButton icon={<RedoOutlined />} onClick={() => onRecalculate()} /></Tooltip>
                    </FlexContainer>
                </ItemContainer>
            </FlexContainer>

            <FlexContainerGrid>
                { isLevelItem && <GridItem rowData={switchResult?.switchResultArticleStores as SwitchResultArticleStoreRow[]} hasClearFilters={hasClearFilters}/>}
                { isLevelProduct && <GridProduct rowData={switchResult?.switchResultProducts as SwitchResultProductRow[]}
                                                 hasClearFilters={hasClearFilters}
                                                 onExcludeProduct={(productId, exclude) => excludeProduct(productId, exclude)}/>}
                { isLevelStore && <GridStore rowDataShip={switchResult?.switchResultStoreSenders as SwitchResultStoreSenderRow[]}
                                             rowDataRecip={switchResult?.switchResultStoreReceivers as SwitchResultStoreReceiverRow[]}
                                             hasClearFilters={hasClearFilters}
                                             onExcludeSender={(storeId, exclude) => excludeSender(storeId, exclude)}
                                             onExcludeRecipient={(storeId, exclude) => excludeRecipient(storeId, exclude)}/>}
            </FlexContainerGrid>
        </>}

        {simulationProcessDataQuery?.simulationProgress?.processStatus != ProcessStatus.Finished && <>
            <FlexContainer style={{marginTop: 50, flexDirection: 'column', justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                <StyledLabel style={{fontSize: "1.8em"}}>{messageProcess}</StyledLabel>
                <FlexContainer style={{flexDirection: 'row', alignItems: 'center'}}>
                    <StyledProgress percent={simulationProcessDataQuery?.simulationProgress?.processProgress} style={{width: 800}}/>
                    <Tooltip title={t('simulate_switch.cancel_simulation')}><StyledCancelIconPrimaryButton icon={<DeleteOutlined />} onClick={() => setConfirmModalOpen(true)} /></Tooltip>
                </FlexContainer>
                <ConfirmModal
                  customTitle={t('simulate_switch.cancel_confirmation')}
                  onOk={() => onCancel()}
                  open={confirmModalOpen}
                  showOnlyCloseBtn={false}
                  onCancel={() => setConfirmModalOpen(false)}
                />
            </FlexContainer>
        </>}

        </BlocContent>
    );
};

export default SwitchSimulation;