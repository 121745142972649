import React from 'react';

import { StyledModal } from 'components/Modals';
import {
  FlexContainer,
  StyledItemContainer,
  Title,
  TitleContainer
} from './styles';
import { useTranslation } from 'react-i18next';
import { Indicators } from 'components/UI';
import {SwitchConfig, SwitchResult} from 'services/graphql/main';

interface PropsCriteriasModal {
  customElement?: React.ReactNode;
  customTitle?: string;
  customTitleKey?: string;
  onCancel?: () => void;
  open: boolean;
  text?: string;
  textKey?: string;
  width?: number;
  height?: number;
  showOnlyCloseBtn?: boolean;
  originConfig?: SwitchConfig | undefined | null;
}

const CriteriasModal: React.FC<PropsCriteriasModal> = ({
    customElement,
    customTitle,
    customTitleKey,
    open,
    onCancel,
    width,
    height,
    showOnlyCloseBtn,
    originConfig
  }) => {

    const { t } = useTranslation('translation');

    return (
        <StyledModal
            width={!!width ? width : "900px"}
            centered
            closable={!showOnlyCloseBtn}
            open={open}
            zIndex={2000}
            onCancel={onCancel}
            okButtonProps={{style: {display: 'none'}}}
            cancelButtonProps={{style: {display: 'none'}}}
        >
          <FlexContainer>
            <TitleContainer>
              <Title>{'Synthèse des critères sélectionnés'}</Title>
            </TitleContainer>

            <StyledItemContainer>
              <Title>{originConfig?.name}</Title>
            </StyledItemContainer>



            {customElement && customElement}
          </FlexContainer>
        </StyledModal>
    )
  }

  export default CriteriasModal;