import React from 'react';

import { useTranslation } from 'react-i18next';

import { IndicatorContainer, IndicatorSubContainer, IndicatorSubIconContainer, StyledIcon, StyledLabelNumber, StyledLabelText } from './styles';
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
    number?: number,
    textKey: string,
    iconPath: string,
    theme: string
}

const Indicator: React.FC<Props> = ({number, textKey, iconPath, theme, ...rest}) => {
    const { t } = useTranslation('translation');

    return (
        <IndicatorContainer $theme={theme}>
            <IndicatorSubContainer $theme={theme}>
              { number === undefined && <StyledLabelNumber>-</StyledLabelNumber> }
              { number !== undefined && number >= 0 && <StyledLabelNumber>{number}</StyledLabelNumber> }
              { number === -1 && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> }
                <StyledLabelText>{t(textKey)}</StyledLabelText>
            </IndicatorSubContainer>
            <IndicatorSubIconContainer>
                <StyledIcon $iconPath={iconPath} />
            </IndicatorSubIconContainer>
        </IndicatorContainer>
    );
}

export default Indicator;