import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColDef } from 'ag-grid-community';

import { StyledModal } from 'components/Modals';
import { FooterContainer, GridContainer, Title, TitleContainer } from 'components/Modals/ExcludeModal/styles';
import { StyledFilterPrimaryButton } from 'components/Modals/StyledModal/styles';
import { SwitchContext } from 'context';
import Indicator from './Indicator';
import pictoBoutique from 'assets/images/picto-boutique.svg';
import { StoreRow } from 'types/store';
import pictoFiltres from 'assets/images/picto-désactiver-filtres.svg';
import pictoHistoriqueImport from "../../../../assets/images/picto-historique-import.svg";

type Props = {
    open: boolean,
    onCancel: () => void;
}

const ListStoreRecipientModal: React.FC<Props> = ({open, onCancel, ...rest}) => {

    const { t } = useTranslation('translation');

    const { countRecipient, currentStoreRecipientData, setCurrentStoreRecipientData, setCountRecipient, currentSwitch, setCurrentSwitch } = useContext(SwitchContext);

    const [excludes, setExcludes] = useState<number[]>([]);

    const [rows, setRows] = useState<StoreRow[]>([]);

    const gridRef = useRef<AgGridReact>(null);

    const [hasClearFilters, setHasClearFilters] = useState(false);

    useEffect(() => {
      setExcludes(currentSwitch.storeSenderExcludesIds as number[]);
      const rowsWithoutExclude = currentStoreRecipientData.filter(data => !data.exclude);
      setRows(rowsWithoutExclude);
    }, [currentSwitch, countRecipient, currentStoreRecipientData]);

    useEffect(() =>{
      if(gridRef && gridRef.current && gridRef.current.api) gridRef.current!.api.setFilterModel({});
    }, [hasClearFilters]);

    const colDefs: ColDef[] = useMemo( () => [
        {field: 'code', headerName: t('grid_exclude_store.code'), sortable: true, width: 100, filter: 'agTextColumnFilter', resizable: true},
        {field: 'name', headerName: t('grid_exclude_store.name'), sortable: true, width: 250, filter: 'agTextColumnFilter', resizable: true},
        {field: 'country', headerName: t('grid_exclude_store.country'), sortable: true, width: 150, filter: 'agTextColumnFilter', resizable: true},
        {field: 'region',headerName: t('grid_exclude_store.region'), sortable: true, width: 200, filter: 'agTextColumnFilter', resizable: true},
        {field: 'type', headerName: t('grid_exclude_store.type'), sortable: true, width: 100, filter: 'agTextColumnFilter', resizable: true},
        /*{field: 'exclude', headerName: t('footer.validate'), headerTooltip: t('footer.validate'), sortable: true, maxWidth: 120,
        cellRenderer: (row: any) =>
        <>
          <Switch
              checked={!(excludes.includes(row.data.id)) as boolean}
              onChange={(state) => {
                  row.data.exclude = !state;
                  const updateExcludes = excludes.filter( excludeId => (excludeId != row.data.id));
                  if (!updateExcludes.includes(row.data.id) && !state) updateExcludes.push(row.data.id);
                  setExcludes(updateExcludes);
            }}/>
        </>
      }*/
    ], [t, excludes]);

    const onApply = () => {
        setCurrentSwitch({...currentSwitch, storeSenderExcludesIds: excludes});
        const calculatedCount = (excludes) ? (currentStoreRecipientData.length - excludes.length) : 0;
        setCountRecipient(calculatedCount);
        // mettre le setCurrentStoreRecipientData actualisé
        onCancel();
    }

    const onCancelModal = () => {
        onCancel();
    }

    const onExport = () => {
      gridRef.current &&
      gridRef.current.api.exportDataAsExcel({
        fileName: currentSwitch.name + '_' + t('footer.recipients')
      });
    }

    return (
        <>
        <StyledModal
          width={870}
          centered
          closable={true}
          open={open}
          zIndex={2000}
          onCancel={onCancelModal}
          okButtonProps={{style: {display: 'none'}}}
          cancelButtonProps={{style: {display: 'none'}}}
          /*footer={
            <FooterContainer style={{justifyContent: "flex-end"}}>
                <StyledPrimaryButton onClick={onApply} key="save" text={t('footer.apply_changes')} />
            </FooterContainer>
          }*/
        >
          <TitleContainer style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
            <Title style={{marginRight: 50}}>{t('footer.list_store_recipient')}</Title>
            <Indicator number={countRecipient} textKey='footer.recipients' iconPath={pictoBoutique} theme='purple'/>
            <Tooltip title={t('simulate_switch.show_all')}><StyledFilterPrimaryButton style={{marginLeft: 10}} iconPath={pictoFiltres} onClick={() => setHasClearFilters(!hasClearFilters)}/></Tooltip>
            <Tooltip title={t('export.export')}><StyledFilterPrimaryButton style={{marginLeft: 10, marginRight: 10}} iconPath={pictoHistoriqueImport} onClick={onExport}/></Tooltip>
          </TitleContainer>
  
          <GridContainer gridWidth={1600} gridHeight={700}>
            <AgGridReact
              ref={gridRef}
              columnDefs={colDefs}
              rowData={rows}
              rowHeight={40}
              rowSelection='multiple'
            />
          </GridContainer>
  
        </StyledModal>
      </>
    );
}

export default ListStoreRecipientModal;