import React, { useRef, useState, useEffect, useMemo } from 'react';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import { COLUMN_TYPES } from 'constants/gridConstants';
import { composeTranslatedMessage } from 'helpers/translations';
import {GridContainer} from "./styles";

type Props = {
  errorLogs?: { args: string[]; code: string; field: string; lineIndex: number }[];
};

const ErrorLogsTable: React.FC<Props> = ({ errorLogs }) => {
  const { t } = useTranslation('translation');
  const gridRef = useRef<AgGridReact>(null);

  const [rowData, setRowData] = useState<{ args?: string[]; lineIndex: number; message: string; value: string }[]>([]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: false,
      resizable: true,
    };
  }, []);

  useEffect(() => {
    console.log(errorLogs);
    if (errorLogs?.length) {
      setRowData(
        errorLogs.map((log) => ({ lineIndex: log.lineIndex, message: log.code, value: log.field, args: log.args })),
      );
    } else {
      setRowData([]);
    }
  }, [errorLogs]);

  const columnDefs: ColDef[] = [
    {
      field: 'lineIndex',
      headerName: t('importSettings.line_index'),
    },
    {
      field: 'message',
      headerName: t('importSettings.error_message'),
      valueFormatter: (params) => {
        if (params.data.args?.length) {
          return composeTranslatedMessage(t(`errors.${params.value}`), params.data.args);
        } else {
          return t(`errors.${params.value}`);
        }
      },
    },
    {
      field: 'value',
      headerName: t('importSettings.value'),
    },
  ];

  return (
    <GridContainer gridHeight={450}>
      <AgGridReact
        /*containerStyle={{ width: '100%', marginTop: 5, marginBottom: 10, height: 525 }}*/
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        domLayout={errorLogs && errorLogs?.length >= 17 ? 'normal' : 'autoHeight'}
        suppressCellFocus={true}
        suppressRowVirtualisation={true}
        rowSelection={'single'}
        columnTypes={COLUMN_TYPES}
      />
    </GridContainer>
  );
};

export default ErrorLogsTable;
