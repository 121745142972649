import { Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: calc(100% - 72px);
  padding: calc(18px + 4.5em) 18px calc(18px + 4.5em) 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0px 20px;
`;

export const StyledCollapse = styled(Collapse)`
  background-color: ${(props) => props.theme.white};
`;

export const StyledCollapsePanel = styled(CollapsePanel)`

  padding: 0px 18px 0px 18px;

  .ant-collapse-header-text {
    color: ${(props) => props.theme.black};
    font-family: "NotoBold";
    font-size: 1.4em;
  }
`;


