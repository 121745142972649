import styled from 'styled-components';

export const IndicatorContainer =  styled.div<{$theme: string}>`
    width: 300px;
    height: 3.4em;
    border-radius: 10px;
    padding: 6px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-flow: center;
    align-items: center;
    ${(props) => {
        switch (props.$theme) {
            case "blue":
                return `background-color: ${props.theme.blue_background_indicator}`
            case "purple":
                return `background-color: ${props.theme.purple_background_indicator}`
            default:
                return `background-color: ${props.theme.white}`
        }
    }}
`;

export const IndicatorSubContainer = styled.div<{$theme: string}>`
    width: 207px;
    height: 2.5em;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex-flow: center;
    align-items: center;
    gap: 20px;
    ${(props) => {
        switch (props.$theme) {
            case "blue":
                return `background-color: ${props.theme.blue_foreground_indicator}`
            case "purple":
                return `background-color: ${props.theme.purple_foreground_indicator}`
            default:
                return `background-color: ${props.theme.white}`
        }
    }}
`;

export const IndicatorSubIconContainer = styled.div`
    width: 36px;
    height: 2.5em;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: center;
    align-items: center;
`;

export const StyledLabelNumber = styled.label`
    text-align: center;
    font-family: "NotoBold";
    font-size: 22pt;
    color: ${(props) => props.theme.black};
    padding-left: 10px;
`;

export const StyledLabelText = styled.label`
    width: 135px;
    font-family: "NotoMedium";
    font-size: 9pt;
    color: ${(props) => props.theme.black};
`;

export const StyledIcon = styled.i<{$iconPath: string}>`
    height: 18px;
    width: 18px;
    background-image:  ${(props) => `url(${props.$iconPath})`};
    background-size: contain;
`;