import styled from 'styled-components';

export const StyledLabelNumber = styled.label<{currency?: string}>`
    width: 50px;
    text-align: center;
    font-family: "NotoBold";
    font-size: 22pt;
    color: ${(props) => props.theme.black};

    :after {
        font-family: "NotoRegular";
        font-size: 9pt;
        position: relative;
        top: -14px;
        left: 5px;
        content: '${(props) => props.currency}';
    }
`;

export const StyledLabelText = styled.label`
    width: 130px;
    font-family: "NotoMedium";
    font-size: 9pt;
    color: ${(props) => props.theme.black};
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ItemContainer = styled.div`
    flex-grow: 0;
`;

interface GridContainerProps {
    gridWidth?: number,
    gridHeight?: number
}

export const GridContainer = styled.div.attrs({
    className: 'ag-theme-material'})<GridContainerProps>`

    height: 100%;
    width: 100%;

    border: lightgray solid 1px;
    border-radius: 18px;
    padding: 10px;

    .ant-switch-checked {
        background-color: ${(props) => props.theme.red} !important;
    }

    .ag-icon-filter {
        color: ${(props) => props.theme.red} !important;
    }

    .ag-cell-label-container {
        flex-direction: column-reverse;
        align-content: center;
        align-items: center;
    }

    .ag-header-cell-label {
        text-align: center;
        justify-content: center;
    }

    --ag-range-selection-border-style: none;
    --ag-range-selection-border-color: ${(props) => props.theme.green};
    --ag-selected-row-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-background-color: ${(props) => props.theme.light_green};
    --ag-range-selection-highlight-color: ${(props) => props.theme.light_green};
    --ag-material-accent-color: ${(props) => props.theme.green};
    --ag-data-color: ${(props) => props.theme.black};
    --ag-font-family: "NotoRegular";
    --ag-font-size: 1em;
  `;

