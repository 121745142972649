import styled from 'styled-components';
import { PrimaryButton } from 'components/UI';

export const StyledFooter = styled.div`
    position: fixed;
    top: calc(100vh - 4.5em);
    width: calc(100% - 72px);
    z-index: 1;
    margin-left: 72px;
    background-color: ${(props) => props.theme.more_light_blue_background} !important;
    height: 4.5em;
`;

export const StyledFooterWrapper = styled.div`
    max-width: 1800px;
    margin: 0 auto;
`;

export const FlexContainer =  styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 10px 10px;
`;

export const ItemContainer = styled.div`
    flex-grow: 0;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    font-family: "NotoMedium";
    font-size: 1.06em;
    border-radius: 50px;
    padding: 0.7em 2em;
    margin-right: 18px;
`;