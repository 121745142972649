import { ValueFormatterParams } from 'ag-grid-community';
import moment from "moment";

export const decimalNumberFormatter =
  (digits = 1) =>
  (params: ValueFormatterParams) => {
    return params.value.toFixed(digits);
  };

export const percentFormatter = (params: ValueFormatterParams) => {
  return params.value + ' %';
};

export const currencyFormatter = (params: ValueFormatterParams) => {
  return (
    !params.value ? '-' :
    (Math.round(params.value * 100)/100)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €'
  );
};

export const dateFormatter = (params: ValueFormatterParams) => {
  return moment(params.value).local().format('DD-MM-YYYY _ HH:mm');
;}
