export enum FileImportType {
  ProductColor = 'PRODUCT_COLOR',
  Store = 'STORE',
  StoreSeason = 'STORE_SEASON',
  IdealStock = 'IDEAL_STOCK',
}

export enum ProductColorImportType {
  ProductCode = 'PRODUCT_CODE',
  ColorCode = 'COLOR_CODE',
  SeasonCode = 'SEASON_CODE',
  GenericReference = 'GENERIC_REFERENCE',
  IsSync = 'IS_SYNC',
  CustomFieldValue_1 = 'CUSTOM_FIELD_VALUE_1',
  CustomFieldValue_2 = 'CUSTOM_FIELD_VALUE_2',
  CustomFieldValue_3 = 'CUSTOM_FIELD_VALUE_3',
  CustomFieldValue_4 = 'CUSTOM_FIELD_VALUE_4',
  CustomFieldValue_5 = 'CUSTOM_FIELD_VALUE_5',
  NewSeasonCode = 'NEW_SEASON_CODE',
}

export enum StoreImportType {
  StoreCode = 'STORE_CODE',
  UniverseCode = 'UNIVERSE_CODE',
  StoreType = 'STORE_TYPE',
  CustomFieldValue_1 = 'CUSTOM_FIELD_VALUE_1',
  CustomFieldValue_2 = 'CUSTOM_FIELD_VALUE_2',
  CustomFieldValue_3 = 'CUSTOM_FIELD_VALUE_3',
  CustomFieldValue_4 = 'CUSTOM_FIELD_VALUE_4',
  CustomFieldValue_5 = 'CUSTOM_FIELD_VALUE_5',
  IsActive = 'IS_ACTIVE'
}

export enum StoreSeasonImportType {
  StoreCode = 'STORE_CODE',
  UniverseCode = 'UNIVERSE_CODE',
  SeasonCode = 'SEASON_CODE',
  TargetTurnover = 'TARGET_TURNOVER',
  Capacity = 'CAPACITY'
}

export enum IdealStockImportType {
  Sku = 'SKU',
  StoreCode = 'STORE_CODE',
  StockIdeal = 'STOCK_IDEAL',
  Date = 'DATE'
}