import React from 'react';

import { useTranslation } from 'react-i18next';

import { FlexContainer, IndicatorContainer, IndicatorSubContainer, IndicatorSubIconContainer, ItemContainer, StyledIcon, StyledLabelNumber, StyledLabelText } from './styles';

type Props = {
    number1: number | undefined | null,
    number2: number | undefined | null,
    number3?: number | undefined | null,
    number4?: number | undefined | null,
    number5?: number | undefined | null,
    number6?: number | undefined | null,
    number7?: number | undefined | null,
    number8?: number | undefined | null,
    textKey1: string,
    textKey2: string,
    textKey3?: string,
    textKey4?: string,
    textKey5?: string,
    textKey6?: string,
    textKey7?: string,
    textKey8?: string,
    iconPath: string,
    theme: string,
    unit1?: string,
    unit2?: string
}

const Indicator: React.FC<Props> = ({
    number1,
    number2,
    number3,
    number4,
    number5,
    number6,
    number7,
    number8,
    textKey1,
    textKey2,
    textKey3,
    textKey4,
    textKey5,
    textKey6,
    textKey7,
    textKey8,
    iconPath,
    theme,
    unit1,
    unit2,
    ...rest}) => {

    const { t } = useTranslation('translation');

    return (
        <IndicatorContainer $theme={theme}>

            <IndicatorSubIconContainer>
                <StyledIcon $iconPath={iconPath} />
            </IndicatorSubIconContainer>

            <FlexContainer style={{gap: 10}}>

                <ItemContainer>
                    <FlexContainer style={{flexDirection: "column", gap: "6px"}}>
                        <IndicatorSubContainer $theme={theme} style={{flexDirection: "row", justifyContent: "flex-start", alignContent: "center", alignItems: "center", gap: 5}}>
                            <ItemContainer>
                                <StyledLabelNumber unit={unit1}>{number1?.toLocaleString()}</StyledLabelNumber>
                            </ItemContainer>
                            <ItemContainer>
                                <StyledLabelText>{t(textKey1)}</StyledLabelText>
                            </ItemContainer>
                        </IndicatorSubContainer>
                        <IndicatorSubContainer $theme={theme} style={{flexDirection: "row", justifyContent: "flex-start", alignContent: "center", alignItems: "center", gap: 5}}>
                            <ItemContainer>
                                <StyledLabelNumber unit={unit1}>{number2?.toLocaleString()}</StyledLabelNumber>
                            </ItemContainer>
                            <ItemContainer>
                                <StyledLabelText>{t(textKey2)}</StyledLabelText>
                            </ItemContainer>
                        </IndicatorSubContainer>
                    </FlexContainer>
                </ItemContainer>

                {textKey3 && textKey4 && <ItemContainer>
                    <FlexContainer style={{flexDirection: "column", gap: "6px"}}>
                        <IndicatorSubContainer $theme={theme} style={{flexDirection: "row", justifyContent: "flex-start", alignContent: "center", alignItems: "center", gap: 5}}>
                            <ItemContainer>
                                <StyledLabelNumber unit={unit1}>{number3?.toLocaleString()}</StyledLabelNumber>
                            </ItemContainer>
                            <ItemContainer>
                                <StyledLabelText>{t(textKey3)}</StyledLabelText>
                            </ItemContainer>
                        </IndicatorSubContainer>
                        <IndicatorSubContainer $theme={theme} style={{flexDirection: "row", justifyContent: "flex-start", alignContent: "center", alignItems: "center", gap: 5}}>
                            <ItemContainer>
                                <StyledLabelNumber unit={unit1}>{number4?.toLocaleString()}</StyledLabelNumber>
                            </ItemContainer>
                            <ItemContainer>
                                <StyledLabelText>{t(textKey4)}</StyledLabelText>
                            </ItemContainer>
                        </IndicatorSubContainer>
                    </FlexContainer>
                </ItemContainer>}

                {textKey5 && textKey6 && textKey7 && <ItemContainer>
                    <IndicatorSubContainer $theme={theme} style={{flexDirection: "column", height: 80, gap: 5}}>
                        <FlexContainer style={{flexDirection: "row", alignContent: "center", alignItems: "center"}}>
                            <ItemContainer>
                                <StyledLabelNumber unit={unit1}>{number5?.toLocaleString()}</StyledLabelNumber>
                            </ItemContainer>
                            <ItemContainer>
                                <StyledLabelText >{t(textKey5)}</StyledLabelText>
                            </ItemContainer>
                        </FlexContainer>
                        <FlexContainer style={{flexDirection: "row", justifyContent: "center", alignContent: "center", alignItems: "center", gap: 5}}>
                            <ItemContainer>
                                <FlexContainer style={{flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                    <ItemContainer>
                                        <StyledLabelNumber unit={unit1} style={{fontSize: "10pt"}}>{number6?.toLocaleString()}</StyledLabelNumber>
                                    </ItemContainer>
                                    <ItemContainer>
                                        <StyledLabelText style={{marginLeft: 10, marginRight:10}}>{t(textKey6)}</StyledLabelText>
                                    </ItemContainer>
                                </FlexContainer>
                            </ItemContainer>
                            <ItemContainer style={{height: 40, borderRight: "1px solid lightgray"}}></ItemContainer>
                            <ItemContainer>
                                <FlexContainer style={{flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                    <ItemContainer>
                                        <StyledLabelNumber unit={unit1} style={{fontSize: "10pt"}}>{number7?.toLocaleString()}</StyledLabelNumber>
                                    </ItemContainer>
                                    <ItemContainer>
                                        <StyledLabelText style={{marginLeft: 10, marginRight:10}}>{t(textKey7)}</StyledLabelText>
                                    </ItemContainer>
                                </FlexContainer>
                            </ItemContainer>
                        </FlexContainer>
                    </IndicatorSubContainer>
                </ItemContainer>}

                {textKey8 && <ItemContainer>
                    <IndicatorSubContainer $theme={theme} style={{flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", height: 80}}>
                        <ItemContainer>
                            <StyledLabelText style={{marginLeft: 10, marginRight:10}}>{t(textKey8)}</StyledLabelText>
                        </ItemContainer>
                        <ItemContainer>
                            <StyledLabelNumber unit={unit2}>{number8?.toLocaleString()}</StyledLabelNumber>
                        </ItemContainer>
                    </IndicatorSubContainer>
                </ItemContainer>}

            </FlexContainer>

        </IndicatorContainer>
    );
}

export default Indicator;