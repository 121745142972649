import styled from 'styled-components';

export const StyledTitle = styled.h5`
  color: ${(props) => props.theme.black};
  font-family: "NotoBold";
  font-size: 1.4em;
  margin-bottom: 20px;
`;

export const PageWrapper = styled.div`
  //position: fixed;
  margin-top: 4.5em;
  width: calc(100% - 72px);
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 0px 20px;
`;

export const FlexContainer =  styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 0px;
`;

export const BlurOverlay = styled.div`
  filter: blur(0.5rem);
  pointer-events: none;
`;