import { WarningOutlined } from '@ant-design/icons';
import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const TitleContainer = styled.h1`
  text-align: center;
  font-family: "NotoBold";
  color: ${(p) => p.theme.black};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

export const TextContainer = styled.p`
  text-align: center;
  margin-bottom: 0px;
  color: ${(p) => p.theme.black};
`;

export const StyledWarningOutlined = styled(WarningOutlined)`
  svg {
    color: ${(p) => p.theme.primary_yellow};
    height: 130px;
    width: 130px;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  font-family: "NotoMedium";
  font-size: 1.17em;
  border-radius: 50px;
  margin-left: 0px !important;
`;

export const StyledCancelPrimaryButton = styled(PrimaryButton)`
  font-family: "NotoMedium";
  font-size: 1.17em;
  border-radius: 50px;
  background-color: ${(p) => p.theme.white};
  border-color: ${(p) => p.theme.black};
  margin-left: 0px !important;
  :hover {
    background-color: ${(p) => p.theme.black};
    border-color: ${(p) => p.theme.black};
    color: ${(p) => p.theme.white};
  }
  :focus {
    background-color: ${(p) => p.theme.black};
    border-color: ${(p) => p.theme.black};
    color: ${(p) => p.theme.white};
  }
`;