import React, {useCallback, useContext, useEffect} from 'react';

import { Outlet } from 'react-router-dom';

import { MenuSider } from '../Siders';
import { PageWrapper, StyledLayout } from './styles';
import { TabsSwitch } from '../TabsSwitch'
import { Footer } from '../Footer'
import { SwitchContextProvider, SwitchSettingContext, SwitchSettingContextProvider } from 'context';
import {
  TenantSettings,
  useGetSwitchSettingLazyQuery,
  useGetSwitchSettingQuery,
  useGetTenantSettingsQuery
} from 'services/graphql/main';
import { useError } from 'services/utils';

type Props = {
  children?: React.ReactNode;
  hasMenuSider: boolean;
  hasTabs: boolean;
  hasFooter: boolean;
  noPadding: boolean;
};

const MainLayout = (props: Props) => {

  return (
    <>
      <SwitchContextProvider>
        <StyledLayout>
          {props.hasMenuSider && <MenuSider />}
          {props.hasTabs && <TabsSwitch />}
          <PageWrapper noPadding={props.noPadding}>
            {props.children ? props.children : <Outlet />}
          </PageWrapper>
          {props.hasFooter && <Footer />}
        </StyledLayout>
      </SwitchContextProvider>
    </>
  );
};

export default MainLayout;
