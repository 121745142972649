import React, { useEffect, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import StyledModal from '../StyledModal/StyledModal';
import { TitleContainer, Title, GridContainer, FooterContainer } from './styles';
import { ColDef, GetRowIdParams, SelectionChangedEvent } from 'ag-grid-community';
import { StyledCancelPrimaryButton, StyledPrimaryButton } from '../StyledModal/styles';

interface PropsExcludeModal {
  customElement?: React.ReactNode;
  customTitle?: string;
  customTitleKey?: string;
  onCancel?: () => void;
  onSelect: (data:any) => void;
  open: boolean;
  text?: string;
  textKey?: string;
  colDefs: ColDef[];
  rowData: unknown[];
  width?: string | number;
  height?: number;
  showOnlyCloseBtn?: boolean;
  selectedIds?: number[];
  getRowId: (params: GetRowIdParams) => string;
}

const ExcludeModal: React.FC<PropsExcludeModal> = ({
    customElement,
    customTitle,
    customTitleKey,
    open,
    onCancel,
    onSelect,
    colDefs,
    rowData,
    width,
    height,
    showOnlyCloseBtn = false,
    selectedIds,
    getRowId
  }) => {

    const [selectedRows, setSelectedRows] = useState<unknown[] | null>(null);

    const gridRef = useRef<AgGridReact>(null);

    const { t } = useTranslation('translation');

    const onSelectionChanged = (event: SelectionChangedEvent) => {
      if (event.source == 'rowClicked' || event.source == 'uiSelectAll' || event.source == 'checkboxSelected') {
        const rows = gridRef.current!.api.getSelectedRows();
        setSelectedRows(rows);
        !showOnlyCloseBtn && onSelect(rows);
      }
    };

    const onCancelSelection = () => {
      setSelectedRows(null);
      gridRef.current!.api.clearRangeSelection();
      onCancel && onCancel();
    }

    const onConfirmSelection = () => {
      onSelect(selectedRows);
      onCancel && onCancel();
    }

    const setSelected = () => {
      gridRef.current!.api.forEachNode(node => {
        node.setSelected(!!node.data.exclude);
      });
    }
  
  return (
    <>
      <StyledModal
        width={!!width ? width : "900px"}
        centered
        closable={!showOnlyCloseBtn}
        open={open}
        zIndex={2000}
        onCancel={onCancel}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        footer={
          showOnlyCloseBtn && <FooterContainer>
                        <StyledCancelPrimaryButton onClick={onCancelSelection} key="cancel" text={t('exclude_modal.button_cancel')} />
                        <StyledPrimaryButton onClick={onConfirmSelection} key="save" text={t('exclude_modal.button_confirm')} />
                    </FooterContainer>
        }
      >
        <TitleContainer>
          <Title>{customTitleKey ? t(customTitleKey) : customTitle}</Title>
        </TitleContainer>

        <GridContainer gridWidth={width} gridHeight={height}>
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={rowData}
            rowHeight={40}
            onSelectionChanged={(event)=>{onSelectionChanged(event)}}
            onRowDataUpdated={setSelected}
            rowSelection='multiple'
            rowMultiSelectWithClick={true}
            onGridReady={setSelected}
            getRowId={getRowId}
          />
        </GridContainer>

        {customElement && customElement}
      </StyledModal>
    </>
  );
};

export default ExcludeModal;
