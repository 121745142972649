import { InputNumber, Radio, Switch } from 'antd';
import { PrimaryButton } from 'components/UI';
import styled from 'styled-components';

export const StyledTitle = styled.h1`
  color: ${(props) => props.theme.white};
  font-size: 28px;
  font-family: "NotoBold";
  margin: 0px 0px 20px 20px;
`;

export const PageWrapper = styled.div`
  width: calc(100% - 80px);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0px 20px;
`;

export const FlexContainer =  styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemContainer = styled.div`
  flex-grow: 0;

  .ant-radio-wrapper {
    color: ${(props) => props.theme.black};
    font-family: "NotoRegular";
    font-size: 16px;
    margin: 0 90px 0 0;
  }

  .ant-radio {
    top: .1em;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.green};
    border-width: 4px;
    width: 18px;
    height: 18px;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 0;
  }

  span.ant-radio-inner::after {
    background-color: ${(props) => props.theme.white};
  }
`;

export const StyledSettingTitle =  styled.h2`
  color: ${(props) => props.theme.black};
  font-size: 20px;
  font-family: "NotoBold";
`;

export const BlocSettings = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.light_soft_gray};
  padding: 30px 40px 30px 40px;
`;

export const StyledLabel = styled.h5`
    color: ${(props) => props.theme.black};
    font-family: "NotoMedium";
    font-size: 1.17em;
`;

export const ContainerSwitchButtons = styled.div`
  flex-grow: 0;
  border: 1px solid ${(props) => props.theme.light_soft_gray};
  border-radius: 20px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  font-family: "NotoMedium";
  font-size: 1.06em;
  border-radius: 45px;
  padding: 0.5em 8em;

  i {
    position: absolute;
    right: 2.5em;
    top: 0.7em;
    height: 20px;
  }
`

export const StyledInputNumber = styled(InputNumber)`
  border: 0;
  border-bottom: ${(props) => props.theme.light_soft_gray} solid 1px;
  box-shadow: none !important;
  color: ${(props) => props.theme.black};
  font-family: "NotoRegular";
  font-size: 1.06em;

  :hover {
    border-color: ${(props) => props.theme.green};
  }

  .ant-input-number-handler-wrap {
    opacity: 1;
    border: 0 !important;

    svg {
      color: ${(props) => props.theme.black};
      width: 11px;
      height: 11px;
    }
  }

  .ant-input-number-group,
  .ant-input-number,
  .ant-input-number-input,
  .ant-input-number-handler-wrap
  .ant-input-number-handler-up,
  .ant-input-number-handler-down,
  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    border: none;
  }
`;

export const StyledInputPlaceholderInner = styled.div`
    position: absolute;
    font-size: 0.9em;
    right: 30px;
    top: 5px;
    pointer-events: none;
    opacity: 0.4;
    z-index: 1;
`;

export const StyledRadio = styled(Radio)`
  width: 250px;
  border-right: solid 1px lightgray;
  margin-right: 18px !important;
  font-size: 15px !important;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  border: ${(props) => props.theme.light_soft_gray} solid 1px;
  padding: 9px;
  border-radius: 9px;
  width: 830px;
`;