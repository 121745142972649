import React from 'react';

import { ModalProps } from 'antd/lib/modal';

import { StyledAntdModal } from './styles';

const StyledModal: React.FC<ModalProps> = ({ children, ...rest }) => {
  return <StyledAntdModal {...rest}>{children}</StyledAntdModal>;
};

export default StyledModal;
