import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  FlexContainer,
  GridContainer,
  ItemContainer,
  StyledLabel,
  StyledPrimaryButton,
  StyledSelect,
  StyledVisibleLabel
} from "./styles";
import { CaretDownOutlined } from "@ant-design/icons";
import defaultTheme from "theme";
import { ReactComponent as RequireSvg } from 'assets/images/Picto-champ-oligatoire.svg';
import { ExcludeModal } from "components/Modals";
import { 
  GetStoresQuery, 
  AddressRegion, 
  useGetRegionsQuery, 
  useGetStoresQuery, 
  useGetCountriesQuery, 
  Country, 
  useGetStoreTypesQuery, 
  StoreType} from "services/graphql/main"
import { useError } from "services/utils";
import { StoreRow } from "types/store";
import {SwitchContext, SwitchSettingContext} from "context";
import pictoReglageActif from "assets/images/picto-reglage.svg";
import { BaseOptionType } from "antd/lib/select";
import { ColDef } from "ag-grid-community";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {CustomTagProps} from "rc-select/lib/BaseSelect";
import {Tooltip} from "antd";

type Props = {
  children?: React.ReactNode;
  onMagasin: (isOpenProduct: boolean) => void;
  isCriteriaRegion?: boolean | null;
  isCriteriaType?: boolean | null;
}

const defaultValue: string = '';

const Magasin: React.FC<Props> = ({children, onMagasin, isCriteriaRegion, isCriteriaType}) => {

    const { t } = useTranslation('translation');
    const { addError } = useError();

 
    const { currentStoreRecipientData, 
            setCurrentStoreRecipientData,
            currentStoreShipperData,
            setCurrentStoreShipperData,
            setCountShipping,
            setCountRecipient,
            clearCounts,
            currentSwitch,
            setCurrentSwitch } = useContext(SwitchContext);

    const { switchSetting, tenantSettings } = useContext(SwitchSettingContext);

    const [countSelectedExcludeShipping, setCountSelectedExcludeShipping] = useState<number | 0>(0);
    const [countSelectedExcludeRecipient, setCountSelectedExcludeRecipient] = useState<number | 0>(0);

  const formatCustomFieldOptions = (customFieldValues: string) => {
    const nullOption = {value: 'NULL', label: 'Non renseigné'};
    const formatedOptions = JSON.parse(customFieldValues).map((v: any) => ({value: v.Code, label: v.Label}));
    return [nullOption, ...formatedOptions];
  }

  const optsCustomField1 = tenantSettings && tenantSettings.storeCustomFieldValues1 ? formatCustomFieldOptions(tenantSettings.storeCustomFieldValues1) : [];
  const optsCustomField2 = tenantSettings && tenantSettings.storeCustomFieldValues2 ? formatCustomFieldOptions(tenantSettings.storeCustomFieldValues2) : [];
  const optsCustomField3 = tenantSettings && tenantSettings.storeCustomFieldValues3 ? formatCustomFieldOptions(tenantSettings.storeCustomFieldValues3) : [];
  const optsCustomField4 = tenantSettings && tenantSettings.storeCustomFieldValues4 ? formatCustomFieldOptions(tenantSettings.storeCustomFieldValues4) : [];
  const optsCustomField5 = tenantSettings && tenantSettings.storeCustomFieldValues5 ? formatCustomFieldOptions(tenantSettings.storeCustomFieldValues5) : [];

    const [isOpenProduct, setIsOpenProduct] = useState(false);

    const {
      loading: loadingCountriesDataQuery,
      data: countriesDataQuery,
      refetch: refetchCountriesQuery,
    } = useGetCountriesQuery({ onError: (err) => addError(err, 'error') });

    const {
      loading: loadingRegionsDataQuery,
      data: regionsDataQuery,
      refetch: refetchRegionsQuery,
    } = useGetRegionsQuery({
      variables: {countries: currentSwitch.storeCountriesIds as number[]},
      onError: (err) => addError(err, 'error') });

    const {
      loading: loadingStoreTypesDataQuery,
      data: storeTypesDataQuery,
      refetch: refetchStoreTypesQuery,
    } = useGetStoreTypesQuery({
      variables: {universeId: currentSwitch.universeId, countries: currentSwitch.storeCountriesIds as number[], regions: currentSwitch.storeRegionsIds as number[]},
      onError: (err) => addError(err, 'error') });

    const {
      loading: loadingStoresDataQuery,
      data: storesDataQuery,
      refetch: refetchStoresQuery,
    } = useGetStoresQuery({ 
      variables: {
        universeId: currentSwitch.universeId,
        countries: currentSwitch.storeCountriesIds as number[],
        regions: currentSwitch.storeRegionsIds as number[],
        types: currentSwitch.storeTypesIds as number[],
        storeCustomField1Codes: currentSwitch.storeCustomField1ValueCodes as string[],
        storeCustomField2Codes: currentSwitch.storeCustomField2ValueCodes as string[],
        storeCustomField3Codes: currentSwitch.storeCustomField3ValueCodes as string[],
        storeCustomField4Codes: currentSwitch.storeCustomField4ValueCodes as string[],
        storeCustomField5Codes: currentSwitch.storeCustomField5ValueCodes as string[]
      },
      onError: (err) => {
        addError(err, 'error');
        setCountShipping(0);
        setCountRecipient(0);
        setCurrentStoreShipperData([]);
        setCurrentStoreRecipientData([]);
      } });

      // const {
      //   loading: loadingStoresRecipientDataQuery,
      //   data: storesRecipientDataQuery,
      //   refetch: refetchStoresRecipientQuery,
      // } = useGetStoresQuery({
      //   variables: {universeId: currentSwitch.universeId, countries: currentSwitch.storeCountriesIds as number[], regions: currentSwitch.storeRegionsIds as number[], types: currentSwitch.storeTypesIds as number[], perfFlowRateSign: currentSwitch.perfDestFlowRateSign, perfFlowRatePercent: currentSwitch.perfDestFlowRatePercent, perfQtyPeriodSaleSign: currentSwitch.perfDestQtyPeriodSaleSign, perfQtyPeriodSale: currentSwitch.perfDestQtyPeriodSale},
      //   onError: (err) => addError(err, 'error') });

    const [regions, setRegions] = useState<(AddressRegion | null)[] | null | undefined>();
    const [countries, setCountries] = useState<(Country | null)[] | null | undefined>();
    const [types, setTypes] = useState<(StoreType | null)[] | null | undefined>();

    const [excludeShippersModalOpen, setExcludeShippersModalOpen] = useState<boolean>(false);
    const [excludeRecipientsModalOpen, setExcludeRecipientsModalOpen] = useState<boolean>(false);

    const getRowsStoresDataQuery = useCallback( (rowsRawData: GetStoresQuery | undefined, excludes: number[]) => {
      const rows  = rowsRawData?.stores?.map((store) => {
        return {
          exclude: excludes?.includes(store.id),
          code: store.code,
          name: store.name,
          country: store.address?.country?.name,
          region: store.address?.addressRegion?.name,
          type: store.storeType?.name,
          ...store
        }
      });
      return rows;
    }, []);

    useEffect(() => {
      if (loadingStoresDataQuery) {
        setCountShipping(-1);
        setCountRecipient(-1);
      }
    }, [loadingStoresDataQuery]);

    useEffect(() => {
      if (countriesDataQuery) { 
        setCountries(countriesDataQuery.countries);
      }
      if (regionsDataQuery) { 
        setRegions(regionsDataQuery.regions);
      }
      if (storeTypesDataQuery) { 
        setTypes(storeTypesDataQuery.storeTypes);
      }
      if (storesDataQuery) {
        const rowsShipper = getRowsStoresDataQuery(storesDataQuery, currentSwitch.storeSenderExcludesIds as number[]);
        if (rowsShipper) {
          setCurrentStoreShipperData(rowsShipper as StoreRow[]);
          setCountShipping(rowsShipper.length - (currentSwitch.storeSenderExcludesIds ? currentSwitch.storeSenderExcludesIds.length as number : 0));
          setCountSelectedExcludeShipping((currentSwitch.storeSenderExcludesIds ? currentSwitch.storeSenderExcludesIds.length as number : 0));
        }
        const rowsRecipient = getRowsStoresDataQuery(storesDataQuery, currentSwitch.storeRecipientExcludesIds as number[]);
        if (rowsRecipient) {
          setCurrentStoreRecipientData(rowsRecipient as StoreRow[]);
          setCountRecipient(rowsRecipient.length - (currentSwitch.storeRecipientExcludesIds ? currentSwitch.storeRecipientExcludesIds.length as number : 0));
          setCountSelectedExcludeRecipient((currentSwitch.storeRecipientExcludesIds ? currentSwitch.storeRecipientExcludesIds.length as number : 0));
        }
      }
      if (currentSwitch.storeCountriesIds && currentSwitch.storeCountriesIds.length > 0) onMagasin(true);
    }, [countriesDataQuery, regionsDataQuery, storeTypesDataQuery, storesDataQuery]);

    const onClickParamsExcludeShippers = () => {
      setExcludeShippersModalOpen(true);
    };

    const onClickParamsExcludeRecipients = () => {
        setExcludeRecipientsModalOpen(true);
    };

    const onChangeSelectCountry = (value: unknown) => {
      setCurrentSwitch({...currentSwitch, storeCountriesIds: value as number[], storeRegionsIds: [], storeTypesIds: [], storeSenderExcludesIds: [], storeRecipientExcludesIds: []});
      setCurrentStoreShipperData([]);
      setCurrentStoreRecipientData([]);
      setCountSelectedExcludeShipping(0);
      setCountSelectedExcludeRecipient(0);
      // clearCounts();
      // setIsOpenProduct(true);
      // onMagasin(true);
    };

    const onChangeSelectRegion = (value: unknown) => {
      setCurrentSwitch({...currentSwitch, storeRegionsIds: value as number[], storeTypesIds: [], storeSenderExcludesIds: [], storeRecipientExcludesIds: []});
      setCountSelectedExcludeShipping(0);
      setCountSelectedExcludeRecipient(0);
      setCurrentStoreShipperData([]);
      setCurrentStoreRecipientData([]);
      // clearCounts();
      // setIsOpenProduct(true);
      // onMagasin(true);
    };

    const onChangeSelectType = (value: unknown) => {
      setCurrentSwitch({...currentSwitch, storeTypesIds: value as number[], storeSenderExcludesIds: [], storeRecipientExcludesIds: []});
      setCountSelectedExcludeShipping(0);
      setCountSelectedExcludeRecipient(0);
      setCurrentStoreShipperData([]);
      setCurrentStoreRecipientData([]);
      // clearCounts();
      // setIsOpenProduct(true);
      // onMagasin(true);
    };

    const onSelectExcludeShippers = (value: StoreRow[]) => {
      setCountSelectedExcludeShipping(value.length);
      const calculatedCount = (currentStoreShipperData) ? (currentStoreShipperData.length - value.length) : 0;
      setCountShipping(calculatedCount);
      let ids = value.map( v => v.id);
      setCurrentSwitch({...currentSwitch, storeSenderExcludesIds: ids as number[]});
      const rowsShipper = getRowsStoresDataQuery(storesDataQuery, ids);
      console.log(rowsShipper);
      setCurrentStoreShipperData(rowsShipper as StoreRow[]);
    }

    const onSelectExcludeRecipients = (value: StoreRow[]) => {
      setCountSelectedExcludeRecipient(value.length);
      const calculatedCount = (currentStoreRecipientData) ? (currentStoreRecipientData.length - value.length) : 0;
      setCountRecipient(calculatedCount);
      let ids = value.map( v => v.id);
      setCurrentSwitch({...currentSwitch, storeRecipientExcludesIds: ids as number[]});
      const rowsRecipient = getRowsStoresDataQuery(storesDataQuery, ids);
      setCurrentStoreRecipientData(rowsRecipient as StoreRow[]);
    }

    // Filter `option.label` match the user type `input`
    const filterOption = (input: string, option?: BaseOptionType) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const customTagRender = (props: CustomTagProps, selected?: any[] | null, options?: any[] | null, valueKey: string = 'value', labelKey: string = 'label') => {
    const { value, label } = props;
    if (selected && selected.length > 1 && selected.indexOf(value) === selected.length - 1) {
      let selectedLabels = '';
      if (options && valueKey && labelKey) {
        selectedLabels = selected.map((item) => options?.find((option) => option && option[valueKey] === item)[labelKey]).join(', ');
      }
      return (
        <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} placement="top" title={selectedLabels} trigger={'hover'}>
          {t('create_switch.many_elements')}
        </Tooltip>
      );
    }
    if (selected && selected.length > 1) {
      return <></>;
    }
    return (
      <>
        {label}
      </>
    );
  };

    const colDefs: ColDef[] = useMemo( () => [
      {
        field: 'exclude',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMenu: true,
        resizable: true,
        headerName: "",
        sortable: true,
        maxWidth: 120,
        valueFormatter: (params) => {
          return "";
          // if (params.data.exclude) {
          //   return t('grid_exclude_store.exception');
          // } else {
          //   return "";
          // }
        },
      },
      {field: 'code', headerName: t('grid_exclude_store.code'), sortable: true, width: 100, filter: 'agTextColumnFilter', resizable: true},
      {field: 'name', headerName: t('grid_exclude_store.name'), sortable: true, width: 200, filter: 'agTextColumnFilter', resizable: true},
      {field: 'country', headerName: t('grid_exclude_store.country'), sortable: true, width: 120, filter: 'agTextColumnFilter', resizable: true},
      {field: 'region',headerName: t('grid_exclude_store.region'), sortable: true, width: 180, filter: 'agTextColumnFilter', resizable: true},
      {field: 'type', headerName: t('grid_exclude_store.type'), sortable: true, width: 100, filter: 'agTextColumnFilter', resizable: true},
    ], [t]);

    return (
      <FlexContainer style={{flexDirection: "row", flexWrap: "wrap", marginTop: 30}}>

      <ItemContainer style={{marginBottom: 30}}>
        <FlexContainer style={{flexDirection: "column", width: 850}}>

              <ItemContainer style={{marginBottom: 20}}>
                <FlexContainer style={{flexDirection: "row", alignItems: "flex-end"}}>
                    <StyledLabel style={{width: 250, marginLeft: 20, marginBottom: 15}}>{t("create_switch.zone")}</StyledLabel>

                    <ItemContainer>
                      <FlexContainer style={{flexDirection: "row", gap: 0}}>
                        <ItemContainer style={{paddingTop: 25}}>
                          <RequireSvg style={{width: 20}}/>
                        </ItemContainer>
                        <ItemContainer>
                          <StyledVisibleLabel visibility={!(currentSwitch.storeCountriesIds && currentSwitch.storeCountriesIds.length > 0)}>{t("create_switch.country")}</StyledVisibleLabel>
                          <StyledSelect
                            getPopupContainer={(trigger)=> trigger.parentNode}
                            style={{ color: 'black', width: 230}}
                            suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                            bordered={false}
                            value={currentSwitch.storeCountriesIds}
                            options={ countries?.map((country) => ({value: country?.id, label: country?.name})) }
                            placeholder={t("create_switch.country")}
                            mode="multiple"
                            showArrow={true}
                            onChange={onChangeSelectCountry}
                            filterOption={filterOption}
                          />
                        </ItemContainer>
                      </FlexContainer>
                    </ItemContainer>
                    <ItemContainer visibility={!(isCriteriaRegion ? isCriteriaRegion : false)} style={{marginBottom: 10}}>
                        <StyledVisibleLabel visibility={!(currentSwitch.storeRegionsIds && currentSwitch.storeRegionsIds.length > 0)}>{t("create_switch.region")}</StyledVisibleLabel>
                        <StyledSelect
                          getPopupContainer={(trigger)=> trigger.parentNode}
                          disabled={!(switchSetting.storeRegionEnabled && switchSetting.storeRegionEnabled)}
                          style={{ color: 'black', width: 250}}
                          suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                          bordered={false}
                          value={currentSwitch.storeRegionsIds}
                          options={ regions?.map((region) => ({value: region?.id, label: region?.name + '(' + region?.country?.name + ')'})) }
                          placeholder={t("create_switch.region")}
                          mode="multiple"
                          showArrow={true}
                          onChange={onChangeSelectRegion}
                          filterOption={filterOption}
                          allowClear
                          tagRender={(props) => customTagRender(props, currentSwitch.storeRegionsIds, regions, 'id', 'name')}
                        />
                    </ItemContainer>
                </FlexContainer>
              </ItemContainer>

              <ItemContainer visibility={!(isCriteriaType ? isCriteriaType : false)}>
                <FlexContainer style={{flexDirection: "column"}}>
                    <StyledLabel style={{marginLeft: 20}}>{t("create_switch.warehouse_type")}</StyledLabel>
                    <ItemContainer visibility={!(isCriteriaType ? isCriteriaType : false)}>
                      <StyledVisibleLabel visibility={!(currentSwitch.storeTypesIds && currentSwitch.storeTypesIds.length > 0)}>{t("create_switch.type")}</StyledVisibleLabel>
                      <StyledSelect
                        getPopupContainer={(trigger)=> trigger.parentNode}
                        disabled={!(switchSetting.storeTypeEnabled && switchSetting.storeTypeEnabled)}
                        style={{ color: 'black', width: 450, marginLeft: 50}}
                        suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                        bordered={false}
                        value={currentSwitch.storeTypesIds}
                        options={ types?.map((type) => ({value: type?.id, label: type?.name})) }
                        placeholder={t("create_switch.type")}
                        mode="multiple"
                        showArrow={true}
                        onChange={onChangeSelectType}
                        filterOption={filterOption}
                        // listHeight={450}
                      />
                    </ItemContainer>

                </FlexContainer>
              </ItemContainer>

          {(switchSetting.storeCustomFieldValues1Enabled || switchSetting.storeCustomFieldValues2Enabled || switchSetting.storeCustomFieldValues3Enabled || switchSetting.storeCustomFieldValues4Enabled || switchSetting.storeCustomFieldValues5Enabled) && <>
            <ItemContainer style={{marginTop: 15, marginLeft: 20}}>
              <StyledLabel>{t("create_switch.custom_fields")}</StyledLabel>
              <GridContainer style={{width: 850}}>
                {tenantSettings && tenantSettings.storeCustomFieldName1 && tenantSettings.storeCustomFieldValues1 && switchSetting.storeCustomFieldValues1Enabled && switchSetting.storeCustomFieldValues1Enabled && <>
                <ItemContainer>
                  <FlexContainer style={{flexDirection: "column"}}>
                    <StyledVisibleLabel visibility={!(currentSwitch.storeCustomField1ValueCodes && currentSwitch.storeCustomField1ValueCodes.length > 0)}>{tenantSettings.storeCustomFieldName1}</StyledVisibleLabel>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      value={currentSwitch.storeCustomField1ValueCodes}
                      onChange={(value) => { setCurrentSwitch({...currentSwitch, storeCustomField1ValueCodes: value as string[], storeSenderExcludesIds: [], storeRecipientExcludesIds: []}); }}
                      style={{width: 230}}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      bordered={false}
                      options={optsCustomField1}
                      placeholder={tenantSettings.storeCustomFieldName1}
                      mode="multiple"
                      filterOption={filterOption}
                      allowClear
                      tagRender={(props) => customTagRender(props, currentSwitch.storeCustomField1ValueCodes, optsCustomField1)}
                    />
                  </FlexContainer>
                </ItemContainer>
              </> }
              {tenantSettings && tenantSettings.storeCustomFieldName2 && tenantSettings.storeCustomFieldValues2 && switchSetting.storeCustomFieldValues2Enabled && switchSetting.storeCustomFieldValues2Enabled && <>
                <ItemContainer>
                  <FlexContainer style={{flexDirection: "column"}}>
                    <StyledVisibleLabel visibility={!(currentSwitch.storeCustomField2ValueCodes && currentSwitch.storeCustomField2ValueCodes.length > 0)}>{tenantSettings.storeCustomFieldName2}</StyledVisibleLabel>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      value={currentSwitch.storeCustomField2ValueCodes}
                      onChange={(value) => { setCurrentSwitch({...currentSwitch, storeCustomField2ValueCodes: value as string[], storeSenderExcludesIds: [], storeRecipientExcludesIds: []}); }}
                      style={{width: 250}}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      bordered={false}
                      options={optsCustomField2}
                      placeholder={tenantSettings.storeCustomFieldName2}
                      mode="multiple"
                      filterOption={filterOption}
                      allowClear
                      tagRender={(props) => customTagRender(props, currentSwitch.storeCustomField2ValueCodes, optsCustomField2)}
                    />
                  </FlexContainer>
                </ItemContainer>
              </> }
              {tenantSettings && tenantSettings.storeCustomFieldName3 && tenantSettings.storeCustomFieldValues3 && switchSetting.storeCustomFieldValues3Enabled && switchSetting.storeCustomFieldValues3Enabled && <>
                <ItemContainer>
                  <FlexContainer style={{flexDirection: "column"}}>
                    <StyledVisibleLabel visibility={!(currentSwitch.storeCustomField3ValueCodes && currentSwitch.storeCustomField3ValueCodes.length > 0)}>{tenantSettings.storeCustomFieldName3}</StyledVisibleLabel>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      value={currentSwitch.storeCustomField3ValueCodes}
                      onChange={(value) => { setCurrentSwitch({...currentSwitch, storeCustomField3ValueCodes: value as string[], storeSenderExcludesIds: [], storeRecipientExcludesIds: []}); }}
                      style={{width: 250}}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      bordered={false}
                      options={optsCustomField3}
                      placeholder={tenantSettings.storeCustomFieldName3}
                      mode="multiple"
                      filterOption={filterOption}
                      allowClear
                      tagRender={(props) => customTagRender(props, currentSwitch.storeCustomField3ValueCodes, optsCustomField3)}
                    />
                  </FlexContainer>
                </ItemContainer>
              </> }
          {/*  </FlexContainer>*/}
          {/*</ItemContainer>*/}

          {/*<ItemContainer style={{marginBottom: 20, marginLeft: 20}}>*/}
          {/*  <FlexContainer style={{flexDirection: "row"}}>*/}
              {tenantSettings && tenantSettings.storeCustomFieldName4 && tenantSettings.storeCustomFieldValues4 && switchSetting.storeCustomFieldValues4Enabled && switchSetting.storeCustomFieldValues4Enabled && <>
                <ItemContainer>
                  <FlexContainer style={{flexDirection: "column"}}>
                    <StyledVisibleLabel visibility={!(currentSwitch.storeCustomField4ValueCodes && currentSwitch.storeCustomField4ValueCodes.length > 0)}>{tenantSettings.storeCustomFieldName4}</StyledVisibleLabel>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      value={currentSwitch.storeCustomField4ValueCodes}
                      onChange={(value) => { setCurrentSwitch({...currentSwitch, storeCustomField4ValueCodes: value as string[], storeSenderExcludesIds: [], storeRecipientExcludesIds: []}); }}
                      style={{width: 230}}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      bordered={false}
                      options={optsCustomField4}
                      placeholder={tenantSettings.storeCustomFieldName4}
                      mode="multiple"
                      filterOption={filterOption}
                      allowClear
                      tagRender={(props) => customTagRender(props, currentSwitch.storeCustomField4ValueCodes, optsCustomField4)}
                    />
                  </FlexContainer>
                </ItemContainer>
              </> }
              {tenantSettings && tenantSettings.storeCustomFieldName5 && tenantSettings.storeCustomFieldValues5 && switchSetting.storeCustomFieldValues5Enabled && switchSetting.storeCustomFieldValues5Enabled && <>
                <ItemContainer>
                  <FlexContainer style={{flexDirection: "column"}}>
                    <StyledVisibleLabel visibility={!(currentSwitch.storeCustomField5ValueCodes && currentSwitch.storeCustomField5ValueCodes.length > 0)}>{tenantSettings.storeCustomFieldName5}</StyledVisibleLabel>
                    <StyledSelect
                      getPopupContainer={(trigger)=> trigger.parentNode}
                      value={currentSwitch.storeCustomField5ValueCodes}
                      onChange={(value) => { setCurrentSwitch({...currentSwitch, storeCustomField5ValueCodes: value as string[], storeSenderExcludesIds: [], storeRecipientExcludesIds: []}); }}
                      style={{width: 250}}
                      suffixIcon={<CaretDownOutlined style={{color: defaultTheme.black}}/>}
                      bordered={false}
                      options={optsCustomField5}
                      placeholder={tenantSettings.storeCustomFieldName5}
                      mode="multiple"
                      filterOption={filterOption}
                      allowClear
                      tagRender={(props) => customTagRender(props, currentSwitch.storeCustomField5ValueCodes, optsCustomField5)}
                    />
                  </FlexContainer>
                </ItemContainer>
              </> }
            </GridContainer>
          </ItemContainer>
          </> }

          </FlexContainer>
        </ItemContainer>

        <ItemContainer style={{marginBottom: 30}}>
            <FlexContainer style={{flexDirection: "column"}}>

                <ItemContainer>
                    <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.exclude_shippers")}</StyledLabel>
                    <FlexContainer style={{flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                        <ItemContainer>
                            <StyledLabel style={{width: 250, fontFamily: "NotoRegular", paddingLeft: 40}}>{t("create_switch.store_exclude")}</StyledLabel>
                        </ItemContainer>
                        <ItemContainer>
                            <StyledPrimaryButton iconPath={pictoReglageActif} countBadge={countSelectedExcludeShipping} style={{width: 530, padding: "0.5em 0em"}} textKey="create_switch.settings_exclusions" onClick={() => onClickParamsExcludeShippers()} />
                        </ItemContainer>
                    </FlexContainer>
                </ItemContainer>

                <ItemContainer>
                    <StyledLabel style={{paddingBottom: 20}}>{t("create_switch.exclude_recipients")}</StyledLabel>
                    <FlexContainer style={{flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                        <ItemContainer>
                            <StyledLabel style={{width: 250, fontFamily: "NotoRegular", paddingLeft: 40}}>{t("create_switch.store_exclude")}</StyledLabel>
                        </ItemContainer>
                        <ItemContainer>
                            <StyledPrimaryButton iconPath={pictoReglageActif} countBadge={countSelectedExcludeRecipient} style={{width: 530, padding: "0.5em 0em"}} textKey="create_switch.settings_exclusions" onClick={() => onClickParamsExcludeRecipients()} />
                        </ItemContainer>
                    </FlexContainer>
                </ItemContainer>
            </FlexContainer>
        </ItemContainer>

        <ExcludeModal
          customTitle={t('exclude_modal.title_shipping')}
          text={''}
          onSelect={onSelectExcludeShippers}
          open={excludeShippersModalOpen}
          onCancel={() => setExcludeShippersModalOpen(false)}
          colDefs={colDefs}
          rowData={currentStoreShipperData as unknown[]}
          selectedIds={currentSwitch.storeSenderExcludesIds as number[] | undefined}
          getRowId={(params) => params.data.id}
        />

        <ExcludeModal
          customTitle={t('exclude_modal.title_recipient')}
          text={''}
          onSelect={onSelectExcludeRecipients}
          open={excludeRecipientsModalOpen}
          onCancel={() => setExcludeRecipientsModalOpen(false)}
          colDefs={colDefs}
          rowData={currentStoreRecipientData as unknown[]}
          selectedIds={currentSwitch.storeRecipientExcludesIds as number[] | undefined}
          getRowId={(params) => params.data.id}
        />

      </FlexContainer>

    )
};

export default Magasin;